import { useEffect, useState } from 'react';

import format from 'date-fns/format';
import { Column } from 'react-table';
import { IModalContentProps } from 'src/components/ModalWrapper';
import api from 'src/services/api';
import Table from '../Table';
import { Body, Container, Head } from './styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Pdf from 'src/assets/pdf/Termos_de_Aceite_Primeiro_Login.pdf';
const path = 'public/Termos_de_Aceite_Primeiro_Login.pdf';
interface ITermoModal extends IModalContentProps {
  text: string;
}

const TermoModal = ({ closeButton, text }: ITermoModal): JSX.Element => {
  const [data, setData] = useState([]);
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // Columns of table
  const columns: Column[] = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
  ];
  const [numPages, setNumPages] = useState(5);
  const [pageNumber, setPageNumber] = useState(5);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Container>
      <Head>Termo de uso {closeButton}</Head>
      <Body>
        <Document
          file={Pdf}
          options={{
            cMapPacked: true,
          }}
        >
          <Page pageNumber={1} />
          <Page pageNumber={2} />
          <Page pageNumber={3} />
          <Page pageNumber={4} />
          <Page pageNumber={5} />
        </Document>
      </Body>
    </Container>
  );
};

export default TermoModal;
