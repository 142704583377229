import { ComponentType, useEffect } from 'react';
import { useRef } from 'react';

import { Container, Text, Value } from './styles';

interface IIndicatorCardProps {
  start?: number;
  icon?: ComponentType;
  text: string;
  value: string | number;
}

const IndicatorCard = ({
  start = 0,
  icon: Icon,
  text,
  value,
}: IIndicatorCardProps): JSX.Element => {
  const valueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const makeCountEffect = () => {
      const parsedValue = Number(value);
      const parsedStart = Number(start);

      let count = parsedStart
        ? Math.ceil(parsedValue * (parsedStart / 100))
        : 0;

      const timer = setInterval(() => {
        count += 1;
        if (valueRef.current) valueRef.current.innerText = `${count}`;

        if (count === parsedValue) clearInterval(timer);
      }, 10);
    };

    if (typeof value === 'number') {
      makeCountEffect();
    }
  }, [start, value]);

  return (
    <Container>
      <Value ref={valueRef}>{value}</Value>
      <Text>{text}</Text>
      <div className="close_btn">&times;</div>
      {Icon && (
        <div className="icon-wrapper">
          <Icon />
        </div>
      )}
    </Container>
  );
};

export default IndicatorCard;
