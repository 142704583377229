import styled from 'styled-components';

import FormGroup from '../FormGroup';

export const Container = styled.div`
  color: ${({ theme }) => theme.text.title};

  align-content: center;
  flex: 1;

  & svg {
    width: 32px;
    height: 32px;
    fill: #56ad39;
  }

  svg * {
    clip-path: none !important;
  }

  &.display {
    font-size: 2.625rem;
    font-weight: 800;
  }

  &.header {
    font-size: 30px;
    font-weight: 400;
    border: none !important;

    & svg {
      width: 38px;
      height: 38px;
      fill: #56ad39;
    }

    .icon-wrapper {
      background: #ffffff 0% 0% no-repeat padding-box;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: -1px 3px 4px rgba(0, 0, 0, 0.15);
    }

    & span {
      border-bottom: 1px solid #cccccc;
    }
  }

  &.title {
    font-size: 1.75rem;
    font-weight: 800;
  }

  &.subtitle {
    font-family: 'Roboto Condensed';
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.headline {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &.info {
    display: inline-block;
    margin-left: 5px;

    font-size: 13px;
    text-transform: lowercase;
  }
`;

export const Content = styled(FormGroup)`
  align-items: center;
`;
