import { Container } from './styles';
import logo_footer from 'src/assets/img/logo_footer.svg';

const LogoFooter = (): JSX.Element => {
  return (
    <Container>
      <img
        src={logo_footer}
        style={{ width: 266, height: 95, marginTop: 18 }}
      />
    </Container>
  );
};

export default LogoFooter;
