import { RefObject, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { defaultOptions, formatErrors } from 'src/libs/yup';

interface IUseStepFormControlProps {
  steps: {
    label: string;
    content: JSX.Element | Element;
    validation?: any;
    ref?: RefObject<FormHandles>;
  }[];
  onFinish?: () => void;
}

interface IUseStepFormControl {
  currentStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  previousStep: () => void;
  nextStep: () => void;
}

const useStepFormControl = ({
  onFinish,
  steps,
}: IUseStepFormControlProps): IUseStepFormControl => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const updatedStepLength = steps.length - 1;

  const isLastStep = currentStep + 1 > updatedStepLength;

  const isFirstStep = currentStep - 1 < 0;

  const previousStep = () => {
    setCurrentStep((cs) => (cs - 1 >= 0 ? cs - 1 : cs));
  };

  const canPass = useCallback(async () => {
    if (steps[currentStep].validation && steps[currentStep].ref) {
      try {
        const formData = steps[currentStep].ref?.current?.getData();
        await steps[currentStep].validation.validate(formData, {
          ...defaultOptions,
          context: formData,
        });
        return true;
      } catch (error) {
        if (error?.inner) {
          steps[currentStep].ref?.current?.setErrors(formatErrors(error.inner));
        }
        return false;
      }
    }

    return true;
  }, [currentStep, steps]);

  const nextStep = useCallback(async () => {
    const can = await canPass();
    if (can)
      setCurrentStep((cs) => {
        if (cs + 1 <= updatedStepLength) {
          return cs + 1;
        }

        if (onFinish) {
          onFinish();
        }

        return cs;
      });
  }, [canPass, onFinish, updatedStepLength]);

  return {
    currentStep,
    isLastStep,
    isFirstStep,
    previousStep,
    nextStep,
  };
};

export default useStepFormControl;
