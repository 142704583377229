import { useEffect, useState } from 'react';

import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import { CustomProvider, DateRangePicker, Stack } from 'rsuite';
import pt_BR from 'rsuite/locales/pt_BR';

import 'rsuite/dist/rsuite.min.css';
import './style.css';

import { DropdownArrowDown } from '../../components/Icons';

const DateRangePickerContainer = (): JSX.Element => {
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<string>('ESTE MÊS');
  const [selectedRangeConfirm, setSelectedRangeConfirm] =
    useState<string>('ESTE MÊS');

  useEffect(() => {
    isCustom && setSelectedRange('PERSONALIZADO');
  }, [isCustom]);

  useEffect(() => {
    document.querySelectorAll('.rs-btn-link').forEach((el) => {
      el.classList.remove('active');
      if (el.innerHTML == selectedRange) {
        el.classList.add('active');
      }
    });
  }, [selectedRange]);

  function customListener(e) {
    setSelectedRange(e.target.innerHTML);
    setIsCustom(false);
  }

  function changeRangePickerElement() {
    const btnPrimary = document.querySelector('.rs-btn-primary');
    if (btnPrimary) btnPrimary.childNodes[0].replaceWith('Aplicar');

    const headerValue = document.querySelector(
      '.rs-picker-daterange-header'
    )?.innerHTML;

    const personalizadoBtn = `
    <div class="rs-stack-item">
      <button placement="left" type="button" aria-disabled="false" class="rs-btn-link rs-btn-link-custom btn-blocked" rangename="PERSONALIZADO">PERSONALIZADO</button>
    </div>
    `;

    document
      .querySelector('.rs-picker-daterange-predefined')
      ?.insertAdjacentHTML('beforeend', personalizadoBtn);

    // const cancelarBtn = `
    //     <button type="button" aria-disabled="false" class="rs-btn rs-btn-secondary rs-btn-sm">
    //       Cancelar
    //       <span class="rs-ripple-pond"><span class="rs-ripple">
    //       </span></span>
    //     </button>
    //   `;

    // document
    //   .querySelector('.rs-picker-toolbar-right')
    //   ?.insertAdjacentHTML('afterbegin', cancelarBtn);
  }

  function handleOpen() {
    changeRangePickerElement();

    document.querySelectorAll('.rs-btn-link').forEach((el) => {
      const rangeName = el.innerHTML;

      rangeName != 'PERSONALIZADO' &&
        el.addEventListener('click', customListener, false);

      el.setAttribute('rangeName', rangeName);

      if (rangeName == selectedRange) {
        el.classList.add('active');
      }
    });
  }

  function handleChange(value) {
    setIsCustom(true);
  }
  function handleOK(value) {
    setSelectedRangeConfirm(selectedRange);
  }
  function handleExit(value) {
    if (selectedRange != selectedRangeConfirm) {
      setSelectedRange(selectedRangeConfirm);
    }
  }

  return (
    <CustomProvider locale={pt_BR}>
      <Stack
        className="rangePickerContainer"
        direction="column"
        spacing={8}
        alignItems="flex-start"
      >
        <div className="rs-picker-daterange-tag">{selectedRange}</div>
        <DateRangePicker
          ranges={[
            {
              label: 'TOTAL',
              value: [
                new Date(new Date().getFullYear() - 22, 0, 1),
                new Date(),
              ],
              placement: 'left',
              closeOverlay: false,
            },
            {
              label: 'ESTA SEMANA',
              value: [startOfWeek(new Date()), endOfWeek(new Date())],
              placement: 'left',
              closeOverlay: false,
            },
            {
              label: 'ESTE MÊS',
              value: [startOfMonth(new Date()), new Date()],
              placement: 'left',
              closeOverlay: false,
            },
            {
              label: 'ÚLTIMOS 3 MESES',
              value: [
                startOfMonth(addMonths(new Date(), -3)),
                endOfMonth(addMonths(new Date(), -1)),
              ],
              placement: 'left',
              closeOverlay: false,
            },
            {
              label: 'ÚLTIMOS 6 MESES',
              value: [
                startOfMonth(addMonths(new Date(), -6)),
                endOfMonth(addMonths(new Date(), -1)),
              ],
              placement: 'left',
              closeOverlay: false,
            },
            {
              label: 'ESTE ANO',
              value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
              placement: 'left',
              closeOverlay: false,
            },
          ]}
          onEntering={handleOpen}
          character=" - "
          format="dd/MM/yyyy"
          placement="bottomEnd"
          placeholder="dd/mm/yyyy - dd/mm/yyyy"
          style={{ width: 300 }}
          caretAs={DropdownArrowDown}
          cleanable={false}
          onSelect={(value) => handleChange(value)}
          onOk={(value) => handleOK(value)}
          onExit={(value) => handleExit(value)}
          preventOverflow={true}
          defaultValue={[startOfMonth(new Date()), new Date()]}
        />
      </Stack>
    </CustomProvider>
  );
};
export default DateRangePickerContainer;
