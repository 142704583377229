import { useRef, useState, useCallback } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Sap from 'src/assets/img/sap.png';
import ServiceNow from 'src/assets/img/servicenow.png';
import Grid from 'src/components/Grid';
import {
  SystemPrivate,
  Disponivel,
  Instavel,
  Indisponivel,
} from 'src/components/Icons';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Title from 'src/components/Title';

import ListModal from '../ListModal';
import { Container } from './styles';
const Private = (): JSX.Element => {
  const modalRef = useRef<IModalHandlers>(null);
  const [systemModal, setSystemModal] = useState('');
  const [linkModal, setLinkModal] = useState('');

  const handleOnModal = (system, link) => {
    setSystemModal(system);
    setLinkModal(link);
    modalRef.current?.open();
  };

  return (
    <>
      <Container>
        <ModalWrapper ref={modalRef}>
          <ListModal link={linkModal} title={systemModal} />
        </ModalWrapper>
        <Title
          className="titulo-sistema-container"
          icon={SystemPrivate}
          variant="title"
        >
          STATUS SISTEMAS PRIVADOS
        </Title>
        <Grid columns="1fr 1fr 1fr">
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={Sap}
                  className="mr-3 logo-sistema"
                  style={{ width: '10%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">SAP S/4 HANA</h5>
                  <p className="card-text  text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      SAP HANA Sistema ERP Inteligente - v1709
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      SAP COMPANY BRAZIL
                    </span>
                  </p>
                </div>
              </div>
              <p
                className="card-text card-text-container"
                style={{ marginTop: '15px' }}
              >
                <Indisponivel
                  style={{
                    font: 'normal normal normal 10px/16px Roboto Condensed',
                    color: '#B0B0B0',
                    marginRight: '15px',
                  }}
                />
                <div className="card-text-wrapper">
                  <p className="messageLastUpdate">
                    SISTEMA INDISPONÍVEL NO MOMENTO
                  </p>
                  <p className="dateLastUpdate">21/04/2022 - 23:54 -&nbsp; </p>
                  <p className="labelLastUpdate">Última atualização</p>
                </div>
              </p>
              <a
                href="#"
                onClick={() => handleOnModal('SAP S/4 HANA', 'sap')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media">
                <img
                  src={ServiceNow}
                  className="mr-3 logo-sistema"
                  style={{ width: '10%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">SERVICENOW</h5>
                  <p className="card-text  text-muted">
                    <span
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      ServiceNow
                    </span>
                    <br />
                    <span
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      SERVICENOW COMPANY
                    </span>
                  </p>
                </div>
              </div>
              <p
                className="card-text card-text-container"
                style={{ marginTop: '15px' }}
              >
                <Indisponivel
                  style={{
                    font: 'normal normal normal 10px/16px Roboto Condensed',
                    color: '#B0B0B0',
                    marginRight: '15px',
                  }}
                />
                <div className="card-text-wrapper">
                  <p className="messageLastUpdate">
                    SISTEMA INDISPONÍVEL NO MOMENTO
                  </p>
                  <p className="dateLastUpdate">21/04/2022 - 23:54 -&nbsp; </p>
                  <p className="labelLastUpdate">Última atualização</p>
                </div>
              </p>
              <a
                href="#"
                onClick={() => handleOnModal('SERVICENOW', 'servicenow')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default Private;
