import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  padding: 40px 20px 50px 20px;

  .root-title.header {
    padding-bottom: 8px;
    margin-bottom: 32px;
    border-bottom: 1px solid #cccccc;
  }
`;

export const Content = styled.div`
  padding: 16px 10px 20px 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.card.background};
  box-shadow: ${({ theme }) => theme.boxShadow};

  .root-title {
    margin-bottom: 24px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;
