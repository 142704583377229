import defaultTheme from './_defaultTheme';
import button from './button';
import card from './card';
import checkbox from './checkbox';
import input from './input';
import sideNavigation from './sideNavigation';
import switchColors from './switch';
import table from './table';
import text from './text';
import topNavigation from './topNavigation';

export const theme = {
  ...defaultTheme,
  border: '#eeeeee',
  button,
  card,
  checkbox,
  input,
  sideNavigation,
  table,
  switch: switchColors,
  text,
  topNavigation,
};

export type ThemeType = typeof theme;

export default theme;
