import { Container, Select, Title } from './styles';

interface IPageSizeProps {
  onChangePageSize?: ({ label: string, value: number }) => void;
}

const DEFAULT_OPTIONS = [
  { label: '10 POR PÁGINA', value: 10 },
  { label: '20 POR PÁGINA', value: 20 },
  { label: '30 POR PÁGINA', value: 30 },
  { label: '40 POR PÁGINA', value: 40 },
  { label: '50 POR PÁGINA', value: 50 },
];

const PageSize = ({ onChangePageSize }: IPageSizeProps): JSX.Element => {
  return (
    <Container>
      <Title>Exibir Itens:</Title>
      <Select
        label=""
        name="providers_table_count"
        options={DEFAULT_OPTIONS}
        onChange={onChangePageSize}
        isSearchable={false}
        defaultValue={DEFAULT_OPTIONS[0]}
      />
    </Container>
  );
};

export default PageSize;
