import { Container, TextMidia, BodyMidia } from './styles';
import like from 'src/assets/img/like.svg';
import instagram from 'src/assets/img/instagram.svg';
import facebook from 'src/assets/img/facebook.svg';
import linkedin from 'src/assets/img/linkedin.svg';
import twiter from 'src/assets/img/twiter.svg';

const MidiaFooter = (): JSX.Element => {
  return (
    <Container>
      <img src={like} style={{ width: 22, height: 24, marginTop: 18 }} />

      <TextMidia>estamos por aí</TextMidia>

      <BodyMidia>
        <img src={facebook} style={{ width: 13, height: 20 }} />
        <img src={instagram} style={{ width: 20, height: 20, marginLeft: 7 }} />
        <img src={linkedin} style={{ width: 20, height: 20, marginLeft: 7 }} />
        <img src={twiter} style={{ width: 20, height: 17, marginLeft: 7 }} />
      </BodyMidia>
    </Container>
  );
};

export default MidiaFooter;
