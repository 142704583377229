import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 570px !important;
  height: 100vh;
  color: #222;
  display: flex;
  flex-direction: column;

  font-family: 'Montserrat', sans-serif;
`;

export const Header = styled.div`
  min-height: 100px;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  & .cempre {
    padding-right: 20px;
    width: 220px;
    height: auto;
  }
  & .vi {
    padding-left: 20px;
    border-left: 1px solid #d8d8d8;
    width: 220px;
    height: auto;
  }
`;

export const Content = styled.div`
  flex: 1;

  & .img-container {
    @media (max-width: 660px) {
      overflow-x: hidden;
    }
    position: relative;

    & .img-fundo {
      height: 725px;
      width: auto;
    }
  }

  & .como-funciona-container {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 525px;
    position: relative;

    @media (max-width: 1200px) {
      flex-direction: column;
      padding: 30px;
    }

    & .horizontal-bar {
      position: absolute;
      width: 160px;
      height: 3px;
      border: 2px solid #dedede;
      left: 0;
      top: 165px;

      @media (max-width: 1400px) {
        display: none;
      }
    }

    & .arrow {
      transform: translateY(40px);

      @media (max-width: 1200px) {
        display: none;
      }
    }

    & .left-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 175px;

      @media (max-width: 1400px) {
        padding-left: 40px;
      }
    }

    & .left {
      position: relative;
      margin-right: 25px;
      & img {
        position: absolute;
        left: -20px;
      }
      & .como-funciona_titulo {
        text-align: left;
        font: normal normal bold 24px/60px Montserrat;
        letter-spacing: 0px;
        color: #35a2d0;
        text-transform: uppercase;
        text-indent: 45px;
        padding-top: 30px;
      }
      & .como-funciona_descricao {
        min-width: 321px;
        max-width: 321px;
        text-align: left;
        font: normal normal normal 20px/42px Ubuntu;
        letter-spacing: 0px;
        color: #716666;
      }

      @media (max-width: 1400px) {
        & .como-funciona_titulo {
          font-size: 20px;
        }
        & .como-funciona_descricao {
          font-size: 18px;
        }
      }
    }
    & .right {
      padding: 30px;
      flex: 1;
      & img {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
`;

export const FormContainer = styled.form`
  position: absolute;
  width: 450px;
  min-width: 250px !important;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-top: 10px solid #bed62f;
  padding: 50px;
  padding-top: 30px;

  top: 0;
  right: 12%;

  transform: scale(100.5%) translateY(2px);

  @media (max-width: 660px) {
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    padding: 40px;
  }

  & p {
    margin-bottom: 30px;
  }

  & .login {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    & p {
      padding-left: 20px;
      text-align: left;
      font: normal normal bold 24px/45px Montserrat;
      letter-spacing: 1.2px;
      color: #37a1d4;
      margin: 0;
    }
  }

  & .login-mensagem p {
    margin-top: 10px;
  }

  & .input {
    margin-top: -10px;

    & p {
      margin-bottom: -17px;
    }

    & div:first-of-type {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  & button {
    margin-top: 10px;
    background-color: #35a2d0;

    & p {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      font-family: 'Ubuntu', sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      margin: 0;
    }
  }

  & .esqueci-senha {
    cursor: pointer;
    text-align: left;
    font: normal normal bold 12px/24px Montserrat;
    letter-spacing: 0px;
    color: #3397c5;
    text-transform: uppercase;

    padding-top: 15px;
  }

  & .logo {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.div`
  & .footer-top {
    height: 180px;
    background-color: #35a2d0;
    color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 29px;

    & .footer-top_conecte {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      & p {
        margin: 0;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0px;
      }
      & p.conecte {
        font: normal normal bold 16px/28px Ubuntu;
        letter-spacing: 0.8px;
      }
      & p.instagram {
        font: normal normal bold 14px/19px Ubuntu;
      }
      & p.linkedin {
        font: normal normal bold 14px/16px Ubuntu;
      }
    }
    & .footer-top_logos {
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin: 0;
      padding: 0;

      & .cempre {
        padding-right: 20px;
      }
      & .vi {
        padding-left: 20px;
        border-left: 1px solid #fff;
      }
    }
  }
  & .footer-bottom {
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & p {
      text-align: left;
      font: normal normal medium 14px/24px Ubuntu;
      letter-spacing: 0px;
      color: #847e7e;
    }
  }
`;

export const ReCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
`;

export const BlockInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
`;
