import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import LoginArcosDourados from '../LoginArcosDourados';
import LoginCarrefour from '../LoginCarrefour';
import LoginCempre from '../LoginCempre';
import LoginGeral from '../LoginGeral';
const Login: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  return (
    <>
      {url_atual.includes('carrefour') && <LoginCarrefour />}{' '}
      {url_atual.includes('arcosdourados') && <LoginArcosDourados />}{' '}
      {url_atual.includes('vicempre') && <LoginCempre />}{' '}
      {!url_atual.includes('carrefour') &&
        !url_atual.includes('arcosdourados') &&
        !url_atual.includes('vicempre') && <LoginGeral />}{' '}
    </>
  );
};

export default Login;
