import { useCallback, useEffect, useRef, useState } from 'react';

import { clear } from 'magic-masks';
import Input from 'src/components/Input';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Select from 'src/components/Select';
import api from 'src/services/api';

import AlertModal from './AlertModal';

interface IRegister {
  fieldType: 'cpf' | 'cnpj' | 'international_register';
  onChange: ({ value }) => void;
}

const Register = ({ fieldType, onChange }: IRegister): JSX.Element => {
  const modalRef = useRef<IModalHandlers>(null);
  const [provider, setProvider] = useState();

  useEffect(() => {
    if (provider) {
      modalRef.current?.open();
    }
  }, [modalRef, provider]);

  const handleOnChangeCnpj = useCallback(
    async (event) => {
      const value = clear(event.target.value);

      if (value.length === 14 || value.length === 11) {
        try {
          const response = await api.providers.get(
            `/api/v1/providers?${fieldType}=${value}`
          );

          setProvider(response.data?.data?.[0]);
        } catch (error) {}
      }
    },
    [fieldType]
  );

  const DEFAULT_REGISTER_FIELDS = {
    cpf: {
      label: 'CPF',
      name: 'cpf',
      component: (
        <Input
          label="CPF"
          name="cpf"
          mask="cpf"
          placeholder="000.000.000-00"
          onChange={handleOnChangeCnpj}
          required
          returnUnmasked
          readOnly
        />
      ),
    },
    cnpj: {
      label: 'CNPJ',
      name: 'cnpj',
      component: (
        <Input
          label="CNPJ"
          name="cnpj"
          mask="cnpj"
          placeholder="00.000.000/0000-00"
          onChange={handleOnChangeCnpj}
          required
          returnUnmasked
          readOnly
        />
      ),
    },
    international_register: {
      label: 'Registro Internacional',
      name: 'international_register',
      component: (
        <Input
          label="Registro Internacional"
          name="international_register"
          required
          readOnly
        />
      ),
    },
  };

  const getOptions = () => {
    const keys = Object.keys(DEFAULT_REGISTER_FIELDS);

    const options = keys.map((key) => {
      const { label, name } = DEFAULT_REGISTER_FIELDS[key];

      return {
        label,
        value: name,
      };
    });

    return options;
  };

  return (
    <>
      <ModalWrapper ref={modalRef}>
        <AlertModal
          fieldName={DEFAULT_REGISTER_FIELDS[fieldType].label}
          provider={provider}
        />
      </ModalWrapper>
      <Select
        label="Tipo de registro"
        name="registry_type"
        options={getOptions()}
        onChange={onChange}
        isDisabled
        defaultValue={{
          label: 'CNPJ',
          value: 'cnpj',
        }}
        required
      />
      {DEFAULT_REGISTER_FIELDS[fieldType].component}
    </>
  );
};

export default Register;
