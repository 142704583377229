import { FC } from 'react';

import { IContainerProps, Container } from './styles';

type FadeInProps = IContainerProps;

const FadeIn: FC<FadeInProps> = ({ children, delay, duration }) => {
  return (
    <Container delay={delay} duration={duration}>
      {children}
    </Container>
  );
};

export default FadeIn;
