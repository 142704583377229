import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
  padding: 16px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow};

  .root-title {
    margin-bottom: 16px;
  }
`;
