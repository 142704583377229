import { useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Text from 'src/components/Text';
import Title from 'src/components/Title';

import FormFields from './FormFields';
import { Container, Content, Head, HeadContent } from './styles';

const Store = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  return (
    <Container>
      <Head>
        <HeadContent>
          <Title variant="header">Criar Documento de Saída</Title>
          <Text className="helper">(*) campos obrigatórios</Text>
        </HeadContent>
      </Head>
      <Content>
        <Form ref={formRef} onSubmit={console.log}>
          <FormFields formRef={formRef} />
        </Form>
      </Content>
    </Container>
  );
};

export default Store;
