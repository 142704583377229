import styled from 'styled-components';

export const Container = styled.label`
  display: inline-block;
  width: 100%;

  color: ${({ theme }) => theme.text.label};
  font-size: 14px;
  font-weight: 400;
`;
