import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Roboto Condensed';
  font-size: 16px;
  color: #ffffff;
`;

export const LastLevel = styled.span`
  font-weight: 600;
`;
