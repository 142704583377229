import { Container } from './styles';
import headset from 'src/assets/img/headset.svg';
const OmbudsmanFooter = (): JSX.Element => {
  return (
    <Container>
      <img src={headset} style={{ width: 21, height: 24, marginTop: 18 }} />

      <span
        style={{
          textTransform: 'uppercase',
          color: '#77E653',
          fontSize: 13,
          marginTop: 9,
        }}
      >
        queremos te ouvir
      </span>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: 10,
        }}
      >
        <span
          style={{
            fontSize: 20,
            color: '#FFFFFF',
            textShadow: '0px 1px 2px #00000065',
          }}
        >
          (11) 2337-4049
        </span>
        <span
          style={{
            fontSize: 13,
            color: '#FFFFFF',
            textShadow: '0px 1px 2px #00000065',
            marginTop: 4,
          }}
        >
          suporte@plataformaverde.com.br
        </span>
      </div>
    </Container>
  );
};

export default OmbudsmanFooter;
