import { useHistory } from 'react-router-dom';
import Button from 'src/components/Button';
import { IModalContentProps } from 'src/components/ModalWrapper';

import {
  Body,
  Container,
  Footer,
  Head,
  Info,
  InfoLabel,
  InfoRow,
  InfoValue,
  Instruction,
} from './styles';

interface IAlertModal extends IModalContentProps {
  fieldName: string;
  provider?: { [key: string]: any };
}

const AlertModal = ({
  closeButton,
  closeModal,
  fieldName,
  provider,
}: IAlertModal): JSX.Element => {
  const history = useHistory();

  const handleOnClickEdit = () =>
    history.push(`/prestadores/edicao/${provider?.uuid_provider}`);

  const handleOnClickClose = () => {
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <Container>
      <Head>Aviso! {closeButton}</Head>
      <Body>
        <Instruction>
          O número de {fieldName} digitado já possui cadastro em nosso sistema
          com as seguintes informações:
        </Instruction>
        <Info>
          <InfoRow>
            <InfoLabel>Razão Social:</InfoLabel>
            <InfoValue>
              {provider?.company?.company_name || 'Não informado'}
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Nome fantasia:</InfoLabel>
            <InfoValue>
              {provider?.company?.trading_name || 'Não informado'}
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>País de origem:</InfoLabel>
            <InfoValue>
              {provider?.company?.address_country || 'Não informado'}
            </InfoValue>
          </InfoRow>
        </Info>
        <Instruction>Escolha uma das opções nos botões abaixo.</Instruction>
      </Body>
      <Footer>
        <Button label="Editar cadastro existente" onClick={handleOnClickEdit} />
        <Button label="Realizar uma nova busca" onClick={handleOnClickClose} />
      </Footer>
    </Container>
  );
};

export default AlertModal;
