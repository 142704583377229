/* eslint-disable react/display-name */
import { ComponentType, ReactNode } from 'react';

import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

export default [
  {
    Header: (): null => null, // No header
    id: 'expander', // It needs an ID
    Cell: ({ row }: any): ComponentType | ReactNode => (
      <span className="root-table-toggle" {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
      </span>
    ),
  },
];
