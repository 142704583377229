import styled from 'styled-components';

export const Container = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const BodyKilos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10;
`;

export const TitleKilos = styled.span`
  texttransform: uppercase;
  color: #77e653;
  font-size: 13px;
  margin-top: 9px;
`;

export const CountKilos = styled.span`
  fontsize: 20px;
  color: #ffffff;
  text-shadow: 0px 1px 2px #00000065;
`;

export const CountTextKilos = styled.span`
  fontsize: 13px;
  color: #ffffff;
  text-shadow: 0px 1px 2px #00000065;
  margin-top: 4px;
`;
