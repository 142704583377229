import { useCallback, useEffect, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import { clear } from 'magic-masks';
import FadeIn from 'src/components/FadeIn';
import FormGroup from 'src/components/FormGroup';
import { Employee, Providers } from 'src/components/Icons';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';

import Register from './Register';
import Services from './Services';
interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

type TFieldType = 'cpf' | 'cnpj' | 'international_registry';

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  const [providerOptionsStates, setProviderOptionsStates] = useState([] as any);
  const [providerOptionsCities, setProviderOptionsCities] = useState([] as any);

  const [fieldType, setFieldType] = useState<TFieldType>('cnpj');

  const handleOnChangeRegisterType = useCallback(
    (selected) => {
      setFieldType((currentValue) => {
        return selected.value || currentValue;
      });

      const registerType = selected.value || fieldType;

      formRef.current?.setFieldValue(
        'company.address_country',
        registerType !== 'international_registry' ? 'Brasil' : ''
      );
    },
    [formRef, fieldType]
  );

  useEffect(() => {
    formRef.current?.setFieldValue('company.address_country', 'Brasil');

    const getStates = async () => {
      try {
        const { data } = await api.providers.get('/api/v1/states');

        const options = Object.keys(data).map((key) => ({
          label: `${data[key].federative_unit} - ${data[key].name}`,
          value: data[key].federative_unit,
        }));

        setProviderOptionsStates(options);
      } catch (error) {}
    };

    getStates();
  }, [formRef]);

  const getProviderOptionsCities = useCallback(
    async (code) => {
      if (code.value !== '') {
        try {
          const { data } = await api.providers.get(
            `/api/v1/cities?state=${code.value}`
          );

          const options = Object.keys(data)
            .map((key) => ({
              label: data[key].name,
              value: data[key].name,
            }))
            .filter((option) => option.value !== '');

          setProviderOptionsCities(options);

          formRef.current?.setFieldValue('company.address_city', '');
        } catch (error) {}
      }
    },
    [setProviderOptionsCities, formRef]
  );

  const handleOnChangeCEP = useCallback(
    async (event) => {
      const { value } = event.target;
      if (value.length === 9) {
        const cep = clear(value);

        // Reset when change cep
        formRef.current?.setFieldError('company.address_zip_code', '');
        formRef.current?.setFieldValue('company.address_name', '');

        try {
          const address = await cepPromise(cep);

          // Fill if has cep
          formRef.current?.setFieldValue(
            'company.address_name',
            address.street
          );
          formRef.current?.setFieldValue(
            'company.address_state',
            address.state
          );

          await getProviderOptionsCities({ value: address.state });

          formRef.current?.setFieldValue(
            'company.address_city',
            address.city.toUpperCase()
          );
        } catch (error) {
          formRef.current?.setFieldError(
            'company.address_zip_code',
            'Todos os serviços de CEP não encontraram o valor informado.'
          );
        }
      }
    },
    [formRef, getProviderOptionsCities]
  );

  return (
    <FadeIn>
      <Form ref={formRef} onSubmit={console.log}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={10}>
            <Title icon={Providers} variant="subtitle">
              Dados do Prestador
            </Title>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr 1fr 1fr 1fr">
              <Register
                fieldType={fieldType}
                formRef={formRef}
                cities={getProviderOptionsCities}
                onChange={handleOnChangeRegisterType}
              />
              <Input label="Apelido" name="alias" maxLength={100} />
              <Input label="Ramo de Atividade" name="segment" maxLength={45} />
            </FormGroup>
            <FormGroup columns="1fr 1fr">
              <Input
                label="Razão Social"
                name="company_name"
                maxLength={100}
                required
              />
              <Input
                label="Nome Comercial"
                name="trading_name"
                maxLength={100}
                required
              />
            </FormGroup>
            <FormGroup columns="1fr 1fr 2fr 0.8fr">
              <Input
                label="País"
                name="address_country"
                maxLength={30}
                readOnly={fieldType !== 'international_registry'}
                required
              />
              <Input
                label={
                  fieldType !== 'international_registry'
                    ? 'CEP'
                    : 'Código Postal'
                }
                name="address_zip_code"
                mask={fieldType !== 'international_registry' ? 'zipCode' : ''}
                placeholder={
                  fieldType !== 'international_registry' ? '00000-000' : ''
                }
                onChange={
                  fieldType !== 'international_registry'
                    ? handleOnChangeCEP
                    : undefined
                }
                maxLength={10}
                returnUnmasked
                required
              />
              <Input
                label="Endereço"
                name="address_name"
                maxLength={150}
                required
              />
              <Input
                label="Número"
                name="address_number"
                maxLength={9}
                required
              />
            </FormGroup>
            <FormGroup columns="1fr 1fr 0.7fr 1.23fr 0.8fr">
              <Input
                label="Complemento"
                name="address_complement"
                maxLength={30}
              />
              <Input label="Regional" name="address_region" />
              {fieldType !== 'international_registry' && (
                <>
                  <Select
                    label="Estado"
                    name="address_state"
                    options={providerOptionsStates}
                    onChange={getProviderOptionsCities}
                    required
                  />
                  <Select
                    label="Cidade"
                    name="address_city"
                    options={providerOptionsCities}
                    noOptionsMessage="Selecione um estado para continuar"
                    required
                  />
                </>
              )}
              {fieldType === 'international_registry' && (
                <>
                  <Input
                    label="Estado"
                    name="address_state"
                    maxLength={100}
                    required
                  />
                  <Input
                    label="Cidade"
                    name="address_city"
                    maxLength={100}
                    required
                  />
                </>
              )}
              <Input
                label="Telefone"
                name="phone"
                mask={fieldType !== 'international_registry' ? 'phone' : ''}
                placeholder={
                  fieldType !== 'international_registry' ? '(00) 0000-0000' : ''
                }
                maxLength={20}
                required
                returnUnmasked
              />
            </FormGroup>
            <FormGroup
              columns={
                fieldType !== 'international_registry'
                  ? '1.04fr 1.04fr 3fr'
                  : '1fr'
              }
            >
              {fieldType !== 'international_registry' && (
                <>
                  <Input
                    label="Inscrição Municipal"
                    name="im"
                    maxLength={15}
                    returnUnmasked
                  />
                  <Input
                    label="Inscrição Estadual"
                    name="ie"
                    maxLength={15}
                    returnUnmasked
                  />
                </>
              )}
              <Services />
            </FormGroup>
          </Scope>
          <Title icon={Employee} variant="subtitle">
            Dados do responsável
          </Title>
          <Scope path="responsible_first">
            <FormGroup columns="2fr 2fr 1fr 1fr">
              <Input
                label="Nome Completo do Responsável"
                name="name"
                maxLength={100}
                required
              />
              <Input label="Cargo" name="role" maxLength={40} />
              <Input
                label="Telefone"
                name="phone"
                mask={fieldType !== 'international_registry' ? 'phone' : ''}
                placeholder={
                  fieldType !== 'international_registry' ? '(00) 0000-0000' : ''
                }
                maxLength={20}
                required
                returnUnmasked
              />
              <Input label="Ramal" name="phone_extension" maxLength={10} />
            </FormGroup>
            <FormGroup columns="2fr 1.02fr">
              <Input
                label="E-mail"
                name="email"
                type="email"
                maxLength={100}
                required
              />
              <Input
                label="Celular"
                name="cellphone"
                mask={fieldType !== 'international_registry' ? 'cellphone' : ''}
                placeholder={
                  fieldType !== 'international_registry'
                    ? '(00) 00000-0000'
                    : ''
                }
                maxLength={20}
                returnUnmasked
              />
            </FormGroup>
          </Scope>
          <Scope path="responsible_second">
            <FormGroup columns="2fr 2fr 1fr 1fr">
              <Input
                label="Nome do Segundo Responsável"
                name="name"
                maxLength={100}
              />
              <Input
                label="Cargo do Segundo Responsável"
                name="role"
                maxLength={40}
              />
              <Input
                label="Telefone"
                name="telephone"
                mask={fieldType !== 'international_registry' ? 'phone' : ''}
                placeholder={
                  fieldType !== 'international_registry' ? '(00) 0000-0000' : ''
                }
                maxLength={20}
                returnUnmasked
              />
              <Input label="Ramal" name="telephone_extension" maxLength={4} />
            </FormGroup>
            <FormGroup columns="2fr 1.02fr">
              <Input
                label="E-mail do Segundo Responsável"
                name="email"
                type="email"
                maxLength={100}
              />
              <Input
                label="Celular"
                name="cellphone"
                mask={fieldType !== 'international_registry' ? 'cellphone' : ''}
                placeholder={
                  fieldType !== 'international_registry'
                    ? '(00) 00000-0000'
                    : ''
                }
                maxLength={20}
                returnUnmasked
              />
            </FormGroup>
          </Scope>
        </FormGroup>
      </Form>
    </FadeIn>
  );
};

export default BasicData;
