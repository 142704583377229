import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 300px;
  height: fit-content;
  position: relative;
`;

export const IconBox = styled.div`
  position: absolute;

  height: 30px;
  width: 32px;
  margin-left: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #707070;
    color: #707070;
    width: 32px;
    height: 20px;
  }
`;

export const Input = styled.input`
  width: 100%;

  border-radius: 4px;
  font-family: 'Roboto Condensed';
  font-size: 13px;
  font-weight: 400;
  height: 30px;
  padding: 0 12px 0 38px;

  ::placeholder {
    color: #c0c0c0;
    font-style: italic;
  }

  ${({ theme }) => css`
    color: ${theme.input.normal.foreground};
    background-color: ${theme.input.normal.background};
    border: 1px solid ${theme.input.normal.border};

    transition: 200ms linear border-color;

    &.error {
      border-color: ${theme.input.error.border};
    }

    :read-only {
      border-color: ${theme.input.normal.border};
    }

    :not(:read-only):hover,
    :not(:read-only):focus {
      border-color: ${theme.input.normal.focus.border};
    }
  `}

  &:read-only {
    cursor: not-allowed;
  }
`;
