import { FC, useEffect, useRef } from 'react';

import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import AuthLayout from 'src/layouts/Auth';
import { RootState } from 'src/store/ducks/index';
import lottie from 'lottie-web';
import loadingPv from '../../assets/json/pv-loading.json';
import Loading from 'src/components/Loading';

import ExtendedRouteProps from '../interfaces/ExtendedRouteProps';
import { Container, PvLoading } from './styles';
const Auth: FC<ExtendedRouteProps> = ({
  component: Component,
  title,
  ...rest
}) => {
  const { isAuthenticated, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const element = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: document.getElementById('pvLoading')!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingPv,
    });
    return () => anim.destroy();
  }, [element]);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        !isAuthenticated ? (
          <>
            <Container display={loading ? 'block' : 'none'}>
              <PvLoading>
                <div className="animation-container" id="pvLoading" />
              </PvLoading>
            </Container>

            <Helmet>
              <title>{title}</title>
            </Helmet>
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          </>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default Auth;
