import styled from 'styled-components';

export const Container = styled.span`
  &.body {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text.body};
  }

  &.caption {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text.caption};
  }

  &.helper {
    font-size: 12px;
    color: #606060;
  }
`;
