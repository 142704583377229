import { useCallback, useEffect, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import { clear } from 'magic-masks';
import FadeIn from 'src/components/FadeIn';
import FormGroup from 'src/components/FormGroup';
import {
  Employee,
  Providers,
  employeeCool,
  employeeFinancial,
} from 'src/components/Icons';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';

import { Card } from '../styles';
import Register from './Register';
interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  return (
    <FadeIn>
      <Form ref={formRef} onSubmit={console.log}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={10}>
            <Title icon={Employee} variant="subtitle">
              USUÁRIO MASTER DE ACESSO AO SISTEMA
            </Title>
          </FormGroup>
          <Scope path="responsible_first">
            <FormGroup columns="2fr 2fr 1fr 1fr">
              <Input
                label="Nome Completo"
                name="name"
                maxLength={100}
                required
              />
              <Input
                label="Telefone"
                name="phone"
                mask={'phone'}
                placeholder={'(00) 0000-0000'}
                maxLength={20}
                required
                returnUnmasked
              />
              <Input label="Ramal" name="phone_extension" maxLength={10} />
              <Input
                label="E-mail"
                name="email"
                type="email"
                maxLength={100}
                required
              />
            </FormGroup>
            <Card>
              <h3 style={{ marginBottom: '1%', fontSize: '1.0625rem' }}>
                Importante
              </h3>
              <p style={{ marginBottom: '1%', fontSize: '0.875rem' }}>
                Ao finalizar o cadastro clicando no botão SALVAR, o sistema
                enviará automaticamente o e-mail de boas-vindas de primeiro
                acesso ao software GreenPlat™ para o usuário master acima
                cadastrado. Caso precise reenviar o e-mail de boas-vindas, vá
                até a edição do cadastro desejado e acesse a opção
                <b> usuário master</b> / <b>reenviar e-mail de boas-vindas</b>.
              </p>
            </Card>
          </Scope>
        </FormGroup>
      </Form>
    </FadeIn>
  );
};

export default BasicData;
