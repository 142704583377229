import { FC } from 'react';

import { Container, NameCarrefour, NameGreenPlatform } from './styles';

const InfoText: FC = ({ children }) => {
  return (
    <Container>
      <>
        <p>
          Aqui, o cliente{' '}
          <NameCarrefour>
            <span>CARREFOUR</span>{' '}
          </NameCarrefour>
          pode gerenciar seus
        </p>
        <p>resíduos de forma online, rápida e fácil com os</p>
        <p>
          serviços da{' '}
          <NameGreenPlatform>
            <span>PlataformaVerde™</span>
          </NameGreenPlatform>
          , a primeira REDE
        </p>
        <p>MUNDIAL de gestão ambiental online.</p>
      </>
    </Container>
  );
};

export default InfoText;
