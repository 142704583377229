import styled from 'styled-components';

import ReactSelect from 'react-select';
import { ThemeType } from 'src/styles/themes/light';

interface ISelectAttrs {
  isMulti: boolean;
  error: boolean;
  theme?: ThemeType;
}

export const Container = styled.div`
  min-width: 237px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  height: fit-content;
`;

export const Title = styled.div`
  font-family: 'Roboto Condensed';
  font-size: 13px;

  text-transform: uppercase;
`;

export const Select = styled(ReactSelect).attrs(
  ({ error, isMulti, theme }: ISelectAttrs) => ({
    styles: {
      control: (provided, state) => ({
        ...provided,
        minHeight: 30,
        height: isMulti ? 'auto' : 30,
        borderRadius: 4,
        alignItems: `start`,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        border: `1px solid ${
          error ? theme?.error : theme?.input.normal.border
        }`,
        borderColor: state.isFocused
          ? `${theme?.input.normal.border} !important`
          : 'inherit',
        backgroundColor: theme?.input.normal.background,
        boxShadow: 'none',
      }),
      container: (provided, state) => ({
        ...provided,
        width: 155,
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontSize: 15,
        padding: `0 8px`,
        alignItems: `start`,
        height: 30,
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 30,
        alignItems: `start`,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        height: 30,
        alignItems: `center`,
      }),
      menu: (provided, state) => ({
        ...provided,
        fontSize: 13,
        marginTop: 0,
        paddingTop: 0,
        borderRadius: 4,
        zIndex: 99,
      }),
      menuList: (provided, state) => ({
        ...provided,
        fontSize: 13,
        fontFamily: 'Roboto Condensed',
        marginTop: 0,
        paddingTop: 0,
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontSize: 13,
        fontFamily: 'Roboto Condensed',
      }),
    },
  })
)``;
