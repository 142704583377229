import styled from 'styled-components';

export const Container = styled.button`
  background: #57ba22;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 41px;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  color: #dd1021 !important;
  line-height: 20px;
  font-weight: normal !important;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;
  font: normal normal bold 16px/24px Roboto;
  box-shadow: none;
  &:hover {
    background: #ffb50c;
    cursor: pointer;
  }
`;
