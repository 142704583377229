import { combineReducers } from 'redux';

import auth from './auth';

const reducers = combineReducers({
  auth,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
