import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  padding: 10px;

  border-radius: 5px;

  font-weight: bold;
  color: #ffffff;
  background-color: #56ad39;
  border-radius: 6px;
  transition: opacity 0.3s;
  font: normal normal bold 13px/24px Roboto;
  box-shadow: none;
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;
