import styled from 'styled-components';

import ReactSelect from 'react-select';
import { ThemeType } from 'src/styles/themes/light';

interface ISelectAttrs {
  isMulti: boolean;
  error: boolean;
  theme?: ThemeType;
  isDisabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  .root-label {
    margin-bottom: 2px;
    font-size: 14px;
  }
`;

export const Field = styled(ReactSelect).attrs(
  ({ error, isMulti, theme, isDisabled }: ISelectAttrs) => ({
    styles: {
      control: (provided, state) => ({
        ...provided,
        minHeight: 15,
        height: isMulti ? 'auto' : 15,
        borderRadius: 4,
        alignItems: `start`,
        fontSize: 12,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        border: `none`,
        borderColor: state.isFocused
          ? `${theme?.input.normal.border} !important`
          : 'inherit',
        backgroundColor: isDisabled
          ? '#f2f2f2'
          : theme?.input.normal.background,
        boxShadow: 'none',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontSize: 15,
        padding: `0 8px`,
        alignItems: `start`,
        height: 30,
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 30,
        alignItems: `start`,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        height: 30,
        alignItems: `center`,
      }),
      menu: (provided, state) => ({
        ...provided,
        fontSize: 15,
        marginTop: 0,
        paddingTop: 0,
        borderRadius: 4,
      }),
      menuList: (provided, state) => ({
        ...provided,
        fontSize: 15,
        marginTop: 0,
        paddingTop: 0,
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontSize: 15,
      }),
    },
  })
)``;
