/* eslint-disable react/display-name */

import { useCallback, useMemo, useRef, useState } from 'react';

import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import FadeIn from 'src/components/FadeIn';
import IconButton from 'src/components/IconButton';
import {
  Contratantes,
  EditProviders,
  Inactive,
  Justification,
} from 'src/components/Icons';
import InactiveModal from 'src/components/InactiveModal';
import JustificationModal from 'src/components/JustificationModal';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Actions from 'src/components/Table/Hover/Actions';
import Title from 'src/components/Title';
import api from 'src/services/api';

import Indicators from './Indicators';
import { Container, Content } from './styles';

interface IUseHistoryState {
  providerURL?: string;
}

const List = (): JSX.Element => {
  const history = useHistory<IUseHistoryState | undefined>();

  const justificationsModalRef = useRef<IModalHandlers>(null);
  const inactiveModalRef = useRef<IModalHandlers>(null);

  const [apiURL, setApiURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    data: [],
    total: 0,
  });

  const getProviders = useCallback(
    async ({ pageIndex = 0, pageSize = 10, showInactive = false }) => {
      try {
        setLoading(true);
        const response = await api.providers.get(
          `/api/v1/providers/?page=${
            pageIndex + 1
          }&per_page=${pageSize}&active=${!showInactive}`
        );

        setData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleOnClickNew = () => {
    history.push('/contratantes/cadastro');
  };

  // Columns of table
  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Data de Criação',
        hide: true,
        accessor: ({ created_at }) =>
          created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
      },
      {
        Header: 'Responsável',
        accessor: ({ company }) => company?.trading_name || '',
      },
      {
        Header: 'Validade do Contrato',
        accessor: ({ company }) => company?.company_name || '',
      },
      {
        Header: 'Status',
        hide: true,
        accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
      },
    ],
    []
  );

  const handleClickOpenModal = (event) => {
    const { id, value } = event.target;

    if (id === 'justification') {
      setApiURL(`/api/v1/providers/${value}/changes`);
      justificationsModalRef.current?.open();
    } else {
      setApiURL(`/api/v1/providers/${value}`);
      inactiveModalRef.current?.open();
    }
  };

  const handleOnClickHover = (providerId) => {
    history.push(`/prestadores/edicao/${providerId}`);
  };

  const getHoverComponent = ({
    created_at,
    updated_at,
    deleted_at,
    uuid_provider,
  }) => {
    return (
      <Hover value={uuid_provider} onClick={handleOnClickHover}>
        <Actions>
          <IconButton
            id={deleted_at ? 'justification' : 'inactive'}
            value={uuid_provider}
            onClick={handleClickOpenModal}
            tooltip={deleted_at ? 'Justificativas' : 'Inativar'}
          >
            {deleted_at ? <Justification /> : <Inactive />}
          </IconButton>
          {!deleted_at && created_at !== updated_at && (
            <IconButton
              id={'justification'}
              value={uuid_provider}
              onClick={handleClickOpenModal}
              tooltip={'Justificativas'}
            >
              <Justification />
            </IconButton>
          )}
        </Actions>
      </Hover>
    );
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: '10px',
        }}
      >
        <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
      </pre>
    ),
    []
  );

  return (
    <Container>
      <FadeIn>
        <ModalWrapper ref={justificationsModalRef}>
          <JustificationModal text="Prestador" getURL={apiURL} />
        </ModalWrapper>
        <ModalWrapper ref={inactiveModalRef}>
          <InactiveModal text="Prestador" postURL={apiURL} />
        </ModalWrapper>
        <Title variant="header" icon={Contratantes}>
          Contratantes
        </Title>
        <Indicators />
        <Content>
          <Title variant="subtitle" icon={EditProviders}>
            Contratantes cadastrados
          </Title>
          <Table
            allowControls
            columns={columns}
            data={data.data}
            loading={loading}
            totalData={data.total}
            newButtonLabel="Novo Cadastro"
            onChangePage={getProviders}
            onClickNew={handleOnClickNew}
            hoverComponent={getHoverComponent}
            renderRowSubComponent={renderRowSubComponent}
          />
        </Content>
      </FadeIn>
    </Container>
  );
};

export default List;
