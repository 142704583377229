/* eslint-disable react/display-name */
import { useEffect, useRef, useState, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import Button, { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import IconButton from 'src/components/IconButton';
import { ArrowDown, Chemistry, RemoveCircle } from 'src/components/Icons';
import Select, {
  SelectOptionsGroupedInterface,
  SelectOptionsInterface,
} from 'src/components/Select';
import { defaultOptions, formatErrors } from 'src/libs/yup';
// import { treatmentsSchema } from 'src/validators/providers/store/index';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface ITreatmentsProps {
  formRef: RefObject<FormHandles>;
}

const Treatments = ({ formRef }: ITreatmentsProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [treatmentOptionsCategories, setTreatmentOptionsCategories] = useState(
    [] as any
  );

  const [treatmentOptionsTechnologies, setTreatmentOptionsTechnologies] =
    useState([] as any);

  const [treatmentOptionsClasses, setTreatmentOptionsClasses] = useState(
    [] as any
  );

  const resetForm = () => {
    const formData = formRef.current?.getData();

    formRef.current?.setData({});
    formRef.current?.setErrors({});
    formRef.current?.setFieldValue('treatments', formData?.treatments);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      // const data = await treatmentsSchema.validate(formData, defaultOptions);
      // formTableRef.current?.add({ id: uuidv4(), ...data });
      formTableRef.current?.add({ id: uuidv4(), ...formData });

      setTimeout(() => resetForm(), 200);
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  useEffect(() => {
    const getCategory = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/categories');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setTreatmentOptionsCategories(options);
      } catch (error) {}
    };

    const getTreatmentTechnology = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/treatment/technology'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setTreatmentOptionsTechnologies(options);
      } catch (error) {}
    };

    const getClasses = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/classes');

        const options = Object.keys(data)
          .map((key) => ({
            label: key,
            options: Object.keys(data[key])
              .map((innerKey) => ({
                label: data[key][innerKey],
                value: innerKey,
              }))
              .filter((option) => option.value !== ''),
          }))
          .filter((option) => option.label !== '');

        setTreatmentOptionsClasses(options);
      } catch (error) {}
    };

    getCategory();
    getTreatmentTechnology();
    getClasses();
  }, []);

  const formTableColumns = {
    size: '2fr 2fr 2fr 0.5fr',
    fields: [
      {
        key: 'category',
        label: 'Categoria',
        format: (categoryId) => {
          const categories = treatmentOptionsCategories?.find(
            (categories: SelectOptionsInterface) =>
              categories.value === categoryId
          ) || {
            label: '',
          };

          return categories.label;
        },
      },
      {
        key: 'technology',
        label: 'Tecnologia de Tratamento',
        format: (technologyId) => {
          const technologies = treatmentOptionsTechnologies?.find(
            (technologies: SelectOptionsInterface) =>
              technologies.value === technologyId
          ) || {
            label: '',
          };

          return technologies.label;
        },
      },
      {
        key: 'class',
        label: 'Classe',
        format: (classId) => {
          const classes = treatmentOptionsClasses?.find(
            (classes: SelectOptionsGroupedInterface) =>
              classes.options.find((options) => options.value === classId)
          ) || {
            label: '',
          };

          const options = classes.label
            ? classes.options.find((options) => options.value === classId) || {
                label: '',
              }
            : {
                label: '',
              };

          return `${classes.label} ${
            options.label ? ' (' + options.label + ')' : 'Não informado'
          }`;
        },
      },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton value={id} onClick={handleOnClickRemove}>
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="treatments"
      title="TRATAMENTOS"
      icon={Chemistry}
    >
      <FormGroup columns="1fr 1fr 1fr 0.3fr">
        <Select
          label="Categoria"
          name="category"
          options={treatmentOptionsCategories}
        />
        <Select
          label="Tecnologia de Tratamento"
          name="technology"
          options={treatmentOptionsTechnologies}
        />
        <Select label="Classe" name="class" options={treatmentOptionsClasses} />

        <Button
          ref={addButtonRef}
          type="submit"
          label="Incluir"
          variant="table-inclusion"
          icon={ArrowDown}
        />
      </FormGroup>
    </FormTableInsert>
  );
};

export default Treatments;
