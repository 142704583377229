import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  padding: 32px;

  .root-title.header {
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid #cccccc;
  }
`;

export const Content = styled.div`
  padding: 16px 10px 20px 10px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const ContentList = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-top: 3%;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const Space = styled.div`
  margin-top: 2%;
  margin-bottom: 2%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  svg {
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
