import React, { useState } from 'react'; // react plugin used to create charts

import { useHistory } from 'react-router-dom';

import notificacao_header from 'src/assets/img/notificacao_header.svg';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

const Notifications = (): JSX.Element => {
  const history = useHistory();
  const [mouseouvernotifications, setMouseOuverNotifications] =
    useState<boolean>(false);

  return (
    <div
      onMouseOver={() => {
        setMouseOuverNotifications(true);
      }}
      onMouseLeave={() => {
        setMouseOuverNotifications(false);
      }}
      style={{
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        cursor: 'pointer',
        borderRadius: 7,
        backgroundColor: mouseouvernotifications ? '#327118' : '',
      }}
    >
      <img src={notificacao_header} style={{ width: 25, height: 22 }} />

      <div
        style={{
          position: 'absolute',
          height: '20px',
          top: 70,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 3px 18px #0000003B',
          borderRadius: 4,
          visibility: mouseouvernotifications ? 'visible' : 'hidden',
        }}
      >
        <div
          onMouseLeave={() => {
            setMouseOuverNotifications(false);
          }}
          style={{
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            width: 84,
            height: 20,
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: '#247C49',
              fontWeight: 'bold',
              fontSize: 10,
            }}
          >
            NOTIFICAÇÕES
          </span>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
