/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles } from '@unform/core';
import Button, { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import IconButton from 'src/components/IconButton';
import { ArrowDown, Box, RemoveCircle } from 'src/components/Icons';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import { defaultOptions, formatErrors } from 'src/libs/yup';
// import { storagesSchema } from 'src/validators/providers/store/index';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface IStoragesProps {
  formRef: RefObject<FormHandles>;
}

const Storages = ({ formRef }: IStoragesProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [storagesOptionsTypes, setStoragesOptionsTypes] = useState([] as any);

  const [storagesOptionsPhysicalStates, setStoragesOptionsPhysicalStates] =
    useState([] as any);

  const [storagesOptionsPackagings, setStoragesOptionsPackagings] = useState(
    [] as any
  );

  useEffect(() => {
    const getStorages = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/storages');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setStoragesOptionsTypes(options);
      } catch (error) {}
    };

    const getPhysicalStates = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/physical/states'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setStoragesOptionsPhysicalStates(options);
      } catch (error) {}
    };

    const getPackagings = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/packagings');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setStoragesOptionsPackagings(options);
      } catch (error) {}
    };

    getStorages();
    getPhysicalStates();
    getPackagings();
  }, []);

  const resetForm = () => {
    const formData = formRef.current?.getData();

    formRef.current?.setData({});
    formRef.current?.setErrors({});
    formRef.current?.setFieldValue('storages', formData?.storages);
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      // const data = await storagesSchema.validate(formData, defaultOptions);
      // formTableRef.current?.add({ id: uuidv4(), ...data });
      formTableRef.current?.add({ id: uuidv4(), ...formData });

      setTimeout(() => resetForm(), 200);
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const formTableColumns = {
    size: '2fr 2fr 2fr 0.5fr',
    fields: [
      {
        key: 'type',
        label: 'Tipo de Armazenagem',
        format: (typeId) => {
          const types = storagesOptionsTypes?.find(
            (types: SelectOptionsInterface) => types.value === typeId
          ) || {
            label: '',
          };

          return types.label;
        },
      },
      {
        key: 'physical_state',
        label: 'Estado Físico',
        format: (physicalStateId) => {
          const physicalStates = storagesOptionsPhysicalStates?.find(
            (physicalStates: SelectOptionsInterface) =>
              physicalStates.value === physicalStateId
          ) || {
            label: '',
          };

          return physicalStates.label;
        },
      },
      {
        key: 'packaging',
        label: 'Acondicionamento',
        format: (packagingId) => {
          const packagings = storagesOptionsPackagings?.find(
            (packagings: SelectOptionsInterface) =>
              packagings.value === packagingId
          ) || {
            label: '',
          };

          return packagings.label;
        },
      },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton value={id} onClick={handleOnClickRemove}>
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="storages"
      title="ARMAZENAGEM"
      icon={Box}
    >
      <FormGroup columns="1fr" gap={24}>
        <FormGroup columns="2fr 2fr">
          <Select
            label="Tipo de Armazenagem"
            name="type"
            options={storagesOptionsTypes}
          />
          <Select
            label="Estado Físico"
            name="physical_state"
            options={storagesOptionsPhysicalStates}
          />
        </FormGroup>
        <FormGroup columns="1.85fr 1.5fr 0.3fr">
          <Select
            label="Acondicionamento"
            name="packaging"
            options={storagesOptionsPackagings}
          />
          <Select
            label="Descontar Tara do Acondicionamento"
            name="discount_packaging"
            defaultValue={{
              label: 'Sim',
              value: true,
            }}
            options={[
              {
                label: 'Sim',
                value: true,
              },
              {
                label: 'Não',
                value: false,
              },
            ]}
          />
          <Button
            ref={addButtonRef}
            type="submit"
            label="Incluir"
            variant="table-inclusion"
            icon={ArrowDown}
          />
        </FormGroup>
      </FormGroup>
    </FormTableInsert>
  );
};

export default Storages;
