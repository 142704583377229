import { useCallback, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import { clear } from 'magic-masks';
import FadeIn from 'src/components/FadeIn';
import FormGroup from 'src/components/FormGroup';
import Input from 'src/components/Input';
import Title from 'src/components/Title';

import Register from './Register';
import Services from './Services';
interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

type TFieldType = 'cpf' | 'cnpj' | 'international_register';

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  const [fieldType, setFieldType] = useState<TFieldType>('cnpj');

  const handleOnChangeRegisterType = useCallback((selected) => {
    if (selected)
      setFieldType((currentValue) => {
        return selected.value || currentValue;
      });
  }, []);

  const handleOnChangeCEP = useCallback(
    async (event) => {
      const { value } = event.target;
      if (value.length === 9) {
        const cep = clear(value);

        // Reset when change cep
        formRef.current?.setFieldError('company.address_zip_code', '');
        formRef.current?.setFieldValue('company.address_name', '');
        formRef.current?.setFieldValue('company.address_city', '');
        formRef.current?.setFieldValue('company.address_state', '');

        try {
          const address = await cepPromise(cep);

          // Fill if has cep
          formRef.current?.setFieldValue(
            'company.address_name',
            address.street
          );
          formRef.current?.setFieldValue('company.address_city', address.city);
          formRef.current?.setFieldValue(
            'company.address_state',
            address.state
          );
        } catch (error) {
          formRef.current?.setFieldError(
            'company.address_zip_code',
            'Todos os serviços de CEP não encontraram o valor informado.'
          );
        }
      }
    },
    [formRef]
  );

  return (
    <FadeIn>
      <Form ref={formRef} onSubmit={console.log}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={10}>
            <Title variant="subtitle">Dados do Prestador</Title>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr 1fr 1fr 1fr">
              <Register
                fieldType={fieldType}
                onChange={handleOnChangeRegisterType}
              />
              <Input label="Apelido" name="alias" />
              <Input label="Ramo de atividade" name="segment" />
            </FormGroup>
            <FormGroup columns="1fr 1fr">
              <Input
                label="Razão Social"
                name="company_name"
                required
                readOnly
              />
              <Input
                label="Nome Comercial"
                name="trading_name"
                required
                readOnly
              />
            </FormGroup>
            <FormGroup columns="1fr 1fr 2fr 0.8fr">
              <Input
                label="País"
                name="address_country"
                disabled={fieldType === 'international_register'}
                readOnly
              />
              <Input
                label="CEP"
                name="address_zip_code"
                mask="zipCode"
                placeholder="00000-000"
                onChange={handleOnChangeCEP}
                readOnly
                returnUnmasked
              />
              <Input label="Endereço" name="address_name" required readOnly />
              <Input label="Número" name="address_number" required readOnly />
            </FormGroup>
            <FormGroup columns="1fr 1fr 0.5fr 1.43fr 0.8fr">
              <Input label="Complemento" name="address_complement" readOnly />
              <Input label="Regional" name="region" />
              <Input label="Estado" name="address_state" required readOnly />
              <Input label="Cidade" name="address_city" required readOnly />
              <Input
                label="Telefone"
                name="phone"
                mask="phone"
                placeholder="(00) 0000-0000"
                required
                returnUnmasked
                readOnly
              />
            </FormGroup>
            {fieldType !== 'international_register' && (
              <FormGroup columns="1fr 1fr">
                <Input label="Inscrição Municipal" name="im" readOnly />
                <Input label="Inscrição Estadual" name="ie" readOnly />
              </FormGroup>
            )}
            <FormGroup columns="1fr">
              <Services />
            </FormGroup>
          </Scope>
          <Title variant="subtitle">Dados do responsável</Title>
          <Scope path="responsible[0]">
            <FormGroup columns="2fr 2fr 1fr 1fr">
              <Input
                label="Nome Completo do Responsável"
                name="name"
                required
              />
              <Input label="Cargo" name="role" />
              <Input
                label="Telefone"
                name="telephone"
                mask="phone"
                placeholder="(00) 0000-0000"
                required
                returnUnmasked
              />
              <Input label="Ramal" name="telephone_extension" />
            </FormGroup>
            <FormGroup columns="2fr 1.02fr">
              <Input label="E-mail" name="email" type="email" required />
              <Input
                label="Celular"
                name="cellphone"
                mask="cellphone"
                placeholder="(00) 00000-0000"
                returnUnmasked
              />
            </FormGroup>
          </Scope>
          <Scope path="responsible[1]">
            <FormGroup columns="2fr 2fr 1fr 1fr">
              <Input label="Nome do Segundo Responsável" name="name" />
              <Input label="Cargo do Segundo Responsável" name="role" />
              <Input
                label="Telefone"
                name="telephone"
                mask="phone"
                placeholder="(00) 0000-0000"
                returnUnmasked
              />
              <Input label="Ramal" name="telephone_extension" />
            </FormGroup>
            <FormGroup columns="2fr 1.02fr">
              <Input
                label="E-mail do Segundo Responsável"
                name="email"
                type="email"
              />
              <Input
                label="Celular"
                name="cellphone"
                mask="cellphone"
                placeholder="(00) 00000-0000"
                returnUnmasked
              />
            </FormGroup>
          </Scope>
        </FormGroup>
      </Form>
    </FadeIn>
  );
};

export default BasicData;
