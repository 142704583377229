import yup from 'src/libs/yup';

const schema = yup.object().shape({
  company: yup.object().shape({
    registry_type: yup.string().required(),
    cpf: yup.string().when('registry_type', {
      is: 'cpf',
      then: yup.string().required(),
    }),
    cnpj: yup.string().when('registry_type', {
      is: 'cnpj',
      then: yup.string().required(),
    }),
    international_register: yup.string().when('registry_type', {
      is: 'international_register',
      then: yup.string().required(),
    }),
    alias: yup.string(),
    segment: yup.string(),
    trading_name: yup.string().required(),
    company_name: yup.string().required(),
    address_zip_code: yup.string().required(),
    address_name: yup.string().required(),
    address_number: yup
      .number()
      .required()
      .typeError('Número deve ser numérico'),
    address_complement: yup.string(),
    region: yup.string(),
    address_state: yup.string().required(),
    address_city: yup.string().required(),
    address_country: yup.string().required(),
    phone: yup.string().required(),
    ie: yup.string(),
    im: yup.string(),
    types: yup.array().of(yup.string()).min(1),
    responsible_first: yup.object().shape({
      name: yup.string().required(),
      role: yup.string(),
      telephone: yup.string().required(),
      telephone_extension: yup
        .number()
        .nullable()
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .typeError('Ramal deve ser numérico'),
      email: yup.string().email().required(),
      cellphone: yup.string(),
    }),
    responsible_second: yup.object().shape({
      name: yup.string(),
      role: yup.string(),
      telephone: yup.string(),
      telephone_extension: yup
        .number()
        .nullable()
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .typeError('Ramal deve ser numérico'),
      email: yup
        .string()
        .email()
        .test(
          'equal',
          'O email dos responsáveis não pode ser o mesmo',
          function (secondEmail) {
            const firstEmail =
              this.options.context?.company.responsible_first.email;

            return firstEmail === '' || firstEmail !== secondEmail;
          }
        ),
      cellphone: yup.string(),
    }),
  }),
});

export default schema;
