import { cloneElement } from 'react';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { createPortal } from 'react-dom';

import { Close } from '../Icons';
import { CloseButton, Container, Content } from './styles';

const modalRoot =
  document.getElementById('root-modal') || document.createElement('div');

export interface IModalContentProps {
  closeButton?: JSX.Element;
  closeModal?: (reload?: boolean) => void;
}

interface IModalProps {
  children: JSX.Element;
  onClose?: (reload) => void;
  onOpen?: () => void;
}

export interface IModalHandlers {
  close(reload?: boolean): void;
  open(): void;
}

const ModalWrapper: ForwardRefRenderFunction<IModalHandlers, IModalProps> = (
  { children, onClose, onOpen },
  ref
) => {
  const [showModal, setShowModal] = useState(false);

  const close = useCallback(
    (reload = false) => {
      setShowModal(false);

      if (onClose) {
        onClose(reload);
      }
    },
    [onClose]
  );

  const open = useCallback(() => {
    setShowModal(true);

    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  useImperativeHandle(ref, () => ({
    close,
    open,
  }));

  // Close when press key ESC
  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Escape' && showModal) {
        close();
      }
    },
    [close, showModal]
  );

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.addEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return createPortal(
    <Container showModal={showModal ? 1 : 0}>
      <Content>
        {cloneElement(children, {
          closeModal: close,
          closeButton: (
            <CloseButton className="root-close-button" onClick={close}>
              <Close />
            </CloseButton>
          ),
        })}
      </Content>
    </Container>,
    modalRoot
  );
};

ModalWrapper.displayName = 'ModalWrapper';

export default forwardRef(ModalWrapper);
