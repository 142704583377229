import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 10%;
  marginleft: 5%;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: center;
  align-items: center;
`;

export const TextFooter = styled.span`
  color: #ffffff;
  textshadow: 0px 1px 2px #00000065;
  font-size: 13px;
  margin-bottom: 6px;
`;

export const TextFooterTitle = styled.span`
  color: #77e653;
  text-shadow: 0px 1px 2px #00000065;
  font-weight: bold;
`;
