import { FC, useEffect, useState } from 'react';
import { RootState } from 'src/store/ducks/index';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import format from 'date-fns/format';
import Table from 'src/components/Table';
import { IModalContentProps } from 'src/components/ModalWrapper';
import { Body, Container, Head, Instruction } from './styles';
import api from 'src/services/api';
import { Creators as AuthActions } from 'src/store/ducks/auth';

interface IListModalProps extends IModalContentProps {
  title: string;
  link?: string;
}

const ListModal: FC<IListModalProps> = ({ closeButton, title, link }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { tokenPython } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const getChanges = async () => {
      try {
        if (link) {
          setLoading(true);
          setData([]);
          const response = await api.lighthouse.get(
            `/api/v1/public_systems/logs/${link}`,
            {
              headers: {
                Authorization: `Bearer ${tokenPython}`,
              },
            }
          );
          setData(response.data.payload);
        }
      } finally {
        setLoading(false);
      }
    };

    getChanges();
  }, [link]);

  // Columns of table
  const columns: Column[] = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy HH:MM') : '',
    },
    {
      Header: 'Hora',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'HH:MM') : '',
    },
    {
      Header: 'Descrição',
      accessor: ({ description }) => description || '',
    },
  ];

  return (
    <Container>
      <Head>
        LOG DE SISTEMAS INTEGRADOS - {title} {closeButton}
      </Head>
      <Body>
        <Instruction>
          <Table
            columns={columns}
            data={data}
            loading={loading}
            totalData={data.length}
          />
        </Instruction>
      </Body>
    </Container>
  );
};

export default ListModal;
