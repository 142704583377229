import { useCallback, useEffect, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { clear } from 'magic-masks';
import DatePicker from 'src/components/DatePicker';
import FadeIn from 'src/components/FadeIn';
import FormGroup from 'src/components/FormGroup';
import {
  Employee,
  Contract,
  employeeCool,
  Attachment,
} from 'src/components/Icons';
import { Clip, Close } from 'src/components/Icons';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import FileInput from 'src/components/ListFile';
import Select from 'src/components/Select';
import Switch from 'src/components/Switch';
import Textarea from 'src/components/Textarea';
import Title from 'src/components/Title';

import { CheckBoxBody } from '../styles';
import Register from './Register';

interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  useEffect(() => {
    formRef;
  }, [formRef]);

  return (
    <FadeIn>
      <Form ref={formRef} onSubmit={console.log}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={10}>
            <Title icon={Contract} variant="subtitle">
              DADOS DO CONTRATO
            </Title>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr 1fr 1fr 1fr">
              <Input label="Nr. Interno (ID)" name="interno" maxLength={100} />
              <DatePicker
                label="Início do Contrato"
                placeholder={'dd/mm/aaaa'}
                name="inicio"
              />
              <DatePicker
                label="Término do Contrato"
                placeholder={'dd/mm/aaaa'}
                name="termino"
              />
              <Switch text="Prazo Indeterminado" variant="tinny" />
            </FormGroup>
          </Scope>
          <Title icon={Employee} variant="subtitle">
            REPRESENTANTE COMERCIAL GREENPLAT
          </Title>
          <Scope path="responsible_first">
            <FormGroup columns="2fr 1fr 1fr 2fr">
              <Input
                label="Nome Completo"
                name="name"
                maxLength={100}
                required
              />
              <Input
                label="Telefone"
                name="phone"
                mask={'phone'}
                placeholder={'(00) 0000-0000'}
                maxLength={20}
                required
                returnUnmasked
              />
              <Input label="Ramal" name="phone_extension" maxLength={10} />
              <Input
                label="E-mail"
                name="email"
                type="email"
                maxLength={100}
                required
              />
            </FormGroup>
          </Scope>
          <Title icon={employeeCool} variant="subtitle">
            REPRESENTANTE JURÍDICO GREENPLAT
          </Title>
          <Scope path="responsible_first">
            <FormGroup columns="2fr 1fr 1fr 2fr">
              <Input
                label="Nome Completo"
                name="name"
                maxLength={100}
                required
              />
              <Input
                label="Telefone"
                name="phone"
                mask={'phone'}
                placeholder={'(00) 0000-0000'}
                maxLength={20}
                required
                returnUnmasked
              />
              <Input label="Ramal" name="phone_extension" maxLength={10} />
              <Input
                label="E-mail"
                name="email"
                type="email"
                maxLength={100}
                required
              />
            </FormGroup>
          </Scope>
          <Title icon={Attachment} variant="subtitle">
            DOCUMENTOS ANEXOS
          </Title>
          <Scope path="responsible_first">
            <FormGroup columns="1fr 1fr 1fr">
              <FileInput
                key="Contrato"
                name="contrato"
                label="Contrato(s)"
                accept="application/pdf"
              />
              <FileInput
                key="Proposta"
                name="proposta"
                label="Proposta(s)"
                accept="application/pdf"
              />
              <FileInput
                key="Aditivos"
                name="aditivos"
                label="Aditivos(s)"
                accept="application/pdf"
              />
            </FormGroup>
            <FormGroup columns="1fr">
              <Textarea label="Observações" name="observacoes" />
            </FormGroup>
          </Scope>
        </FormGroup>
      </Form>
    </FadeIn>
  );
};

export default BasicData;
