import { primary } from './_defaultTheme';

export default {
  borderColor: '#D0D0D0',
  backgroundColorHeader: '#F0F0F0',
  backgroundColorBody: '#F5F5F5',
  viewButton: {
    normal: { foreground: '#ffffff', background: primary },
    hover: { foreground: '#ffffff', background: primary },
  },
};
