import React, { useEffect, useState } from 'react'; // react plugin used to create charts
import Lottie from 'lottie-react-web';
import farolIcon from '../../../../../assets/json/farol_icon.json';
import { RootState } from 'src/store/ducks/index';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import api from 'src/services/api';

import notificacao_header from 'src/assets/img/notificacao_header.svg';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

const Notifications = (): JSX.Element => {
  const history = useHistory();
  const [mouseouvernotifications, setMouseOuverNotifications] =
    useState<boolean>(false);
  const integracoes = () => {
    history.push('/integracoes');
  };

  const [data, setData] = useState([]);
  const { tokenPython } = useSelector((state: RootState) => state.auth);

  const renderCards = () => {
    let farol = false;
    data.map((cards, index) => {
      if (cards['value']['status'] !== 'AVAILABLE') {
        farol = true;
      }
    });

    return (
      <div key={1}>
        {farol ? (
          <Lottie
            direction={1}
            options={{
              animationData: farolIcon,
              loop: true,
              autoplay: true,
            }}
            width={30}
            height={30}
          />
        ) : (
          <Lottie
            direction={-1}
            options={{
              animationData: farolIcon,
              loop: false,
              autoplay: false,
            }}
            width={30}
            height={30}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    api.lighthouse
      .post('/api/v1/public_systems/generate_dash', '', {
        headers: {
          Authorization: `Bearer ${tokenPython}`,
        },
      })
      .then((response) => {
        setData(response.data.payload.cards);
      });
  }, []);

  return (
    <div
      onMouseOver={() => {
        setMouseOuverNotifications(true);
      }}
      onMouseLeave={() => {
        setMouseOuverNotifications(false);
      }}
      style={{
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        cursor: 'pointer',
        borderRadius: 7,
        backgroundColor: mouseouvernotifications ? '#327118' : '',
      }}
      onClick={integracoes}
    >
      {renderCards()}
      <div
        style={{
          position: 'absolute',
          height: '20px',
          top: 70,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 3px 18px #0000003B',
          borderRadius: 4,
          visibility: mouseouvernotifications ? 'visible' : 'hidden',
        }}
      >
        <div
          onMouseLeave={() => {
            setMouseOuverNotifications(false);
          }}
          style={{
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            width: 84,
            height: 20,
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: '#247C49',
              fontWeight: 'bold',
              fontSize: 10,
            }}
          >
            INTEGRAÇÕES
          </span>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
