import styled from 'styled-components';

export const Container = styled.div`
  text-align: right;
  width: 100%;
  font-size: 14px;
  line-height: 23px !important;
  color: #ffffff;
  font-family: Open Sans;
  text-shadow: #666 1px 1px 0px;
  padding-right: 5px;
`;

export const NameCarrefour = styled.span`
  color: #004171 !important;
  font-weight: bold !important;
  text-shadow: none !important;
`;

export const NameGreenPlatform = styled.span`
  color: #00f500;
`;
