import React from 'react';

import { Container } from './styles';

interface BockInputArcosProps {
  error: boolean;
}

const BockInputArcos: React.FC<BockInputArcosProps> = ({ children, error }) => (
  <Container error={error}>{children}</Container>
);

export default BockInputArcos;
