import yup from 'src/libs/yup';

export default yup.object().shape({
  uuid_employee: yup.string(),
  name: yup.string().required(),
  function: yup.string(),
  type: yup.string().nullable(),
  cpf: yup.string(),
  moop_expiration: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  moop_file: yup.mixed(),
  license_number: yup.string(),
  license_expiration: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  license_file: yup.mixed(),
  license_type: yup.string(),
  email: yup.string().email().required(),
});
