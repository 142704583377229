import { FC } from 'react';

import { Container, TitleCarrefour, LineTitle, TextCarrefour } from './styles';

const BodyTextLogin: FC = ({ children }) => {
  return (
    <Container>
      <>
        <TitleCarrefour>
          <p>
            SAIBA MAIS SOBRE O <span>CARREFOUR</span>
          </p>
          <LineTitle>
            <>
              <p>.</p>
            </>
          </LineTitle>
        </TitleCarrefour>
        <TextCarrefour>
          <p>
            Ser Carrefour é acreditar no desenvolvimento sustentável, por isso,
            unimos uma perspectiva de um futuro melhor a uma série de ações no
            presente, buscando a criação de um mundo melhor.
          </p>
          <p>
            Com iniciativas que se materializam em nossas lojas, queremos gerar
            uma experiência agradável de compra e demonstrar nossos valores de
            sustentabilidade a nossos clientes.
          </p>
          <p>
            Nossos colaboradores são a essência do Grupo Carrefour e o retrato
            da comunidade na qual estamos inseridos, por isso, contribuímos para
            a inserção deles no mercado de trabalho, priorizando segmentos
            historicamente discriminados.
          </p>
          <p>
            Nosso objetivo é fazer a diferença nos locais em que atuamos, por
            isso, modificamos a realidade dos consummidores e das pessoas ao
            redor através de ações de proteção ambiental, da promoção da
            diversidade e solidariedade.
          </p>
          <p>
            O Grupo Carrefour tem como compromisso permanente, a contribuição
            para o Desmatamento Zero. A fim de transformar esse compromisso em
            realidade, atuamos local e globalmente para conscientizar e engajar
            nossas cadeias produtivas - soja, óleo de palma, pecuária bovina,
            madeira e outras commodities - em modelos mais sustentáveis de
            atuação.
          </p>
          <p>
            Cuidar do meio ambiente é dever de todos. E aqui não poderia ser
            diferente.
          </p>
        </TextCarrefour>
      </>
    </Container>
  );
};

export default BodyTextLogin;
