import { useRef, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Grid from 'src/components/Grid';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Title from 'src/components/Title';
import { RootState } from 'src/store/ducks/index';

import ListModal from '../ListModal';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import {
  SystemPublic,
  Disponivel,
  Instavel,
  Indisponivel,
  CtrE,
  Inea,
  Sinir,
} from 'src/components/Icons';
import Feam from 'src/assets/img/feam.png';
import Fepam from 'src/assets/img/fepam.png';
import ImaAL from 'src/assets/img/ima_al.png';
import ImaSC from 'src/assets/img/ima_sc.png';
import Sigor from 'src/assets/img/sigor.png';
import api from 'src/services/api';

import { Container } from './styles';

import { format } from 'date-fns';
const Public = (): JSX.Element => {
  const dispatch = useDispatch();

  const modalRef = useRef<IModalHandlers>(null);
  const [loading, setLoading] = useState(false);
  const [systemModal, setSystemModal] = useState('');
  const [linkModal, setLinkModal] = useState('');
  const [data, setData] = useState([]);
  const { tokenPython } = useSelector((state: RootState) => state.auth);

  const handleOnModal = (system, link) => {
    setSystemModal(system);
    setLinkModal(link);
    modalRef.current?.open();
  };

  const Status = (status) => {
    switch (status) {
      case 'AVAILABLE':
        return (
          <Disponivel
            style={{
              font: 'normal normal normal 10px/16px Roboto Condensed',
              color: '#B0B0B0',
              marginRight: '15px',
            }}
          />
        );
        break;
      case 'UNSTABLE':
        return (
          <Instavel
            style={{
              font: 'normal normal normal 10px/16px Roboto Condensed',
              color: '#B0B0B0',
              marginRight: '15px',
            }}
          />
        );
        break;
      case 'UNAVAILABLE':
        return (
          <Indisponivel
            style={{
              font: 'normal normal normal 10px/16px Roboto Condensed',
              color: '#B0B0B0',
              marginRight: '15px',
            }}
          />
        );
        break;
    }
  };

  const renderCards = (card) => {
    return (
      data &&
      data.map((cards, index) => (
        <div key={index}>
          {card === cards['type'] ? (
            <p
              className="card-text card-text-container"
              style={{ marginTop: '15px' }}
            >
              {Status(cards['value']['status'])}
              <div className="card-text-wrapper">
                <p className="messageLastUpdate">{cards['value']['message']}</p>
                <p className="dateLastUpdate">
                  {format(new Date(), 'dd/MM/yyyy HH:mm')} -&nbsp;{' '}
                </p>
                <p className="labelLastUpdate">Última atualização</p>
              </div>
            </p>
          ) : (
            ''
          )}
        </div>
      ))
    );
  };

  async function getProviders() {
    try {
      setLoading(true);
      const response = await api.lighthouse.post(
        '/api/v1/public_systems/generate_dash',
        '',
        {
          headers: {
            Authorization: `Bearer ${tokenPython}`,
          },
        }
      );
      setData(response.data.payload.cards);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (tokenPython != null) {
        getProviders();
      } else {
        const response = await api.pvdev.post(
          '/api/v1/ms-dashboards/login',
          ''
        );

        const { ms_dashboard_token } = response.data;

        await dispatch(AuthActions.authTokenPython(ms_dashboard_token));

        getProviders();
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <ModalWrapper ref={modalRef}>
          <ListModal title={systemModal} link={linkModal} />
        </ModalWrapper>
        <Title
          className="titulo-sistema-container"
          icon={SystemPublic}
          variant="title"
        >
          STATUS SISTEMAS PÚBLICOS
        </Title>
        <Grid columns="1fr 1fr 1fr">
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <CtrE className="mr-3 logo-sistema" />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">CTR-E</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Controle de Transporte e Rastreio - Eletrônico
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      PREFEITURA DE SÃO PAULO
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('CTRE')}
              <a
                href="#"
                onClick={() => handleOnModal('CTR-E', 'CTRE')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={Sigor}
                  className="mr-3 logo-sistema"
                  style={{ width: '15%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">SIGOR MTR</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Sistema Estadual de Gerenciamento Online de Resíduos
                      Sólidos
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO DE SÃO PAULO
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('SIGOR')}
              <a
                href="#"
                onClick={() => handleOnModal('SIGOR MTR', 'SIGOR')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={Feam}
                  className="mr-3 logo-sistema"
                  style={{ width: '15%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">FEAM</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Fundação Estadual do Meio Ambiente
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO DE MINAS GERAIS
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('FEAM')}
              <a
                href="#"
                onClick={() => handleOnModal('FEAM', 'FEAM')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div className="line" />
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={Fepam}
                  className="mr-3 logo-sistema"
                  style={{ width: '15%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">FEPAM</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Fundação Estadual de Proteção Ambiental
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO DO RIO GRANDE DO SUL
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('FEPAM')}
              <a
                href="#"
                onClick={() => handleOnModal('FEPAM', 'FEPAM')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={ImaSC}
                  className="mr-3 logo-sistema"
                  style={{ width: '15%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">IMA SC</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Instituto do Meio Ambiente
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      PREFEITURA DE SANTA CATARINA
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('IMASC')}
              <a
                href="#"
                onClick={() => handleOnModal('IMA SC', 'IMASC')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <Sinir className="mr-3 logo-sistema" />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">SINIR</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Sistema Nacional de Informações Sobre a Gestão dos
                      Resíduos Sólidos
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO FEDERAL
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('SINIR')}
              <a
                href="#"
                onClick={() => handleOnModal('SINIR', 'SINIR')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div className="line" />
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <Inea className="mr-3 logo-sistema" />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">INEA</h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Instituto Estadual do Ambiente
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO DO RIO DE JANEIRO
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('INEA')}
              <a
                href="#"
                onClick={() => handleOnModal('INEA', 'INEA')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#fff',
              border: '0 solid #eee',
              borderRadius: '0',
            }}
          >
            <div className="card-body">
              <div className="media" style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={ImaAL}
                  className="mr-3 logo-sistema"
                  style={{ width: '10%' }}
                />
                <div className="media-body overflow-hidden">
                  <h5 className="card-text mb-0 sigla-sistema">IMA AL </h5>
                  <p className="card-text text-muted">
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 14px/16px Roboto',
                        color: '#808080',
                      }}
                    >
                      Instituto do Meio Ambiente
                    </span>
                    <br />
                    <span
                      className="nome-sistema"
                      style={{
                        font: 'normal normal normal 15px/16px Roboto Condensed',
                        color: '#B0B0B0',
                      }}
                    >
                      GOVERNO DE ALAGOAS
                    </span>
                  </p>
                </div>
              </div>
              {renderCards('IMAAL')}
              <a
                href="#"
                onClick={() => handleOnModal('IMA AL', 'IMAAL')}
                className="tile-link"
                style={{ color: '#56AD39', paddingLeft: '35px' }}
              >
                Ver mais...
              </a>
            </div>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default Public;
