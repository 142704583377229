import { FC } from 'react';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { Body, Container, Content } from './styles';

interface IPrivateProps {
  breadcrumbs?: string[];
}

const Private: FC<IPrivateProps> = ({ breadcrumbs, children }) => {
  return (
    <Container>
      <Sidebar />
      <Body id="root_body">
        <Header breadcrumbs={breadcrumbs} />
        <Content>{children}</Content>
        <Footer />
      </Body>
    </Container>
  );
};

export default Private;
