import React from 'react';

import { Container } from './styles';

interface BockInputProps {
  error: boolean;
}

const BockInput: React.FC<BockInputProps> = ({ children, error }) => (
  <Container error={error}>{children}</Container>
);

export default BockInput;
