import { Container, TextFooter, TextFooterTitle } from './styles';

const CopyFooter = (): JSX.Element => {
  return (
    <Container>
      <TextFooter>
        ©2021 <TextFooterTitle>GreenPlat™</TextFooterTitle> — Todos os direitos
        reservados — ver. 3.1.2
      </TextFooter>
    </Container>
  );
};

export default CopyFooter;
