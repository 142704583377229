import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    
    input,
    textarea,    
    button {
      font-family: 'Roboto', sans-serif;
    }
    
    button {
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }

  ::-webkit-scrollbar:horizontal {
      padding: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 21px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 21px;
  }
`;

export default GlobalStyle;
