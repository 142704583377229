import styled from 'styled-components';
interface IContainerProps {
  error: boolean;
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 15px;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  border: 0px !important;
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
`;
