import { useHistory } from 'react-router-dom';
import Legislation from './Legislation';
import Help from './Help';
import ChangeCompany from './ChangeCompany';
import Notifications from './Notifications';
import Integrations from './Integrations';

import Avatar from './Avatar';

import { Container, LegislationIcon } from './styles';

const Central = (): JSX.Element => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/legislacao');
  };

  return (
    <div
      style={{
        position: 'relative',
        paddingLeft: '20px',
        paddingRight: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Legislation />
      <Help />
      <ChangeCompany />
      <Notifications />
      <Integrations />

      <Avatar />

      {/* <LegislationIcon onClick={() => handleClick()}>
        <Legislation />
      </LegislationIcon>
      <div />
      <div />
      <div /> */}
    </div>
  );
};

export default Central;
