import cpfValidator from 'src/utils/cpfValidator';
import { StringSchema } from 'yup';

export default function cpf(this: StringSchema, msg: string): any {
  return this.test({
    name: 'cpf',
    exclusive: false,
    message: msg || '{{label}} não possui um formato válido',
    test(value) {
      if (value) return cpfValidator(value);
      return false;
    },
  });
}
