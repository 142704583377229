import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const FieldContainer = styled.div`
  position: relative;
`;

export const Field = styled.textarea`
  font-size: 0.875rem;
  font-weight: 400;

  resize: vertical;

  width: 100%;
  min-height: 90px;
  max-height: 180px;
  border-radius: 8px;
  padding: 12px;

  ${({ theme }) => css`
    color: ${theme.input.normal.foreground};
    background-color: ${theme.input.normal.background};
    border: 1px solid ${theme.input.normal.border};

    transition: 200ms linear border-color;

    &.error {
      border-color: ${theme.input.error.border};
    }

    :hover,
    :focus {
      border-color: ${theme.input.normal.focus.border};
    }
  `}
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;

  margin-bottom: 2px;
`;

export const CountContainer = styled.div`
  font-size: 0.75rem;
  font-weight: 400;

  text-align: right;
`;

export const Count = styled.div`
  display: inline;
`;
