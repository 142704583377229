/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import format from 'date-fns/format';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface ILicenseProps {
  formRef?: any;
}

interface IFormData {
  id?: string;
  license_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const BasicData = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [residueOptionsType, setResidueOptionsType] = useState([] as any);
  const [residueOptionsCategory, setResidueOptionsCategory] = useState(
    [] as any
  );
  const [
    residueOptionsTreatmentTechnology,
    setResidueOptionsTreatmentTechnology,
  ] = useState([] as any);

  const [residueOptionsClasses, setResidueOptionsClasses] = useState([] as any);
  const [residueOptionsStoragies, setResidueOptionsStoragies] = useState(
    [] as any
  );

  const [residueOptionsFisicalStates, setResidueOptionsFisicalStates] =
    useState([] as any);

  const [residueOptionsPackagings, setResidueOptionsPackagings] = useState(
    [] as any
  );

  useEffect(() => {
    const getTypes = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/types');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsType(options);
      } catch (error) {}
    };

    const getCategory = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/categories');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsCategory(options);
      } catch (error) {}
    };

    const getTreatmentTechnology = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/treatment/technology'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsTreatmentTechnology(options);
      } catch (error) {}
    };

    const getClasses = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/classes');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsClasses(options);
      } catch (error) {}
    };

    const getStoragies = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/storagies');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsStoragies(options);
      } catch (error) {}
    };

    const getFisicalStates = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/fisical/states'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsFisicalStates(options);
      } catch (error) {}
    };

    const getPackagings = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/packagings');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsPackagings(options);
      } catch (error) {}
    };

    getPackagings();
    getTreatmentTechnology();
    getCategory();
    getFisicalStates();
    getStoragies();
    getClasses();
    getTypes();
  }, []);

  const resetForm = () => {
    const { licenses } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = [
    {
      key: 'uuid_license_type',
      label: 'CATEGORIA',
      format: (typeId) => {
        const license = residueOptionsType?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'TECNOLOGIA DE TRATAMENTO' },
    { key: 'code', label: 'CLASSE' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  const formTableColumnsStorage = [
    {
      key: 'uuid_license_type',
      label: 'TIPO DE ARMAZENAGEM',
      format: (typeId) => {
        const license = residueOptionsType?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'ESTADO FÍSICO' },
    { key: 'code', label: 'ACONDICIONAMENTO' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">DADOS DO RESÍDUO</Title>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr 1fr 1fr ">
            <Select
              label="Tipo de Residuo"
              name="uuid_license_type"
              options={residueOptionsType}
            />
            <Input label="Nome Comum do Resíduo*" name="name" />
            <Input label="Código Interno do Resíduo" required name="code" />
          </FormGroup>

          <Title variant="subtitle">TRATAMENTOS</Title>
          <FormGroup columns="2fr 2fr 2fr 1fr">
            <Select
              label="Categoria"
              name="uuid_license_type"
              options={residueOptionsCategory}
            />
            <Select
              label="Tecnologia de Tratamento"
              name="uuid_license_type"
              options={residueOptionsTreatmentTechnology}
            />
            <Select
              label="Classe"
              name="uuid_license_type"
              options={residueOptionsClasses}
            />

            <Button ref={addButtonRef} type="submit" label="Incluir" />
          </FormGroup>
          <FormTable
            ref={formTableRef}
            columns={formTableColumns}
            columnsSize="2fr 2fr 2fr"
            name="licenses"
            showTotal
          />

          <Title variant="subtitle">ARMAZENAGEM</Title>
          <FormGroup columns="2fr 2fr">
            <Select
              label="Tipo de Armazenagem"
              name="uuid_license_type"
              options={residueOptionsStoragies}
            />
            <Select
              label="Estado Físico"
              name="uuid_license_type"
              options={residueOptionsFisicalStates}
            />
          </FormGroup>
          <FormGroup columns="2fr 2fr 1fr">
            <Select
              label="Acondicionamento"
              name="uuid_license_type"
              options={residueOptionsPackagings}
            />
            <Select
              label="Descontar Tara do Acondicionamento"
              name="uuid_license_type"
              options={[
                {
                  label: 'Sim',
                  value: 'no',
                },
                {
                  label: 'Não',
                  value: 'yes',
                },
              ]}
            />

            <Button ref={addButtonRef} type="submit" label="Incluir" />
          </FormGroup>
          <FormTable
            ref={formTableRef}
            columns={formTableColumnsStorage}
            columnsSize="2fr 2fr 2fr"
            name="licenses"
            showTotal
          />
        </FormGroup>
      </Form>
    </FormGroup>
  );
};

export default BasicData;
