/* eslint-disable react/display-name */
import { useCallback, useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';

import { Scope, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import { clear } from 'magic-masks';
import { useSelector } from 'react-redux';
import { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import { Board } from 'src/components/Icons';
import Input from 'src/components/Input';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { RootState } from 'src/store/ducks/index';
import { v4 as uuidv4 } from 'uuid';
import Register from './Register';
import Company from '../../../../../layouts/Private/Header/Company/index';
import Responsible from '../Responsible';

interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

interface ISelectState {
  state: {
    value?: {
      label?: string;
    };
  };
  select: {
    setValue(value: any): void;
  };
}

// international_register
type TFieldType = 'cpf' | 'cnpj' | 'international_register';

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  const [company, setCompany] = useState([] as any);
  const [responsible, setCompanyresponsible] = useState([] as any);
  const [responsibleFirst, setCompanyresponsibleFirst] = useState([] as any);
  const [responsibleSecond, setCompanyresponsibleSecond] = useState([] as any);
  const cityRef = useRef<ISelectState>(null);
  const [providerOptionsStates, setCompanyOptionsStates] = useState([] as any);
  const [providerOptionsCities, setProviderOptionsCities] = useState([] as any);
  const [city, setCity] = useState([] as any);

  const [fieldType, setFieldType] = useState<TFieldType>('cnpj');

  const handleOnChangeRegisterType = useCallback((selected) => {
    if (selected)
      setFieldType((currentValue) => {
        return selected.value || currentValue;
      });
  }, []);

  const getCompanyOptionsCities = useCallback(
    async (code) => {
      if (code?.value !== '' && code?.value !== undefined) {
        try {
          const { data } = await api.providers.get(
            `/api/v1/cities?state=${code.value}`
          );

          const options = Object.keys(data)
            .map((key) => ({
              label: data[key].name,
              value: data[key].name,
            }))
            .filter((option) => option.value !== '');

          setProviderOptionsCities(options);

          const cityOption = formRef.current?.getData();

          if (cityOption?.company.city == '') {
            formRef.current?.setFieldValue('company.address_city', '');
          }
        } catch (error) {}
      }
    },
    [setProviderOptionsCities, formRef]
  );

  const handleOnChangeCEP = useCallback(
    async (event) => {
      const { value } = event.target;
      if (value.length === 9) {
        const cep = clear(value);

        // Reset when change cep
        formRef.current?.setFieldError('company.address_zip_code', '');
        formRef.current?.setFieldValue('company.address_name', '');

        try {
          const address = await cepPromise(cep);

          // Fill if has cep
          formRef.current?.setFieldValue(
            'company.address_name',
            address.street
          );
          formRef.current?.setFieldValue(
            'company.address_state',
            address.state
          );

          await getCompanyOptionsCities({ value: address.state });

          formRef.current?.setFieldValue(
            'company.address_city',
            address.city
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          );
        } catch (error) {
          formRef.current?.setFieldError(
            'company.address_zip_code',
            'Todos os serviços de CEP não encontraram o valor informado.'
          );
        }
      }
    },
    [formRef, getCompanyOptionsCities]
  );

  useEffect(() => {
    const getStates = async () => {
      try {
        const { data } = await api.providers.get('/api/v1/states');

        const options = Object.keys(data).map((key) => ({
          label: `${data[key].federative_unit} - ${data[key].name}`,
          value: data[key].federative_unit,
        }));

        setCompanyOptionsStates(options);
      } catch (error) {}
    };

    getStates();
  }, []);

  useEffect(() => {
    const cityOption = formRef.current?.getData();

    if (cityOption?.company.city && cityOption?.company.city != '') {
      formRef.current?.setFieldValue(
        'company.address_city',
        cityOption?.company.city
      );

      formRef.current?.setFieldValue('company.city', '');
    }
  }, [providerOptionsCities]);

  const handleOnSubmit = async () => {
    const dataCompany = formRef.current?.getData();
  };

  const handleOnChange = useCallback((selected) => {
    if (selected) {
      setFieldType(selected.value);
    }
  }, []);

  return (
    <FormGroup columns="1fr" gap={10}>
      <FormGroup columns="0.1fr 15fr" gap={24}>
        <Board />
        <Title variant="subtitle">DADOS DA EMPRESA</Title>
      </FormGroup>
      <Form ref={formRef} onSubmit={console.log}>
        <Scope path="company">
          <FormGroup columns="1fr" gap={24}>
            <FormGroup columns="1fr 1fr 1fr 1fr">
              <Register
                fieldType={fieldType}
                onChange={handleOnChangeRegisterType}
              />

              {fieldType !== 'international_register' && (
                <>
                  <Input
                    label="Inscrição Municipal"
                    name="im"
                    disabled={true}
                  />
                  <Input label="Inscrição Estadual" name="ie" disabled={true} />
                </>
              )}
            </FormGroup>
            <FormGroup columns="1fr 1fr">
              <Input label="Razão Social" name="company_name" disabled={true} />
              <Input label="Nome Comercial*" name="trading_name" />
            </FormGroup>
            <FormGroup columns="1fr 1fr 1fr">
              <Input label="Apelido/Sigla" name="alias" />
              <Input label="Segmento" name="segment" />
              <Input label="Regional" name="region" />
            </FormGroup>
            <FormGroup columns="1fr 1fr 2fr 1fr">
              <Input
                label="País"
                name="address_country"
                defaultValue={
                  fieldType === 'international_register' ? '' : 'Brasil'
                }
                disabled={fieldType !== 'international_register'}
              />
              <Input
                label="CEP"
                name="address_zip_code"
                mask="zipCode"
                onChange={handleOnChangeCEP}
                returnUnmasked
              />
              <Input label="Endereço" name="address_name" />
              <Input label="Número" name="address_number" />
            </FormGroup>
            <FormGroup columns="2fr 1fr 1fr 1fr">
              <Input label="Complemento" name="address_complement" />
              <Select
                label="Estado"
                name="address_state"
                options={providerOptionsStates}
                onChange={getCompanyOptionsCities}
                required
              />
              <Select
                label="Cidade"
                name="address_city"
                options={providerOptionsCities}
                noOptionsMessage="Selecione um estado para continuar"
                required
              />
              <Input label="city" name="city" hidden={true} />
              <Input
                label="Telefone"
                name="phone"
                mask="phone"
                returnUnmasked
              />
            </FormGroup>
            <FormGroup columns="0.1fr 15fr" gap={24}>
              <Board />
              <Title variant="subtitle">DADOS DO RESPONSÁVEL</Title>
            </FormGroup>
            <Scope path="responsible_first">
              <FormGroup columns="2fr 2fr 1fr 1fr">
                <Input label="Nome responsável" name="name" />
                <Input
                  label="uuid_company_responsible"
                  name="uuid_company_responsible"
                  hidden={true}
                />
                <Input label="Cargo" name="role" />
                <Input
                  label="Telefone"
                  name="telephone"
                  mask="phone"
                  returnUnmasked
                />
                <Input label="Ramal" name="telephone_extension" />
              </FormGroup>
              <FormGroup columns="2fr 1fr">
                <Input label="E-mail" name="email" type="email" />
                <Input
                  label="Celular"
                  name="cellphone"
                  mask="cellphone"
                  returnUnmasked
                />
              </FormGroup>
            </Scope>
            <Scope path="responsible_second">
              <FormGroup columns="2fr 2fr 1fr 1fr">
                <Input label="Nome do Segundo Responsável" name="name" />
                <Input label="Cargo do Segundo Responsável" name="role" />
                <Input
                  label="uuid_company_responsible"
                  name="uuid_company_responsible"
                  hidden={true}
                />
                <Input
                  label="Telefone"
                  name="telephone"
                  mask="phone"
                  returnUnmasked
                />
                <Input label="Ramal" name="telephone_extension" />
              </FormGroup>
              <FormGroup columns="2fr 1fr">
                <Input
                  label="E-mail do Segundo Responsável"
                  name="email"
                  type="email"
                />
                <Input
                  label="Celular"
                  name="cellphone"
                  mask="cellphone"
                  returnUnmasked
                />
              </FormGroup>
            </Scope>
          </FormGroup>
        </Scope>
      </Form>
    </FormGroup>
  );
};

export default BasicData;
