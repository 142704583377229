import styled, { css, keyframes } from 'styled-components';

const bounceDelay = keyframes`
  0%,
  80%,
  100% {    
    transform: scale(0);
  }
  40% {    
    transform: scale(1);
  }
`;

export interface IContainerProps {
  color?: string;
  size?: number;
}

export const Container = styled.div<IContainerProps>`
  text-align: center;

  > div {
    ${({ color, size = 13, theme }) => css`
      width: ${size}px;
      height: ${size}px;
      background-color: ${color || theme.pulseLoader.color};
    `}

    border-radius: 100%;
    display: inline-block;
    animation: 1.4s ${bounceDelay} infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`;

export const Dot = styled.div``;
