import styled from 'styled-components';

export const Container = styled.button`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
  width: 100%;

  padding: 12px 8px;
  border-radius: 4px;
  cursor: pointer;

  border: none;
  background-color: transparent;
  transition: background-color 200ms ease-in-out;

  &.active {
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

  :not(.active):hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  > * {
    pointer-events: none;
  }
`;

export const IconBox = styled.div`
  svg {
    font-size: 24px;
    color: ${({ theme }) => theme.primary};
  }
`;

export const Label = styled.div`
  font-size: 1rem;
  /* font-weight: 600; */
  align-self: center;
  text-align: left;
`;
