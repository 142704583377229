import {
  ButtonHTMLAttributes,
  cloneElement,
  ComponentType,
  ReactElement,
  useState,
} from 'react';

import Can from 'src/components/Can';
import { v4 as uuidv4 } from 'uuid';

export interface IMenu extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: ComponentType;
  active?: boolean;
  controls?: ReactElement;
}

interface IContent {
  menu: IMenu;
  form: ReactElement;
  controls?: ReactElement;
}

export interface IUseEditFormProps {
  config: IContent[];
}

type IContentState = IContent & { id: string; active: boolean };

export interface IUseEditForm {
  menus: IMenu[];
  forms: ReactElement[];
}

const useEditForm = ({ config }: IUseEditFormProps): IUseEditForm => {
  const updatedConfig = config.map((cfg, index) => ({
    id: uuidv4(),
    active: index === 0,
    ...cfg,
  }));
  const [content, setContent] = useState<IContentState[]>(updatedConfig);

  const handleOnClickMenu = (event) => {
    const { id } = event.target;

    setContent((content) =>
      content.map((c) => ({ ...c, active: id === c.id }))
    );
  };

  const getMenus = () =>
    content.map(({ id, controls, active, menu }) => ({
      id,
      active,
      controls,
      onClick: handleOnClickMenu,
      ...menu,
    }));

  const getForms = () =>
    content.map(({ id, active, form }, index) => (
      <Can key={index.toString()} see={active}>
        {cloneElement(form, { id })}
      </Can>
    ));

  return {
    menus: getMenus(),
    forms: getForms(),
  };
};

export default useEditForm;
