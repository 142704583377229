import { FC } from 'react';

import imageWebSite from 'src/assets/img/Icon-globe.svg';
import imageMail from 'src/assets/img/Icon-mail.svg';
import imagePhone from 'src/assets/img/Icon-phone.svg';
import greenPlateNegative from 'src/assets/img/logo-greenplat-negative-arcos.svg';
import imageInstagram from 'src/assets/img/logo-insta.svg';
import imageIn from 'src/assets/img/logo-linkedin.svg';
import imageWhatsApp from 'src/assets/img/logo-wpp.svg';

import {
  Container,
  LeftContactArcos,
  LeftContactArcosImage,
  LeftContactArcosSocial,
  RightContactArcos,
} from './styles';

const ContactGreenPlateArcos: FC = ({ children }) => {
  return (
    <Container>
      <div>
        <section>
          <LeftContactArcos>
            <div>
              <LeftContactArcosImage>
                <div>
                  <img src={greenPlateNegative} alt="Arcos Dourados" />
                </div>
              </LeftContactArcosImage>

              <LeftContactArcosSocial>
                <div>
                  <a href="https://www.linkedin.com/company/plataforma-verde">
                    <img src={imageIn} alt="" />
                  </a>
                  <a href="https://www.instagram.com/plataformaverde/">
                    <img src={imageInstagram} alt="" />
                  </a>
                  <a href="https://arcosdourados.plataformaverde.com.br/">
                    <img src={imageWhatsApp} alt="" />
                  </a>
                </div>
              </LeftContactArcosSocial>
            </div>
          </LeftContactArcos>

          <RightContactArcos>
            <div className="rightContactArcos">
              <ul>
                <li>
                  <a href="tel:1123374049">
                    <img src={imagePhone} alt="" /> (11) 2337-4049
                  </a>
                </li>
                <li>
                  <a href="mailto:suporte@plataformaverde.com.br">
                    <img src={imageMail} alt="" />{' '}
                    suporte@plataformaverde.com.br
                  </a>
                </li>
                <li>
                  <a href="https://greenplat.com/">
                    <img src={imageWebSite} alt="" /> greenplat.com
                  </a>
                </li>
              </ul>
            </div>
          </RightContactArcos>
        </section>
      </div>
    </Container>
  );
};

export default ContactGreenPlateArcos;
