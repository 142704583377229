import { InputHTMLAttributes, useEffect, useRef } from 'react';

import { SearchArrow } from '../Icons';
import { Container, IconBox, Input } from './styles';

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onStopTyping?: (value: string) => void;
}

const SearchInput = ({ onStopTyping }: ISearchInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let timer: any = undefined;

    const typing = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        const value = ref?.value || '';
        if (onStopTyping) onStopTyping(value);
      }, 500);
    };

    const ref = inputRef.current;
    if (onStopTyping) ref?.addEventListener('keyup', typing);

    return () => {
      if (onStopTyping) ref?.removeEventListener('keyup', typing);
    };
  }, [onStopTyping]);

  return (
    <Container className="root-search-input">
      <IconBox>
        <SearchArrow />
      </IconBox>
      <div />
      <Input ref={inputRef} type="text" placeholder="Pesquisa" />
    </Container>
  );
};

export default SearchInput;
