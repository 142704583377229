import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  border-top: 1px solid #ffffff !important;
  width: 100%;
  section {
    display: flex;
    justify-content: space-between;
  }
`;

export const LeftContactArcos = styled.div`
  margin-top: 10px;
`;

export const LeftContactArcosImage = styled.div`
  margin-top: 10px;
`;

export const LeftContactArcosSocial = styled.div`
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-left: 50px;
  img {
    margin-right: 10px;
  }
`;

export const RightContactArcos = styled.div`
  margin-top: 10px;
  ul {
    list-style: none;
  }
  ul li {
    height: 25px;
    line-height: 25px;
    width: 100%;
    margin-bottom: 5px;
  }
  ul li img {
    vertical-align: middle;
    margin-right: 15px;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    line-height: 26px;
    color: #ffffff;
    cursor: pointer;
  }
`;
