import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  padding: 32px;

  .root-title.header {
    padding-bottom: 8px;
    margin-bottom: 32px;
    border-bottom: 1px solid #cccccc;
  }
`;
