import styled, { keyframes } from 'styled-components';

import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

export interface IContainerProps {
  delay?: string;
  duration?: string;
}

export const Container = styled.div<IContainerProps>`
  animation: ${({ duration }) => duration || 500}ms ${fadeInAnimation};
  animation-delay: ${({ delay }) => delay || 0}ms;
`;
