/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import format from 'date-fns/format';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface ILicenseProps {
  formRef?: any;
}

interface IFormData {
  id?: string;
  license_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const IntegratedSystems = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [residueOptions, setResidueOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.residues.get('/api/v1/residues/types');

        const options = response.data.map(({ uuid_license_type, name }) => ({
          label: name,
          value: uuid_license_type,
        }));

        setResidueOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  const resetForm = () => {
    const { licenses } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = [
    { key: 'name', label: 'NÚMERO' },
    { key: 'code', label: 'GERENCIADOR' },
    { key: 'code', label: 'PERÍODO NEGOCIAÇÃO' },
    { key: 'code', label: 'TIPO NEGOCIAÇÃO' },
    { key: 'code', label: 'TIPO DE LANÇAMENTO' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">SISTEMAS INTEGRADOS</Title>
      <p>
        O uso de Sistemas Integrados dentro da PlataformaVerde™ possibilita a
        comunicação entre sistemas de empresas parceiras ou entidades
        governamentais. Com isso, se faz necessário a indicação nos campos
        abaixo de itens equivalentes dentros de ambos sistemas. Na listagem
        abaixo você encontra os dados do resíduo que precisam ter suas
        indicações de equivalências entre a PlataformaVerde™ e os sistemas
        integrados cadastrados. Caso algum item não tenha sido indicado na
        tabela abaixo a PlataformaVerde™ solicitará a indicação pendente no ato
        de emitir uma saída com sistemas integrados.
      </p>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="2fr 2fr">
            <p>PLATAFORMAVERDE</p>
            <p>SISTEMA INTEGRADO (CTR-E)</p>
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Resíduo" name="name" />
            <Select
              label="Resíduo"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Acondicionamento" name="name" />
            <Select
              label="Acondicionamento"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Classe" name="name" />
            <Select
              label="Classe"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Unidade" name="name" />
            <Select
              label="Unidade"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <p>PLATAFORMAVERDE</p>
            <p>SISTEMA INTEGRADO (FEPAM)</p>
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Resíduo" name="name" />
            <Select
              label="Resíduo"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Acondicionamento" name="name" />
            <Select
              label="Acondicionamento"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Classe" name="name" />
            <Select
              label="Classe"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Input label="Unidade" name="name" />
            <Select
              label="Unidade"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>
        </FormGroup>
      </Form>
    </FormGroup>
  );
};

export default IntegratedSystems;
