import { useEffect, useState } from 'react';

import format from 'date-fns/format';
import { Column } from 'react-table';
import { IModalContentProps } from 'src/components/ModalWrapper';
import api from 'src/services/api';

import Table from '../Table';
import { Body, Container, Head } from './styles';

interface IJustificationModal extends IModalContentProps {
  getURL: string;
  text: string;
}

const JustificationModal = ({
  closeButton,
  getURL,
  text,
}: IJustificationModal): JSX.Element => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getChanges = async () => {
      try {
        const response = await api.providers.get(getURL);

        setData(response.data);
      } catch (error) {}
    };

    getChanges();
  }, [getURL]);

  // Columns of table
  const columns: Column[] = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
  ];

  return (
    <Container>
      <Head>
        Justificativas - {text} {closeButton}
      </Head>
      <Body>
        <Table
          columns={columns}
          data={data}
          loading={false}
          totalData={data.length}
        />
      </Body>
    </Container>
  );
};

export default JustificationModal;
