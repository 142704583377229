import {
  Container,
  BodyKilos,
  TitleKilos,
  CountKilos,
  CountTextKilos,
} from './styles';
import recycle from 'src/assets/img/recycle.svg';
const KilosFooter = (): JSX.Element => {
  return (
    <Container>
      <img src={recycle} style={{ width: 24, height: 24, marginTop: 18 }} />

      <TitleKilos>Resíduos Reciclados</TitleKilos>

      <BodyKilos>
        <CountKilos
          style={{
            fontSize: 20,
            color: '#FFFFFF',
            textShadow: '0px 1px 2px #00000065',
          }}
        >
          1.234.567.891,23
        </CountKilos>
        <CountTextKilos>(quilos)</CountTextKilos>
      </BodyKilos>
    </Container>
  );
};

export default KilosFooter;
