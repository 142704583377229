import { FC } from 'react';

import image6 from 'src/assets/img/greenplat-arcos.png';
import image1 from 'src/assets/img/img-arcos-01.png';
import image2 from 'src/assets/img/img-arcos-02.png';
import image3 from 'src/assets/img/img-arcos-03.png';
import image4 from 'src/assets/img/img-arcos-04.png';
import image5 from 'src/assets/img/img-arcos-05.png';

import {
  Container,
  GridArcos,
  TitleTextArcosLeft,
  GridImageArcosRight,
  GridImageArcosLeft,
  TitleTextArcosRight,
  GridImagesArcos,
  InfoGreenPlatArcos,
} from './styles';

const ContentTextPage: FC = ({ children }) => {
  return (
    <Container>
      <div>
        <h1>A ARCOS DOURADOS ESTÁ NA PLATAFORMAVERDE™</h1>
        <h3>
          Aqui você irá gerenciar seus resíduos de forma online, rápida e fácil
          utilizando o software da GreenPlat™ – a primeira rede mundial de
          gestão ambiental online.
        </h3>

        <GridArcos>
          <div>
            <TitleTextArcosLeft>
              <div>
                <h5>RECEITA DO FUTURO</h5>
                <p>
                  A Arcos Dourados, franquia independente do McDonald’s, tem o
                  compromisso de ser uma parte ativa da solução aos desafios que
                  nossa sociedade enfrenta atualmente, sempre trazendo propostas
                  inovadoras.
                </p>
                <p>
                  Por isso, assumimos um compromisso com a sustentabilidade e,
                  ao seu lado, queremos colocar em prática ações que tornarão o
                  mundo melhor para todos nós utilizando o SAAS de gerenciamento
                  de resíduos reconhecido pelo Fórum Econômico Mundial.
                </p>
              </div>
            </TitleTextArcosLeft>

            <GridImageArcosRight>
              <div>
                <img src={image1} alt="Arcos Dourados" />
              </div>
            </GridImageArcosRight>
          </div>
        </GridArcos>

        <GridArcos>
          <div>
            <GridImageArcosLeft>
              <div>
                <img src={image2} alt="Arcos Dourados" />
              </div>
            </GridImageArcosLeft>

            <TitleTextArcosRight>
              <div>
                <h5>O QUE VOCÊ VAI ENCONTRAR NESTE PORTAL?</h5>
                <p>
                  Neste portal de Gerenciamento Ambiental Arcos Dourados você
                  poderá, de maneira 100% online e digital, realizar a gestão
                  dos resíduos de todos os restaurantes da rede McDonald’s no
                  Brasil com a PlataformaVerde™, além de todos os fornecedores,
                  transportadores e parceiros.
                </p>
                <p>
                  Facilidade, rastreabilidade e gestão na palma da mão são as
                  palavras chaves dessa plataforma para Pedidos de Coleta,
                  Saídas de Resíduos, Controle de KPI’s, Geração de Manifestos
                  de Transporte de Resíduos - MTR – e muito mais.
                </p>
              </div>
            </TitleTextArcosRight>
          </div>
        </GridArcos>

        <GridImagesArcos>
          <div>
            <img src={image3} alt="Arcos Dourados" />
            <img src={image4} alt="Arcos Dourados" />
            <img src={image5} alt="Arcos Dourados" />
          </div>
        </GridImagesArcos>

        <InfoGreenPlatArcos>
          <div className="infoGreenPlatArcos">
            <div className="infoGreenImageArcos">
              <img src={image6} alt="Arcos Dourados" />
            </div>
            <section className="infoGreenTextArcos">
              <h4>SOBRE A PLATAFORMAVERDE</h4>
              <p>
                A <strong>GreenPlat</strong> é uma empresa de tecnologia que
                desenvolveu a <strong>PlataformaVerde™</strong>, um SaaS com
                arquitetura baseada em blockchain que permite em tempo real o
                controle e gestão dos resíduos desde a origem até a destinação
                final, além de controles de documentos e processos.
              </p>
              <p>
                Com a <strong>PlataformaVerde™</strong> você irá alcançar aterro
                zero, aumentar as taxas de reciclagem e estar sempre em
                conformidade ambiental.
              </p>
              <p>
                Desde 2018 a <strong>GreenPlat</strong> faz parte do Fórum
                Econômico Mundial na categoria Pioneiros em Tecnologia, sendo a
                primeira empresa a usar a tecnologia Blockchain para
                rastreabilidade de resíduos e até hoje lidera projetos em âmbito
                mundial
              </p>
            </section>
          </div>
        </InfoGreenPlatArcos>
      </div>
    </Container>
  );
};

export default ContentTextPage;
