export const primary = '#56AD39';
export const secondary = '#009053';

export const colors = {
  gray1: '#8E8E93',
  gray2: '#636366',
  gray3: '#48484A',
  gray4: '#505050',
  gray5: '#2C2C2E',
  gray6: '#1C1C1E',
  error: '#FF3B30',
  info: '#5AC8FA',
  warning: '#FFCC01',
  primary,
  secondary,
  success: '#34C759',
};

export default {
  background: '#edf2eb',
  boxShadow: 'rgb(204 204 204) 0px 0px 30px -3px',
  ...colors,
  primary,
  secondary,
};
