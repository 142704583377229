import styled from 'styled-components';

export const Container = styled.div`
  height: 6.875rem;
  padding: 8px;
  border-radius: 12px;
  background-color: #ebf0fa;
  box-shadow: 0px 1px 2px #174da380;
  position: relative;

  .close_btn {
    width: 12px;
    height: 12px;
    background: #c0c0c0 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7px;
    right: 7px;
    color: #ebf0fa;
    font-size: 12px;
    cursor: pointer;
  }

  .icon-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 3px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: 7px;
    right: 7px;
  }
`;

export const Value = styled.div`
  color: #759fe3;
  font-size: 2.5rem;
  line-height: 3.125rem;
`;

export const Text = styled.div`
  text-transform: uppercase;
  color: #174da3;
  font-weight: 500;
  font-size: 0.8125rem;
  font-weight: medium;
  max-width: 70%;
`;
