import { FC, useState } from 'react';

import { Burger, Close } from 'src/components/Icons';

import { Container, Content, Controls, Title, ToggleButton } from './styles';

const ColumnsControl: FC = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const handleOnClick = () => setVisible((b) => !b);

  return (
    <Container>
      <ToggleButton type="button" onClick={handleOnClick}>
        {visible ? <Close /> : <Burger />}
      </ToggleButton>
      <Content visible={visible}>
        <Title>Colunas</Title>
        <Controls>{children}</Controls>
      </Content>
    </Container>
  );
};

export default ColumnsControl;
