import styled from 'styled-components';

export const Container = styled.div`
  clear: both;
  background-color: #ffffff;
  padding: 30px 80px;
`;

export const TitleCarrefour = styled.p`
  text-transform: uppercase;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 26px;
  color: #024e9f;
  span {
    font-weight: bold !important;
    font-size: 50px;
    color: #024e9f;
  }
`;

export const LineTitle = styled.div`
  font-size: 0px !important;
  margin-bottom: 15px;
  width: 44%;
  border-bottom: 1px solid #000000;
  font-size: 0;
`;

export const TextCarrefour = styled.p`
  color: #024e9f;
  font-size: 16px;
  line-height: 22px;
`;
