import { FC, createRef } from 'react';
import { RefObject } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { IModalContentProps } from 'src/components/ModalWrapper';

import Button, { IButtonHandlers } from '../Button';
import { Body, Container, Footer, Head } from './styles';

interface IFormModalProps extends IModalContentProps {
  formRef: RefObject<FormHandles>;
  buttonRef?: RefObject<IButtonHandlers>;
  onSubmit: SubmitHandler;
  title: string;
}

const FormModal: FC<IFormModalProps> = ({
  closeButton,
  children,
  formRef,
  buttonRef = createRef(),
  onSubmit,
  title,
}) => {
  const handleOnClickSend = () => formRef.current?.submitForm();

  return (
    <Container>
      <Head>
        {title} {closeButton}
      </Head>
      <Body>
        <Form ref={formRef} onSubmit={onSubmit}>
          {children}
        </Form>
      </Body>
      <Footer>
        <Button ref={buttonRef} label="Salvar" onClick={handleOnClickSend} />
      </Footer>
    </Container>
  );
};

export default FormModal;
