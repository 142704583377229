import { ButtonHTMLAttributes, FC } from 'react';

import { Button } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  tooltip?: string;
}

const IconButton: FC<IButtonProps> = ({
  children,
  type = 'button',
  tooltip,
  ...rest
}) => {
  return (
    <Button {...rest} tooltip={tooltip || ''}>
      {children}
    </Button>
  );
};

export default IconButton;
