import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';

import { Done } from '../Icons';
import {
  Container,
  Content,
  Line,
  Step,
  StepLabel,
  StepNumberContainer,
  StepNumber,
} from './styles';

export interface IStepperHandlers {
  next(): void;
  previous(): void;
}

interface IStepperProps {
  current?: number;
  steps: string[];
}

const Stepper: ForwardRefRenderFunction<IStepperHandlers, IStepperProps> = (
  { current, steps },
  ref
) => {
  const [stepNumber, setStepNumber] = useState(0);

  useImperativeHandle(ref, () => ({
    next: () =>
      setStepNumber((csn) => (csn + 1 <= steps.length - 1 ? csn + 1 : csn)),
    previous: () => setStepNumber((csn) => (csn - 1 >= 0 ? csn - 1 : csn)),
  }));

  return (
    <Container>
      <Content steps={steps.length}>
        {steps.map((label, index) => (
          <Step key={index.toString()}>
            <StepLabel>
              {(current || stepNumber) === index ? (
                <strong>{label}</strong>
              ) : (
                `${label}`
              )}
            </StepLabel>
            <StepNumberContainer>
              <StepNumber
                complete={(current || stepNumber) >= index || undefined}
              >
                {(current || stepNumber) > index ? <Done /> : index + 1}
              </StepNumber>
            </StepNumberContainer>
          </Step>
        ))}
      </Content>
      <Line steps={steps.length} currentStep={current || stepNumber} />
    </Container>
  );
};

Stepper.displayName = 'Stepper';

export default forwardRef(Stepper);
