import { createActions, createReducer } from 'reduxsauce';

/* Types & Actions Creators */
export const { Types, Creators } = createActions({
  authFailure: [],
  authCompany: ['token', 'companySelected', 'modules'],
  authSuccess: ['token', 'companySelected', 'user'],
  authModule: ['token', 'companyModuled'],
  authLogout: [],
  authLoading: ['loading'],
  authTokenPython: ['tokenPython'],
  authSelectCompanie: ['token', 'modules'],
});

interface AuthState {
  readonly token: string | null;
  readonly tokenPython: string | null;
  readonly isAuthenticated: boolean;
  readonly loading: boolean;
  readonly companySelected: [] | any;
  readonly user: [] | any;
  readonly companyModuled: [] | any;
  readonly modules: [];
}

/* Initial State */
const INITIAL_STATE: AuthState = {
  token: null,
  tokenPython: null,
  isAuthenticated: false,
  loading: false,
  modules: [],
  companySelected: [],
  user: [],
  companyModuled: [],
};

/* Reducers */
const authFailure = () => INITIAL_STATE;

const authLoading = (state, { loading }) => ({
  ...state,
  loading,
});

const authTokenPython = (state, { tokenPython }) => ({
  ...state,
  tokenPython,
});

const authCompany = (state, { token, companySelected, modules }) => ({
  ...state,
  token,
  companySelected: [companySelected],
  modules,
});

const authModule = (state, { token, modules }) => ({
  ...state,
  isAuthenticated: true,
  token,
  modules: [modules],
});

const authSuccess = (state, { token, user, companySelected }) => ({
  ...state,
  isAuthenticated: true,
  token,
  companySelected: companySelected,
  user: user,
});

const authSelectCompanie = (state, { token, modules }) => ({
  ...state,
  token,
  modules,
});

const authLogout = () => INITIAL_STATE;

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_COMPANY]: authCompany,
  [Types.AUTH_MODULE]: authModule,
  [Types.AUTH_LOGOUT]: authLogout,
  [Types.AUTH_LOADING]: authLoading,
  [Types.AUTH_TOKEN_PYTHON]: authTokenPython,
  [Types.AUTH_SELECT_COMPANIE]: authSelectCompanie,
});

export default reducer;
