import Title from 'src/components/Title';
import { useRef, useState, useEffect } from 'react';
import { RootState } from 'src/store/ducks/index';
import { useSelector } from 'react-redux';
import Indicators from './Indicators';
import { useDispatch } from 'react-redux';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { Container } from './styles';
import api from 'src/services/api';

const Dashboard = (): JSX.Element => {
  const { tokenPython } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      if (tokenPython != null) {
      } else {
        const response = await api.pvdev.post(
          '/api/v1/ms-dashboards/login',
          ''
        );

        const { ms_dashboard_token } = response.data;

        if (ms_dashboard_token === null) {
          fetchData();
          return false;
        } else {
          setTimeout(function () {
            fetchData();
          }, 10 * 60 * 1000);
        }

        dispatch(AuthActions.authTokenPython(ms_dashboard_token));
      }
    };
    fetchData();
  }, []);

  return (
    <Container>
      <Title variant="header">Painel de Controle</Title>
      <Indicators />
    </Container>
  );
};

export default Dashboard;
