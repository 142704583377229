import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const Content = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(224 224 224) 0px 4px 10px 4px;
  padding: 24px;
  margin-top: 16px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  margin-top: 30px;

  button:first-child {
    margin-right: auto;
  }
`;

export const Head = styled.div``;

export const HeadContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: cennter;

  padding-bottom: 8px;
  margin-bottom: 40px;

  position: relative;

  & .helper {
    position: absolute;
    right: 0;
    bottom: 25px;
  }
`;

export const CheckBoxBody = styled.div`
  input:checked {
    background: red;
  }
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 300;
  color: #6f6f6f;
  margin-bottom: 6%;
`;

export const Card = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f5f5;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
