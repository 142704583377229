/* eslint-disable react/display-name */
import { useEffect, useRef, useState, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import IconButton from 'src/components/IconButton';
import { RemoveCircle, License } from 'src/components/Icons';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import { licensesSchema } from 'src/validators/providers/store/index';
import { v4 as uuidv4 } from 'uuid';
interface ILicenseProps {
  formRef: RefObject<FormHandles>;
}

const Licenses = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [licenceOptions, setLicenceOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.providers.get('/api/v1/licenses/types');

        const options = response.data
          .map(({ uuid_license_type, name }) => ({
            label: name,
            value: uuid_license_type,
          }))
          .sort((a, b) => (a.label > b.label ? 0 : -1));

        setLicenceOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  const resetForm = () => {
    const formData = formRef.current?.getData();

    formRef.current?.setData({});
    formRef.current?.setErrors({});
    formRef.current?.setFieldValue('licenses', formData?.licenses);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      const data = await licensesSchema.validate(formData, defaultOptions);

      const hasItems = formRef.current
        ?.getFieldValue('licenses')
        .find(
          (item) =>
            item.uuid_license_type === data.uuid_license_type &&
            item.name === data.name &&
            item.code === data.code &&
            format(item.emission, 'yyyy-MM-dd') ===
              format(data.emission, 'yyyy-MM-dd')
        );

      if (hasItems) {
        throw new Error('Licença já adicionada');
      }

      formTableRef.current?.add({ id: uuidv4(), ...data });

      setTimeout(() => resetForm(), 200);
    } catch (error: any) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }

      if (error.message) {
        toast.error(error.message);
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = {
    size: '2fr 1.5fr 1.6fr 1.2fr 1.25fr 1.2fr 1.7fr 0.2fr',
    fields: [
      {
        key: 'uuid_license_type',
        label: 'Tipo de licença',
        format: (typeId) => {
          const license = licenceOptions?.find(
            (license: SelectOptionsInterface) => license.value === typeId
          ) || {
            label: '',
          };

          return license.label;
        },
      },
      { key: 'name', label: 'Nome do documento' },
      { key: 'code', label: 'Número do documento' },
      {
        key: 'emission',
        label: 'Data de emissão',
        format: (emission) => format(emission, 'dd/MM/yyyy'),
      },
      {
        key: 'expiration',
        label: 'Data de validade',
        format: (expirationDate) =>
          expirationDate
            ? format(expirationDate, 'dd/MM/yyyy')
            : 'Não informado',
      },
      {
        key: 'alert_date',
        label: 'Data de alerta',
        format: (alertDate) =>
          alertDate ? format(alertDate, 'dd/MM/yyyy') : 'Não informado',
      },
      {
        key: 'file',
        label: 'Documento',
        format: (document) => document?.name || 'Documento',
      },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton value={id} onClick={handleOnClickRemove}>
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="licenses"
      title="Adicionar licenças"
      icon={License}
      optional
    >
      <FormGroup columns="1fr 1fr 1fr">
        <Select
          label="Tipo de Licença"
          name="uuid_license_type"
          options={licenceOptions}
          required
        />
        <Input label="Nome do Documento" name="name" maxLength={255} required />
        <Input
          label="Número do Documento"
          name="code"
          maxLength={20}
          required
        />
      </FormGroup>
      <FormGroup columns="0.8fr 0.8fr 0.8fr 2.01fr 0.4fr">
        <DatePicker
          label="Data de emissão"
          name="emission"
          placeholder="dd/mm/aaaa"
          filterDate={(date) => {
            return date < new Date();
          }}
          required
        />
        <DatePicker
          label="Data de validade"
          name="expiration"
          placeholder="dd/mm/aaaa"
        />
        <DatePicker
          label="Data de alerta"
          name="alert_date"
          placeholder="dd/mm/aaaa"
          filterDate={(date) => {
            return date >= new Date();
          }}
        />
        <InputFile label="Anexar documento" name="file" accept="*" required />
        <Button
          ref={addButtonRef}
          variant="table-inclusion"
          type="submit"
          label="Incluir"
        />
      </FormGroup>
    </FormTableInsert>
  );
};

export default Licenses;
