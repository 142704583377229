import React, { useState, useEffect } from 'react'; // react plugin used to create charts
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/ducks/index';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import avatar from 'src/assets/img/avatar.svg';
import seta_branca from 'src/assets/img/seta_branca.svg';
import Dropdown from './Dropdown';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

const Notifications = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [mouseouvername, setMouseOuverName] = useState<boolean>(false);
  const [mouseouvernameclick, setMouseOuverNameClick] =
    useState<boolean>(false);

  // const { user } = useSelector((state: RootState) => state.auth);

  const firstName = (text) => {
    if (text.split(' ').length > 1) {
      return text.split(' ')[0];
    }
    return text;
  };

  const RedirectLogin = () => {
    history.push('/');
  };

  // useEffect(() => {
  //   const userLogin = async () => {
  //     console.log(user);
  //     try {
  //       if (user.name) {
  //       } else {
  //         dispatch(AuthActions.authLogout());
  //         //RedirectLogin();
  //       }
  //     } catch (error) {
  //       dispatch(AuthActions.authLogout());
  //       //RedirectLogin();
  //     }
  //   };
  //   userLogin();
  // }, [user]);

  return (
    <div
      style={{
        minWidth: 120,
        height: 35,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 2,
        justifyContent: 'center',
      }}
    >
      <img
        src={avatar}
        style={{
          width: 45,
          height: 45,
          marginTop: 20,
          marginLeft: -5,
          zIndex: 2,
        }}
      />
      <div
        onClick={() => {
          setMouseOuverNameClick(!mouseouvernameclick);
          setMouseOuverName(false);
        }}
        onMouseOver={() => {
          setMouseOuverName(true);
        }}
        onMouseLeave={() => {
          setMouseOuverName(false);
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor:
            mouseouvername || mouseouvernameclick ? '#327118' : '',
          marginLeft: -22,
          padding: 6,
          zIndex: 1,
          marginTop: 4,
          paddingLeft: 23,
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
        }}
      >
        <span style={{ color: '#FFFFFF', fontSize: 12 }}>
          {/* {user && firstName(user.name)} */}
          Usuário
        </span>

        <img
          src={seta_branca}
          style={{
            width: 7,
            height: 4,
            marginTop: 1,
            marginLeft: 10,
          }}
        />
      </div>
      <div
        onMouseLeave={() => {
          setMouseOuverNameClick(false);
        }}
        style={{
          position: 'absolute',
          top: 70,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 3px 18px #0000003B',
          borderRadius: 8,
          visibility: mouseouvernameclick ? 'visible' : 'hidden',
        }}
      >
        <Dropdown visible={mouseouvernameclick} />
      </div>
    </div>
  );
};

export default Notifications;
