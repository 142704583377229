import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  background-color: #f5f5f5;
  padding: 15px 15px 0 15px;
  border: solid #d0d0d0 1px;
  border-radius: 4px;
`;
