import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DropdownArrowRight } from 'src/components/Icons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/ducks/index';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

interface IDropdownSubmenus {
  show?: boolean;
  label: string;
  path: string;
}

interface IDropdownProps {
  icon?: JSX.Element;
  active?: boolean;
  path?: string;
  visible?: boolean;
  submenus?: {
    label: string;
    paths: IDropdownSubmenus[];
  };
}

const Dropdown = ({
  visible = false,
  submenus,
  path,
}: IDropdownProps): JSX.Element => {
  const history = useHistory();
  const handleOnClickButton = () => {
    if (path) history.push(path);
  };
  // const { companieTotal, user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const forgotRequest = async (companyEdit) => {
    toast.success('Unidade alterada com sucesso!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const data = {
      uuid_company: companyEdit.uuid,
    };

    const response = await api.user.post('/api/v1/companies/current', data);
    const companySelected = companyEdit;

    // dispatch(
    //   AuthActions.authSuccess(
    //     response.data.token,
    //     companySelected,
    //     companieTotal,
    //     user
    //   )
    // );

    history.push('/dashboard');
  };

  const RedirectLogin = () => {
    history.push('/');
  };

  // useEffect(() => {
  //   const companyTotal = async () => {
  //     console.log(companieTotal);
  //     try {
  //       if (companieTotal.length >= 1) {
  //       } else {
  //         dispatch(AuthActions.authLogout());
  //         //RedirectLogin();
  //       }
  //     } catch (error) {
  //       dispatch(AuthActions.authLogout());
  //       //RedirectLogin();
  //     }
  //   };
  //   companyTotal();
  // }, [companieTotal]);

  return (
    <Container visible={visible} className={submenus && 'dropdown'}>
      {/* {console.log(companieTotal)} */}

      <DropContainer className="drop">
        <DropContent>
          <DropTitle>ALTERNAR ENTRE AS UNIDADES</DropTitle>
          <DropLinkContainer>
            <></>
            {/* {companieTotal != '' &&
              companieTotal != undefined &&
              companieTotal.map(
                (item, index) =>
                  item.show === undefined && (
                    <DropLink
                      onClick={() => {
                        forgotRequest(item);
                      }}
                      key={index}
                    >
                      {index < 9 && '0'}
                      {index + 1}.{item.company_name}
                    </DropLink>
                  )
              )} */}
          </DropLinkContainer>
        </DropContent>
      </DropContainer>
    </Container>
  );
};

export default Dropdown;
