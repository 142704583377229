import { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container } from './styles';

interface IInputDb {
  name: string;
}

const InputDb = ({ name }: IInputDb): JSX.Element => {
  const inputDbRef = useRef({ value: [] });

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    if (inputDbRef.current) {
      registerField({
        name: fieldName,
        ref: inputDbRef,
        getValue: (ref) => {
          return ref.current.value;
        },
        setValue: (ref, value) => {
          ref.current.value = value;
        },
        clearValue: (ref) => {
          ref.current.value = '';
        },
      });
    }
  }, [fieldName, registerField]);

  return <Container />;
};

export default InputDb;
