import {
  ComponentType,
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ThemeContext } from 'styled-components';

import { useExpanded, usePagination, useTable } from 'react-table';
import Button from 'src/components/Button';
import PulseLoader from 'src/components/PulseLoader';
import SearchInput from 'src/components/SearchInput';
import Switch from 'src/components/Switch';

import { Plus } from '../Icons';
import ColumnsControl from './ColumnsControl';
import PageSize from './PageSize';
import {
  Container,
  Content,
  Controls,
  Info,
  Pagination,
  PaginationButton,
  PaginationControls,
  TableHtml,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from './styles';

export interface IFetchDataParams {
  pageIndex: number;
  pageSize: number;
  showInactive?: boolean;
}

interface ITableProps {
  allowControls?: boolean;
  columns: any;
  data: { [key: string]: any }[];
  loading: boolean;
  hidePagination?: boolean;
  newButtonLabel?: string;
  onChangePage?: ({
    pageIndex,
    pageSize,
    showInactive,
  }: IFetchDataParams) => void;
  onClickNew?: () => void | null;
  totalData: number;
  renderRowSubComponent?: (param: any) => ComponentType | ReactNode;
  hoverComponent?: (param: any) => ComponentType | ReactNode;
}

const Table: FC<ITableProps> = ({
  allowControls,
  columns,
  data,
  loading,
  newButtonLabel,
  onChangePage = () => null,
  onClickNew = () => null,
  totalData,
  hidePagination = false,
  hoverComponent,
  renderRowSubComponent,
}) => {
  const themeContext = useContext(ThemeContext);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    allColumns,
    setPageSize,
    visibleColumns,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: Math.ceil(totalData / 10),
    },
    useExpanded,
    usePagination
  );

  const [showInactive, setShowInactive] = useState();

  useEffect(() => {
    onChangePage({ pageIndex, pageSize, showInactive });
  }, [onChangePage, pageIndex, pageSize, showInactive]);

  const handleOnChangePageSize = ({ value }) => {
    setPageSize(value);

    gotoPage(0);
  };

  const handleOnShowInactive = (event) => {
    const { checked } = event.target;

    setShowInactive(checked);

    onChangePage({ pageIndex, pageSize, showInactive });
  };

  const getPaginationButtons = useCallback(() => {
    const pageCount = Math.ceil(totalData / pageSize);
    const buttons: JSX.Element[] = [];
    const showMaxCountPages = 3;

    if (pageCount > 7) {
      // first page
      if (pageIndex > 1) {
        buttons.push(
          <Button
            key="0"
            label="1"
            onClick={() => gotoPage(0)}
            variant={0 !== pageIndex ? 'line' : 'normal'}
          />
        );
      }

      // first trailing
      if (pageIndex > 2) {
        buttons.push(<Button key="-1" label="..." variant="line" />);
      }

      // three pages
      for (
        let i =
          pageCount == pageIndex + 1
            ? pageIndex - showMaxCountPages + 1
            : pageIndex - 1 < 0
            ? pageIndex
            : pageIndex - 1;
        i < pageCount - 1 &&
        i <
          (pageIndex === 0
            ? showMaxCountPages
            : pageIndex + showMaxCountPages - 1);
        i++
      ) {
        buttons.push(
          <Button
            key={i.toString()}
            label={(i + 1).toString()}
            onClick={() => gotoPage(i)}
            variant={i !== pageIndex ? 'line' : 'normal'}
          />
        );
      }

      // trailing
      if (pageIndex + showMaxCountPages < pageCount) {
        buttons.push(<Button key="-2" label="..." variant="line" />);
      }

      // last page
      buttons.push(
        <Button
          key={pageCount - 1}
          label={pageCount.toString()}
          onClick={() => gotoPage(pageCount - 1)}
          variant={pageCount - 1 !== pageIndex ? 'line' : 'normal'}
        />
      );
    } else {
      for (let i = 0; i < pageCount; i++) {
        buttons.push(
          <Button
            key={i.toString()}
            label={(i + 1).toString()}
            onClick={() => gotoPage(i)}
            variant={i !== pageIndex ? 'line' : 'normal'}
          />
        );
      }
    }

    return buttons;
  }, [totalData, pageSize, pageIndex, gotoPage]);

  const getLineStatus = (row) => {
    switch (true) {
      case row.original.deleted_at:
        return 'deleted';

      case row.original.created_at !== row.original.updated_at:
        return 'edited';

      default:
        return '';
    }
  };

  return (
    <Container>
      {allowControls && (
        <Controls>
          <ColumnsControl>
            {allColumns.map(
              (column) =>
                column?.hide === true || (
                  <Switch
                    key={column.id}
                    text={column.id}
                    {...column.getToggleHiddenProps()}
                    disabled={column?.disabled}
                    variant="tinny"
                    color="secondary"
                  />
                )
            )}
          </ColumnsControl>
          <PageSize onChangePageSize={handleOnChangePageSize} />
          <Switch
            text="INATIVOS"
            variant="tinny"
            onChange={handleOnShowInactive}
          />
          <SearchInput />
          <Button
            label={newButtonLabel || 'Novo'}
            onClick={onClickNew}
            variant="table-inclusion"
            icon={Plus}
          />
        </Controls>
      )}
      <Content>
        <TableHtml {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, hi) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={hi.toString()}>
                {headerGroup.headers.map((column, hgi) => (
                  <Th {...column.getHeaderProps()} key={hgi.toString()}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Fragment {...row.getRowProps()} key={index.toString()}>
                  <Tr
                    className={`${getLineStatus(row)} ${
                      renderRowSubComponent && 'can-expand'
                    }`}
                  >
                    {row.cells.map((cell, ci) => {
                      return (
                        <Td {...cell.getCellProps()} key={ci.toString()}>
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                    {hoverComponent ? hoverComponent(row.original) : null}
                  </Tr>
                  {row.isExpanded && renderRowSubComponent ? (
                    <Tr>
                      <Td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </Td>
                    </Tr>
                  ) : null}
                </Fragment>
              );
            })}
          </Tbody>
        </TableHtml>
        <Pagination className="pagination" loading={loading}>
          {loading ? (
            <PulseLoader size={10} color={themeContext.primary} />
          ) : (
            <>
              <Info>
                <>
                  {!hidePagination && (
                    <>
                      Mostrando de <strong>{pageSize * pageIndex + 1}</strong>{' '}
                      até{' '}
                      <strong>
                        {canNextPage ? pageSize * (pageIndex + 1) : totalData}
                      </strong>{' '}
                      |{' '}
                    </>
                  )}
                  Total de {totalData} itens
                </>
              </Info>
              {!hidePagination && (
                <PaginationControls>
                  <PaginationButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Anterior
                  </PaginationButton>
                  {getPaginationButtons()}
                  <PaginationButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Próximo
                  </PaginationButton>
                </PaginationControls>
              )}
            </>
          )}
        </Pagination>
      </Content>
    </Container>
  );
};

export default Table;
