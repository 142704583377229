import styled from 'styled-components';

import media from 'src/styles/configs/devices';

const TABLE_BORDER_RADIUS = 4;

export const Container = styled.div`
  .root-table-toggle {
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
  }
`;

export const Content = styled.div`
  ${media.max.tablet} {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  border-radius: ${TABLE_BORDER_RADIUS}px;
  border: 1px solid ${({ theme }) => theme.table.borderColor};
`;

export const TableHtml = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  background-color: ${({ theme }) => theme.table.backgroundColorBody};

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
`;

export const Tr = styled.tr`
  position: relative;

  &.edited {
    font-style: italic;
  }

  &.deleted {
    text-decoration: line-through;
  }

  /* HOVER SIZE */
  &.can-expand .root-hover-container {
    left: 34px;
    width: calc(100% - 34px);
  }
`;

export const Td = styled.td`
  font-size: 13px;
  font-weight: 400;
  color: #808080;
  background-color: #ffffff;

  margin: 0;
  padding: 8px;
  border: 0;
`;

export const Th = styled.td`
  font-family: 'Roboto Condensed';
  font-size: 14px;
  font-weight: bold;
  color: #101010;

  text-transform: uppercase;
  margin: 0;
  padding: 10px;
  border-right: 1px solid #b5b5b5;
  background-color: ${({ theme }) => theme.table.backgroundColorHeader};

  :last-child {
    border-right: 0;
    border-radius: 0 ${TABLE_BORDER_RADIUS}px 0 0;
  }

  :first-child {
    border-radius: ${TABLE_BORDER_RADIUS}px 0 0 0;
  }
`;

interface IPaginationProps {
  loading: boolean;
}

export const Pagination = styled.div<IPaginationProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ loading }) => (loading ? 'center' : 'space-between')};

  padding: 8px;
  background-color: ${({ theme }) => theme.table.backgroundColorHeader};
  border-radius: 0 0 ${TABLE_BORDER_RADIUS}px;

  font-family: 'Roboto Condensed';
  font-size: 13px;

  text-transform: uppercase;
  color: #505050;
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;

  .root-button {
    width: 24px;
    height: 24px;

    padding: 2px 8px;
    margin-right: 2px;

    font-size: 12px;
  }

  .root-button.line {
    color: #505050;
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  text-transform: uppercase;

  border: none;
  background: none;

  margin: 0px 8px;

  &:last-of-type {
    margin-right: 24px;
  }

  svg {
    color: ${({ theme }) => theme.gray5};

    font-size: 1.25rem;
    pointer-events: none;

    transition: color 200ms linear;
  }

  &:not(:disabled):hover {
    svg {
      color: ${({ theme }) => theme.primary};
    }
  }

  &:disabled {
    svg {
      color: ${({ theme }) => theme.gray1};
    }
  }
`;

export const Info = styled.div``;

export const Controls = styled.div`
  margin-bottom: 8px;

  display: flex;
  grid-gap: 10px;

  .root-search-input {
    max-width: 200px;
    margin-left: auto;
  }

  .root-button {
    width: 150px;
    padding: 0 10px;
  }

  .root-button svg {
    fill: #ffffff;
    width: 20px;
    height: 20px;
  }

  ${media.max.tablet} {
    grid-template-columns: 1fr;
  }
`;
