import axios from 'axios';

const { REACT_APP_PV_URL } = process.env;

const config = {
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const api = {
  user: axios.create({
    baseURL: REACT_APP_PV_URL,
    ...config,
  }),
  pvdev: axios.create({
    baseURL: REACT_APP_PV_URL,
    ...config,
  }),
  providers: axios.create({
    baseURL: '',
    ...config,
  }),
  residues: axios.create({
    baseURL: '',
    ...config,
  }),
  lighthouse: axios.create({
    baseURL: '',
    ...config,
  }),
};

export default api;
