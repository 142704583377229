import styled from 'styled-components';

export const Container = styled.div`
  padding: 14px 16px 6px 20px;

  background-position: center;
  background-size: cover;
  background-image: url('/assets/img/header_pattern.png');
  min-height: 80px;
  max-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: sticky;
  top: 0;
  z-index: 99;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.55fr;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const RightContent = styled.div`
  display: flex;
  // align-items: center;
  gap: 16px;
`;
