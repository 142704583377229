import Grid from 'src/components/Grid';
import IndicatorCard from 'src/components/IndicatorCard';
import Title from 'src/components/Title';

import { Container } from './styles';
const Indicators = (): JSX.Element => {
  return (
    <Container>
      <Title variant="title">Indicadores</Title>
      <Grid columns="1fr 1fr 1fr 1fr">
        <IndicatorCard start={90} value={407} text="Resíduos cadastrados" />
        <IndicatorCard
          start={90}
          value={1032}
          text="Transportadores cadastrados"
        />
        <IndicatorCard start={90} value={531} text="Destinos cadastrados" />
        <IndicatorCard start={90} value={882} text="Equipamentos cadastrados" />
      </Grid>
    </Container>
  );
};

export default Indicators;
