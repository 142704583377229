import { RefObject, useState } from 'react';

import { FormHandles } from '@unform/core';
import Button from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Radio from 'src/components/Radio';
import Select from 'src/components/Select';
import Textarea from 'src/components/Textarea';

import Residues from './Residues';
import { Card, Container, Controls, Label } from './styles';

interface IDataState {
  document_type?: string;
  mtr_option?: boolean;
  vehicle_type?: boolean;
  residues?: boolean;
  generator?: boolean;
  vehicle_plate?: boolean;
  mtr_responsible?: boolean;
}

interface IFormFieldsProps {
  formRef: RefObject<FormHandles>;
}

const FormFields = ({ formRef }: IFormFieldsProps): JSX.Element => {
  const [data, setData] = useState<IDataState>({});

  const handleOnChangeField = (event) =>
    setData((currentData) => {
      const { name, value } = event.target;

      if (currentData[name]) {
        return currentData;
      }

      return {
        ...currentData,
        [name]: value,
      };
    });

  const handleOnChangeVehicleType = () =>
    setData((currentData) => ({
      ...currentData,
      vehicle_type: true,
    }));

  const handleOnIncludedResidue = () =>
    setData((currentData) => ({
      ...currentData,
      residues: true,
    }));

  return (
    <Container>
      <Label highlight={!data.mtr_option}>
        1. ESCOLHA O SEU TIPO DE DOCUMENTO DE SAÍDA
      </Label>
      <Card>
        <FormGroup columns="1fr">
          <Radio
            label="Test"
            name="document_type"
            onChange={handleOnChangeField}
            options={[
              { label: 'Nota fiscal', value: 'nfe' },
              { label: 'Romaneio', value: 'romaneio' },
            ]}
          />
          {data.document_type && (
            <>
              <FormGroup columns="1fr 1fr 2fr">
                <Input label="Número Nota Fiscal" required name="nfe_number" />
                <DatePicker
                  label="Data de Emissão"
                  required
                  name="emission_date"
                />
                {data.document_type === 'nfe' && (
                  <InputFile
                    label="XML da Nota Fiscal"
                    required
                    name="nfe_xml"
                  />
                )}
              </FormGroup>
              <Radio
                label="Opções de Manifesto de Transporte de Resíduos (MTR)"
                name="mtr_option"
                onChange={handleOnChangeField}
                options={[
                  { label: 'Sem MTR', value: 'none' },
                  {
                    label: 'MTR Somente na PlataformaVerde™',
                    value: 'only_pv',
                  },
                  {
                    label: 'MTR na PlataformaVerde™ e em Sistemas Públicos',
                    value: 'pv_and_others',
                  },
                ]}
              />
            </>
          )}
        </FormGroup>
      </Card>
      {data.mtr_option && (
        <>
          <Label highlight={!data.vehicle_type}>
            2. ESCOLHA QUEM RECEBE E QUEM TRANSPORTA SEUS RESÍDUOS
          </Label>
          <Card>
            <FormGroup columns="1fr 1fr 2fr">
              <Select
                label="Destinatário"
                required
                name="destiny"
                options={[]}
              />
              <Select
                label="Transportador"
                required
                name="transporter"
                options={[]}
              />
              <Select
                label="Tipo de Veículo"
                required
                name="vehicle_type"
                options={[{ label: 'Caminhão', value: 'truck' }]}
                onChange={handleOnChangeVehicleType}
              />
            </FormGroup>
          </Card>
        </>
      )}
      {data.vehicle_type && (
        <>
          <Label highlight={!data.residues}>3. ADICIONE SEUS RESÍDUOS</Label>
          <Card>
            <Residues onIncluded={handleOnIncludedResidue} formRef={formRef} />
          </Card>
        </>
      )}
      {data.residues && (
        <>
          <Label highlight={!data.vehicle_plate}>
            4. INFORMAÇÕES DE TRANSPORTE
          </Label>
          <Card>
            <FormGroup columns="1fr">
              <FormGroup columns="1fr 1fr">
                <Select
                  label="Custo de Transporte"
                  required
                  name="transport_cost"
                  options={[{ label: 'R$ 1,23 por tonelada', value: 123 }]}
                />
                <Select label="Motorista" required name="driver" options={[]} />
              </FormGroup>
              <FormGroup columns="1fr 1fr 2fr">
                <Input
                  label="Placa do Veículo"
                  required
                  name="vehicle_plate"
                  onChange={handleOnChangeField}
                />
                <Input label="Placa da Carreta" name="trailer_plate" />
                <Input label="Número do Lacre" name="seal_number" />
              </FormGroup>
              <Textarea
                label="Observações"
                minLength={0}
                maxLength={500}
                name="observations"
              />
            </FormGroup>
          </Card>
        </>
      )}
      {data.vehicle_plate && (
        <>
          <Label highlight={!data.mtr_responsible}>
            5. INFORMAÇÕES DO GERADOR
          </Label>
          <Card>
            <FormGroup columns="1fr">
              <Radio
                label="Responsável pelo MTR"
                name="mtr_responsible"
                onChange={handleOnChangeField}
                options={[
                  { label: 'Usuário Logado', value: 'logged_user' },
                  { label: 'Usuário especifico', value: 'specific_user' },
                ]}
              />
              <FormGroup columns="1fr 1fr">
                <Select
                  label="Nome do Responsável"
                  required
                  name="responsible_name"
                  options={[
                    { label: 'Usuário 1', value: 1 },
                    { label: 'Usuário 2', value: 2 },
                  ]}
                />
                <InputFile
                  label="Ticket de Balança do Gerador"
                  required
                  name="generator_ticket"
                />
              </FormGroup>
            </FormGroup>
          </Card>
        </>
      )}
      <Controls>
        <Button
          label="Confirmar e Emitir"
          disabled={!data.mtr_responsible}
          type="submit"
        />
      </Controls>
    </Container>
  );
};

export default FormFields;
