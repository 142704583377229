import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ReCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  > img {
    width: 23%;
    height: auto;
    margin: 0 auto;
  }
`;

export const ImageLock = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const ImageUser = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const InputArcos = styled.div`
  width: 100%;
  max-width: 340px;
  height: auto;
  background: #ffffff;
  box-shadow: 8px 16px 32px rgb(0 0 0 / 10%);
  border-radius: 16px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 70px !important;
  margin-bottom: 70px !important;
`;

export const LeftBox = styled.div`
  width: 50%;
  height: 100vh;
  position: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/img/bg-arcos-login.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 5px 30px;
  position: relative;
`;

export const RightBox = styled.div`
  width: 50%;
  overflow-y: auto !important;
`;

export const FormLogin = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextEntre = styled.p`
  text-align: center;
  font: normal normal normal 16px/20px Roboto;
  font-weight: 900;
  font-size: 1.17em;
  color: #000000;
  margin-bottom: 15px;
  opacity: 1;
  max-width: 300px;
  padding: 0 20px !important;
  padding-top: 30px !important;
`;

export const MenuItemLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.3s;
  text-align: left;
  width: 100%;
`;

export const TextFooter = styled.p`
  text-align: left !important;
  font: normal normal normal 11px Roboto;
  line-height: 20px;
  color: #1c2126;
  text-decoration: underline;
  opacity: 1;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    color: #dd1021;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 40px;

  color: '#FFFFFF';

  &:after {
    content: '';
    display: block;
    width: 55px;
    border-bottom: 10px solid #e44c4e;
  }
`;

export const FooterArcos = styled.div`
  width: 100%;
  height: 28px;
  margin-top: -28px !important;
  z-index: 9999 !important;
  line-height: 28px;
  background-color: #ffb50c;
  text-align: center;
  font-size: 11px;
  color: #2d2d2d; ;
`;

export const SSMA = styled.div`
  width: 30px !important;
  position: absolute;
  right: 100px;
  top: 5px;
`;
export const ButtonArcosLogin = styled.div`
  width: 30px !important;
  position: absolute;
  right: 100px;
  top: 5px;
`;
