import styled from 'styled-components';

export const Container = styled.input`
  width: 100%;
  border: none;
  background: #f2f4f8;
  border-radius: 16px;
  width: 100%;
  padding: 10px 15px;
  font-size: 13px;
  line-height: 18px;
  color: #1c2126;
  margin-bottom: 10px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
`;
