/* eslint-disable react/display-name */
import { RefObject, useEffect, useRef, useState } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import IconButton from 'src/components/IconButton';
import { RemoveCircle } from 'src/components/Icons';
import Input from 'src/components/Input';
import InputDb from 'src/components/InputDb';
import Select from 'src/components/Select';
import Table from 'src/components/Table';
import { v4 as uuidv4 } from 'uuid';

import { Container } from './styles';

interface IResiduesProps {
  formRef: RefObject<FormHandles>;
  onIncluded(): void;
}

interface IFormData {
  [key: string]: any;
}

const RESIDUE_FIELDS = [
  'residue',
  'authorization',
  'quantity',
  'packaging',
  'packaging_quantity',
  'origin',
];

const Residues = ({ formRef, onIncluded }: IResiduesProps): JSX.Element => {
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [data, setData] = useState<IFormData[]>([]);
  const [dispatched, setDispatched] = useState<boolean>(false);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('residues', data);
    }

    if (data.length > 0 && !dispatched) {
      onIncluded();
      setDispatched(true);
    }
  }, [data, dispatched, formRef, onIncluded]);

  const resetForm = () => {
    RESIDUE_FIELDS.map((field) =>
      formRef.current?.setFieldValue(`residue.${field}`, '')
    );
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    if (id)
      setData((currentData) => {
        const copy = [...currentData];

        return copy.filter((item) => item.uuid !== id);
      });
  };

  const getFieldValues = () =>
    RESIDUE_FIELDS.reduce((obj, item) => {
      return {
        ...obj,
        [item]: formRef.current?.getFieldValue(`residue.${item}`),
      };
    }, {});

  const handleOnClickSubmit = () => {
    try {
      addButtonRef.current?.startLoad();

      const residue = getFieldValues();

      setData((currentData) => {
        const copy = [...currentData];
        copy.push({ uuid: uuidv4(), ...residue });

        return copy;
      });

      setTimeout(() => resetForm(), 200);
    } catch (error: any) {
      if (error.message) {
        toast.error(error.message);
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const columns: Column = [
    {
      Header: 'Resíduo',
      accessor: 'residue',
    },
    {
      Header: 'Quantidade',
      accessor: 'quantity',
    },
    {
      Header: 'Unidade',
      accessor: 'measure_unity',
    },
    {
      Header: 'Acondicionamento',
      accessor: 'packaging',
    },
    {
      Header: 'Qtde. Acond.',
      accessor: 'packaging_quantity',
    },
    {
      Header: 'Origem',
      accessor: 'origin',
    },
    {
      Header: '',
      id: 'uuid',
      accessor: ({ uuid }) =>
        uuid ? (
          <IconButton value={uuid} onClick={handleOnClickRemove}>
            <RemoveCircle />
          </IconButton>
        ) : (
          ''
        ),
    },
  ];

  return (
    <Container>
      <InputDb name="residues" />
      <Scope path="residue">
        <FormGroup columns="1fr">
          <FormGroup columns="2fr 1fr 1fr 1fr">
            <Select
              label="Resíduo"
              name="residue"
              options={[{ label: 'Papelão', value: 'card_board' }]}
              required
            />
            <Select
              label="Autorização Ambiental Utilizada"
              name="authorization"
              options={[{ label: 'Amarela', value: 'yellow' }]}
              required
            />
            <Input
              label="Quantidade"
              name="quantity"
              type="number"
              min={0}
              required
            />
            <Input
              label="Unidade de medida"
              name="measure_unity"
              defaultValue="toneladas"
              readOnly
            />
          </FormGroup>
          <FormGroup columns="1.5fr 1fr 1fr 1fr 0.5fr">
            <Select
              label="Acondicionamento"
              name="packaging"
              options={[{ label: 'Caixa', value: 'box' }]}
              required
            />
            <Input
              label="Qtde. Acondicionamento"
              name="packaging_quantity"
              type="number"
              min={0}
            />
            <Input
              label="Valor do Resíduo"
              name="residue_value"
              defaultValue="R$ 5,90 / Ton"
              readOnly
            />
            <Select
              label="Origem"
              name="origin"
              options={[{ label: 'São Paulo', value: 'sp' }]}
              required
            />
            <Button
              ref={addButtonRef}
              variant="table-inclusion"
              onClick={handleOnClickSubmit}
              label="Incluir"
            />
          </FormGroup>
          <Table
            columns={columns}
            data={data}
            loading={false}
            totalData={data.length}
            hidePagination
          />
        </FormGroup>
      </Scope>
    </Container>
  );
};

export default Residues;
