/* eslint-disable react/display-name */
import { useEffect, useRef, useState, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';
import IconButton from 'src/components/IconButton';
import { RemoveCircle } from 'src/components/Icons';

interface INegotiationProps {
  formRef: RefObject<FormHandles>;
}

interface IFormData {
  id?: string;
  negotiation_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const Negotiation = ({ formRef }: INegotiationProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [negotiationOptionsCarrier, setNegotiationOptionsCarrier] = useState(
    []
  );
  const [negotiationOptionsManager, setNegotiationOptionsManager] = useState(
    []
  );
  const [negotiationOptionsRecipient, setNegotiationOptionsRecipient] =
    useState([]);
  const [negotiationOptionsVehicles, setNegotiationOptionsVehicles] = useState(
    [] as any
  );

  useEffect(() => {
    const getOptionsCarrier = async () => {
      try {
        const { data } = await api.providers.get(
          '/api/v1/providers/?per_page=50&type=carrier'
        );

        const options = data.data.map(({ uuid_provider, alias, company }) => ({
          label: alias || company.trading_name || company.company_name,
          value: uuid_provider,
        }));

        setNegotiationOptionsCarrier(options);
      } catch (error) {}
    };

    const getOptionsManager = async () => {
      try {
        const { data } = await api.providers.get(
          '/api/v1/providers/?per_page=50&type=manager'
        );

        const options = data.data.map(({ uuid_provider, alias, company }) => ({
          label: alias || company.trading_name || company.company_name,
          value: uuid_provider,
        }));

        setNegotiationOptionsManager(options);
      } catch (error) {}
    };

    const getOptionsRecipient = async () => {
      try {
        const { data } = await api.providers.get(
          '/api/v1/providers/?per_page=50&type=recipient'
        );

        const options = data.data.map(({ uuid_provider, alias, company }) => ({
          label: alias || company.trading_name || company.company_name,
          value: uuid_provider,
        }));

        setNegotiationOptionsRecipient(options);
      } catch (error) {}
    };

    const getOptionsVehicles = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/vehicles/types'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setNegotiationOptionsVehicles(options);
      } catch (error) {}
    };

    getOptionsVehicles();
    getOptionsRecipient();
    getOptionsManager();
    getOptionsCarrier();
  }, []);

  const resetForm = () => {
    const formData = formRef.current?.getData();

    formRef.current?.setData({});
    formRef.current?.setErrors({});
    formRef.current?.setFieldValue('negotiations', formData?.negotiations);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = {
    size: '1fr 1fr 1.2fr 1.2fr 1fr 1fr 1fr 1fr 0.5fr',
    fields: [
      { key: 'number', label: 'Número' },
      {
        key: 'manager',
        label: 'Gerenciador',
        format: (managerId) => {
          const managers = negotiationOptionsManager?.find(
            (managers: SelectOptionsInterface) => managers.value === managerId
          ) || {
            label: 'Nenhum',
          };

          return managers.label;
        },
      },
      { key: 'type', label: 'Tipo Negociação' },
      {
        key: 'period',
        label: 'Período Negociação',
        // format: (periodId) => {
        //   const periods = negotiationOptionsManager?.find(
        //     (periods: SelectOptionsInterface) => periods.value === periodId
        //   ) || {
        //     label: 'Nenhum',
        //   };

        //   return periods.label;
        // },
      },
      { key: 'code', label: 'Repetir Período' },
      { key: 'code', label: 'Destino' },
      { key: 'code', label: 'Transportador' },
      { key: 'code', label: 'Tipo de Lançamento' },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton value={id} onClick={handleOnClickRemove}>
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="negotiations"
      title="Negociações com prestadores de serviços"
    >
      <FormGroup columns="2fr 2fr">
        <Select
          label="Resíduo Sob Gestão de Gerenciador"
          name="manager"
          noOptionsMessage="Não há gerenciadores cadastrados"
          options={negotiationOptionsManager}
        />
        <Select
          label="Tipo de Negociação"
          name="type"
          options={[
            {
              label: 'Preço Individual',
              value: 'price_individual',
            },
            {
              label: 'Preço Fechado (Destinação + Transporte)',
              value: 'price_closed',
            },
          ]}
        />
      </FormGroup>

      <FormGroup columns="2fr 2fr 2fr 2fr">
        <Select
          label="Período Negociação"
          name="period"
          options={[
            {
              label: 'Contínuo',
              value: 'continuous',
            },
            {
              label: 'Período Definido',
              value: 'defined',
            },
          ]}
        />
        <DatePicker label="Início" name="start" />
        <DatePicker label="Término" name="end" />
        <Select
          label="Repetir Período"
          name="repeat_period"
          options={[
            {
              label: 'Não Repetir',
              value: 'no_repeating',
            },
            {
              label: 'Anualmente',
              value: 'annually',
            },
          ]}
        />
      </FormGroup>

      <FormGroup columns="2fr 2fr 1fr 1fr">
        <Select
          label="Destino"
          name="recipient"
          options={negotiationOptionsRecipient}
        />
        <Select
          label="Tipo de Lançamento"
          name="recipient_amount_type"
          options={[
            {
              label: 'Despesa',
              value: 'expense',
            },
            {
              label: 'Receita',
              value: 'income',
            },
          ]}
        />

        <Input label="Preço Destinação" name="recipient_cost" mask="currency" />

        <Select
          label="Destinação Negociada"
          name="recipient_amount_per"
          options={[
            {
              label: 'Por Quilo',
              value: 'kg',
            },
            {
              label: 'Por Tonelada',
              value: 'ton',
            },
            {
              label: 'Por Litro',
              value: 'liters',
            },
            {
              label: 'Por M³',
              value: 'liters',
            },
            {
              label: 'Por Viagem',
              value: 'travel',
            },
          ]}
        />
      </FormGroup>

      <FormGroup columns="2fr 2fr 1fr 1fr">
        <Select
          label="Transportador"
          name="carrier"
          options={negotiationOptionsCarrier}
        />

        <Select
          label="Tipo de Lançamento"
          name="carrier_amount_type"
          options={[
            {
              label: 'Despesa',
              value: 'expense',
            },
            {
              label: 'Receita',
              value: 'income',
            },
          ]}
        />

        <Input label="Preço Transporte" name="carrier_cost" mask="currency" />

        <Select
          label="Transporte Negociada"
          name="carrier_amount_per"
          options={[
            {
              label: 'Por Quilo',
              value: 'kg',
            },
            {
              label: 'Por Tonelada',
              value: 'ton',
            },
            {
              label: 'Por Litro',
              value: 'liters',
            },
            {
              label: 'Por M³',
              value: 'liters',
            },
            {
              label: 'Por Viagem',
              value: 'travel',
            },
          ]}
        />
      </FormGroup>

      <FormGroup columns="2fr 2fr 2fr 1fr">
        <Select
          label="Tipo de Veículo"
          name="vehicle"
          options={negotiationOptionsVehicles}
        />
        <InputFile
          label="Ficha de Emergência"
          name="emergency_file"
          accept="*"
        />
        <InputFile
          label="Declaração de Transporte do Resíduo"
          name="declaration_file"
          accept="*"
        />
        <Button ref={addButtonRef} type="submit" label="Incluir" />
      </FormGroup>
    </FormTableInsert>
  );
};

export default Negotiation;
