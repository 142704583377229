import { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';
import Switch, { ISwitchProps } from 'src/components/Switch';

import { Container, Content, Item } from './styles';

interface ISwitchFormProps extends Omit<ISwitchProps, 'text'> {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  required?: boolean | undefined;
}

const SwitchForm = ({
  label,
  name,
  options,
  required,
  ...rest
}: ISwitchFormProps): JSX.Element => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {
    clearError,
    error,
    fieldName,
    registerField,
    defaultValue = [],
  } = useField(name);

  const handleOnChange = () => clearError();

  useEffect(() => {
    if (inputRefs.current)
      registerField({
        name: fieldName,
        ref: inputRefs.current,
        getValue: (refs: HTMLInputElement[]) => {
          const values = refs
            .filter((ref) => ref.checked)
            .map((ref) => ref.value);

          if (refs.length === 1) {
            return values[0];
          }

          return values;
        },
        clearValue: (refs: HTMLInputElement[]) => {
          refs.forEach((ref) => {
            ref.checked = false;
          });
        },
        setValue: (refs: HTMLInputElement[], value: string | string[]) => {
          const values = Array.isArray(value) ? value : [value];
          refs.forEach((ref) => {
            if (values.includes(ref.value)) {
              ref.checked = true;
            }
          });
        },
      });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      <Label>
        {label}
        {required === true ? '*' : ''}
      </Label>
      <Content className="root-switch-form">
        {options.map((option, index) => (
          <Item key={index.toString()}>
            <Switch
              text={option.label}
              value={option.value}
              onChange={handleOnChange}
              inputRef={(ref) => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              {...rest}
            />
          </Item>
        ))}
      </Content>
      <InputErrorMessage
        fieldLabel={label}
        fieldName={fieldName}
        message={error}
      />
    </Container>
  );
};

export default SwitchForm;
