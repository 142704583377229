import React, { useState, useEffect } from 'react'; // react plugin used to create charts

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DropdownArrowRight } from 'src/components/Icons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/ducks/index';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';

import {
  SairPlataforma,
  GerenciarUsuarios,
  GerenciarPermissoes,
  EditarPerfilEmpresa,
  EditarMeuPerfil,
} from 'src/components/Icons';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
  DropTitleOffice,
} from './styles';

interface IDropdownSubmenus {
  show?: boolean;
  label: string;
  path: string;
}

interface IDropdownProps {
  icon?: JSX.Element;
  active?: boolean;
  path?: string;
  visible?: boolean;
  submenus?: {
    label: string;
    paths: IDropdownSubmenus[];
  };
}

const Dropdown = ({
  visible = false,
  submenus,
  path,
}: IDropdownProps): JSX.Element => {
  const history = useHistory();
  // const { companieTotal, user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const truncate = (text) => {
    if (text.length > 12) {
      return text.substring(0, 22) + '...';
    }
    return text;
  };

  const companyPush = () => {
    history.push('/perfil-empresa');
  };

  const logoutPush = () => {
    history.push('/sair');
  };

  const RedirectLogin = () => {
    history.push('/');
  };

  // useEffect(() => {
  //   const userLogin = async () => {
  //     console.log(user);
  //     try {
  //       if (user.name) {
  //       } else {
  //         dispatch(AuthActions.authLogout());
  //         //RedirectLogin();
  //       }
  //     } catch (error) {
  //       dispatch(AuthActions.authLogout());
  //       //RedirectLogin();
  //     }
  //   };
  //   userLogin();
  // }, [user]);

  return (
    <Container visible={visible} className={submenus && 'dropdown'}>
      {/* {console.log(companieTotal)} */}

      <DropContainer className="drop">
        <DropContent>
          <DropTitle>
            <></>
          </DropTitle>
          <DropTitleOffice>(administrador)</DropTitleOffice>
          <DropLinkContainer>
            <DropLink>
              <EditarMeuPerfil /> Editar Meu Perfil
            </DropLink>
            <DropLink onClick={companyPush}>
              <EditarPerfilEmpresa /> Editar Perfil da Empresa
            </DropLink>
            <DropLink>
              <GerenciarUsuarios /> Gerenciar Usuários
            </DropLink>
            <DropLink>
              <GerenciarPermissoes /> Gerenciar Permissões
            </DropLink>
            <DropLink onClick={logoutPush}>
              <SairPlataforma />
              Sair da Plataforma
            </DropLink>
          </DropLinkContainer>
        </DropContent>
      </DropContainer>
    </Container>
  );
};

export default Dropdown;
