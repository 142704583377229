import styled from 'styled-components';

export interface IContainerProps {
  columns: string;
  rows?: string;
  gap?: number;
  className?: string;
}

export const Container = styled.div<IContainerProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-template-rows: ${({ rows }) => rows};
  grid-gap: ${({ gap }) => gap}px;

  > button {
    margin-top: 20px;
  }
`;
