import ChooseCompany from './ChooseCompany';
import ChooseCompanyArcosDourados from './ChooseCompanyArcosDourados';
import ChooseCompanyCarrefour from './ChooseCompanyCarrefour';
import ChooseModule from './ChooseModule';
import ChooseModuleArcosDourados from './ChooseModuleArcosDourados';
import ChooseModuleCarrefour from './ChooseModuleCarrefour';
import ErroInatividade from './ErroInatividade';
import ErroJaLogado from './ErroJaLogado';
import ForgotMyPassword from './ForgotMyPassword';
import ForgotMyPasswordInfo from './ForgotMyPasswordInfo';
import Login from './Login';
import LoginGreen from './LoginGreen';
import LoginArcosDourados from './LoginArcosDourados';
import LoginCarrefour from './LoginCarrefour';
import LoginCempre from './LoginCempre';
import LoginSSO from './LoginSSO';
import PrimeiroAcesso from './PrimeiroAcesso';
import RecoverPassword from './RecoverPassword';
import RecriarSenha from './RecriarSenha';
import RecriarSenhaForte from './RecriarSenhaForte';
import RedefinirSenhaCempre from './RedefinirSenhaCempre';
import SucessoTrocaSenha from './SucessoTrocaSenha';

export default {
  ChooseCompany,
  ChooseModule,
  ErroInatividade,
  ErroJaLogado,
  RecriarSenha,
  RecriarSenhaForte,
  SucessoTrocaSenha,
  PrimeiroAcesso,
  LoginGreen,
  ForgotMyPassword,
  ForgotMyPasswordInfo,
  Login,
  LoginArcosDourados,
  LoginCarrefour,
  LoginCempre,
  RedefinirSenhaCempre,
  ChooseCompanyCarrefour,
  ChooseCompanyArcosDourados,
  ChooseModuleCarrefour,
  ChooseModuleArcosDourados,
  LoginSSO,
  RecoverPassword,
};
