import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 690px;
`;

export const Content = styled.div``;

export const Head = styled.div`
  padding: 13px 10px;
  border-radius: 8px 8px 0 0;

  font-size: 18px;
  text-transform: uppercase;

  color: #d92727;
  background-color: #efefef;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  padding: 10px;

  background-color: #ffffff;
  color: #606060;

  .root-label {
    font-weight: 600;
  }
`;

export const Footer = styled.div`
  padding: 10px;
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: center;
  gap: 30px;

  background-color: #efefef;
`;

export const Instruction = styled.div`
  font-size: 15px;
  font-weight: 400;

  margin: 16px 0;
`;

export const Info = styled.div``;

export const InfoRow = styled.div`
  font-size: 15px;
  display: grid;
  grid-template-columns: 1fr 4fr;

  margin-bottom: 4px;
`;

export const InfoLabel = styled.span`
  font-weight: 600;
  color: #000000;
`;

export const InfoValue = styled.span`
  margin-left: 5px;
  color: #909090;
`;
