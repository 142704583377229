import { Container, FooterBody } from './styles';

import LogoFooter from './LogoFooter';
import MidiaFooter from './MidiaFooter';
import OmbudsmanFooter from './OmbudsmanFooter';
import KilosFooter from './KilosFooter';
import CopyFooter from './CopyFooter';

//Footer

const Footer = (): JSX.Element => {
  return (
    <Container>
      <FooterBody>
        <LogoFooter />
        <MidiaFooter />
        <OmbudsmanFooter />
        <KilosFooter />
      </FooterBody>
      <CopyFooter />
    </Container>
  );
};

export default Footer;
