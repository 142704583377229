import { ComponentType, RefObject, ForwardRefRenderFunction } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import Title from 'src/components/Title';

import { Container, Content } from './styles';

interface IFormTableInsertProps {
  formRef: RefObject<FormHandles>;
  formTableRef: RefObject<IFormTableHandlers>;
  handleOnSubmit: any;
  formTableColumns: any;
  name: string;
  title?: string;
  optional?: boolean;
  icon?: ComponentType;
}

interface IFormData {
  [key: string]: any;
}

export interface IFormTableInsertHandlers {
  add(newData: IFormData): void;
  remove(id: string): void;
  setData(data: IFormData[]): void;
}

const FormTableInsert: ForwardRefRenderFunction<
  IFormTableInsertHandlers,
  IFormTableInsertProps
> = (
  {
    children,
    formRef,
    formTableRef,
    handleOnSubmit,
    formTableColumns,
    name,
    title,
    optional,
    icon: Icon,
  },
  ref
) => {
  return (
    <Container>
      <FormGroup columns="1fr" gap={10}>
        {title && (
          <Title variant="subtitle" icon={Icon}>
            {title}
            {optional && <Title variant="info">(opcional)</Title>}
          </Title>
        )}
        <Content>
          <Form ref={formRef} onSubmit={handleOnSubmit}>
            <FormGroup columns="1fr" gap={24}>
              {children}
              <FormTable
                ref={formTableRef}
                columns={formTableColumns.fields}
                columnsSize={formTableColumns.size}
                name={name}
                showTotal
              />
            </FormGroup>
          </Form>
        </Content>
      </FormGroup>
    </Container>
  );
};

export default FormTableInsert;
