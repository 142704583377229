import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;

  & svg {
    position: absolute;
    bottom: 8px;
    right: 5px;
  }

  .root-label {
    margin-bottom: 2px;
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.primary};
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;

    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    padding: 0 12px;

    ${({ theme }) => css`
      color: ${theme.input.normal.foreground};
      background-color: ${theme.input.normal.background};
      border: 1px solid ${theme.input.normal.border};

      transition: 200ms linear border-color;

      &.error {
        border-color: ${theme.input.error.border};
      }

      :read-only {
        border-color: ${theme.input.normal.border};
      }

      :not(:read-only):hover,
      :not(:read-only):focus {
        border-color: ${theme.input.normal.focus.border};
      }
    `}

    &:read-only {
      cursor: not-allowed;
    }
  }
`;
