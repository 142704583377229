import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

interface IContainerProps {
  visible?: boolean;
}

export const Container = styled.div<IContainerProps>`
  cursor: pointer;
  position: relative;

  justify-self: center;
  border-radius: 8px;
  display: grid;
  // grid-template-columns: 1fr 0.01fr;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  .drop {
    // display: block;
    opacity: 1;
    pointer-events: all;
  }

  &.dropdown {
    // margin-left: 6px;
  }

  & > svg {
    margin-left: 3px;
    align-self: center;
  }
`;

interface IButtonProps {
  active?: boolean;
  hasAction: boolean;
}

export const Button = styled.div<IButtonProps>`
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'not-allowed')};
  display: flex;
  align-self: center;
  justify-content: center;

  padding: 8px;
  height: 40px;
  width: 40px;

  border-radius: 10px;
  border: 1px solid #e5e5e5;

  background-color: ${({ active, theme }) =>
    active ? theme.primary : '#ffffff'};

  ${({ hasAction }) => {
    if (hasAction) {
      return css`
        :hover {
          background-color: #f0f0f0;
        }

        :hover svg {
          fill: ${({ theme }) => theme.primary};
        }
      `;
    }
  }}

  svg {
    display: flex;
    align-self: center;
    justify-content: center;

    max-height: 24px;

    fill: ${({ active, hasAction, theme }) => {
      if (!hasAction) {
        return '#bdbdbd';
      }

      return active ? '#ffffff' : theme.primary;
    }};
  }
`;

export const DropContainer = styled.div`
  display: hidden;
  opacity: 0;
  pointer-events: none;
  // padding-left: 8px;
  border-radius: 8px;
  // position: absolute;
  // left: 100%;
  top: 0;
  z-index: 1;

  transition: opacity 200ms ease-in-out;
`;

export const DropContent = styled.div`
  height: fit-content;
  min-width: 230px;

  padding: 5px 0;

  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: rgb(204 204 204) 0px 0px 30px -3px;
`;

export const DropTitle = styled.div`
  font-weight: 700;
  cursor: default;
  color: ${({ theme }) => theme.primary};
  padding: 8px 18px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-size: 14px;
  text-align: center;
`;

export const DropLinkContainer = styled.div``;

export const DropLink = styled.div`
  display: block;
  padding: 8px 18px;
  font-size: 16px;
  width: 100%;
  height: 35px;
  text-decoration: none;

  font-family: 'Roboto Condensed';

  color: #a0a0a0;
  background-color: #ffffff;

  /* border-radius: 8px; */

  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: #fafafa;
  }
`;
