import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 600px;
`;

export const Content = styled.div``;

export const Head = styled.div`
  padding: 16px;
  border-radius: 8px 8px 0 0;

  background-color: #efefef;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;

  .root-label {
    font-weight: 600;
  }
`;
