import styled from 'styled-components';

import media from 'src/styles/configs/devices';

export const Body = styled.div`
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.background};
`;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;

  ${media.max.desktopLarge} {
    max-width: inherit;
  }
`;
