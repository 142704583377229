import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ErroJaLogado: React.FC = (props) => {
  const history = useHistory();
  useEffect(() => {
    const getLogin = async () => {
      localStorage.setItem('erro', '2');
      history.push('/');
    };
    getLogin();
  }, []);

  return <></>;
};

export default ErroJaLogado;
