import Grid from 'src/components/Grid';
import IndicatorCard from 'src/components/IndicatorCard';
import Title from 'src/components/Title';

import { Container } from './styles';
const Indicators = (): JSX.Element => {
  return (
    <Container>
      <Title variant="title">Indicadores</Title>
      <Grid columns="1fr 1fr 1fr">
        <IndicatorCard value={70} text="Licenças a vencer" />
        <IndicatorCard value={32} text="Certificados emitidos" />
      </Grid>
    </Container>
  );
};

export default Indicators;
