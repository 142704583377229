import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  padding: 20px 40px 100px 40px;
  height: 100vh;
  h1 {
    font-size: 40px;
    font-family: Roboto !important;
    font-weight: bold !important;
    line-height: 40px;
    color: #dd1021;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 10px;
    width: 100%;
  }
  h3 {
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1c2126;
    text-align: center;
    max-width: 465px;
    margin: 0 0 30px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px !important;
  }
`;

export const GridArcos = styled.section`
  display: flex;
  padding-bottom: 30px;
  width: 100%;
  img {
    border: 2px solid #ffcc00;
    width: calc(100% - 4px);
    border-radius: 16px;
  }
`;

export const TitleTextArcosLeft = styled.div`
  width: 50%;
  padding-right: 15px;
  float: left;
  display: flex;
  h5 {
    font-size: 13px;
    font-family: Roboto;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 15px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 10px;
  }
`;

export const GridImageArcosRight = styled.div`
  width: 50%;
  padding-left: 15px;
  float: right;
  img {
    border: 2px solid #ffcc00;
    width: calc(100% - 4px);
    border-radius: 16px;
  }
`;

export const GridImageArcosLeft = styled.div`
  width: 50%;
  padding-right: 15px;
  float: left;
`;

export const TitleTextArcosRight = styled.div`
  width: 50%;
  padding-left: 15px;
  float: right;
  display: flex;
  h5 {
    font-size: 13px;
    font-family: Roboto;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 15px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 10px;
  }
`;

export const GridImagesArcos = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 0px;
  img {
    border: 2px solid #ffcc00;
    border-radius: 16px;
    width: calc(33.3% - 12px);
    margin: 5px;
  }
`;

export const InfoGreenPlatArcos = styled.div`
  display: flex;
  margin-top: 20px;
  padding-bottom: 50px;
  img {
    width: 33%;
    margin-right: 1%;
    float: left;
  }
  section {
    width: 66%;
    float: right;
  }
  h4 {
    font-size: 14px;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 15px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
    color: #2d2d2d;
    margin: 0 0 10px;
  }
  strong {
    font-weight: bold;
  }
`;
