import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: hidden;
`;

export const Content = styled.div`
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 10px;

  background-color: #ffffff;
`;

export const Table = styled.div`
  position: relative;
  overflow-x: auto;
`;

export const Head = styled.div`
  padding: 0;
  margin-right: 20px;
`;

export const Separator = styled.div`
  position: absolute;

  width: calc(100% - 20px);
  height: 1px;
  padding: 0;
  margin: 0;

  background-color: #000000;
`;

export const Label = styled.div`
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  align-self: center;
`;

interface IBodyProps {
  numberColumns: number;
  columnsSize?: string;
}

export const Body = styled.div<IBodyProps>`
  position: relative;

  min-height: 45px;
  max-height: 200px;
  padding: 0 10px 0 0;

  overflow-x: hidden;
  overflow-y: scroll;
`;

interface IRowProps {
  numberColumns: number;
  columnsSize?: string;
}

export const Row = styled.div<IRowProps>`
  display: grid;
  grid-template-columns: ${({ columnsSize, numberColumns }) =>
    columnsSize || `repeat(${numberColumns}, 1fr)`};
  grid-gap: 0 24px;
`;

export const Cell = styled.div`
  font-size: 13px;
  padding: 8px 0 0 0;
  color: #808080;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.empty-table {
    padding-top: 20px;
    text-align: center;
  }

  &:not(.empty-table):last-child {
    position: absolute;

    right: 0;
    padding: 1px 10px 0 0;
    /* min-height: 32px; */
  }
`;

export const Total = styled.div`
  font-family: 'Roboto Condensed';
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0;
`;
