import {
  FC,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';

import {
  Container,
  Count,
  CountContainer,
  Field,
  FieldContainer,
  Head,
} from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
}

const Textarea: FC<TextareaProps> = ({
  id,
  label,
  name,
  minLength,
  maxLength,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const countRef = useRef<HTMLDivElement>(null);

  const { clearError, fieldName, registerField, error } = useField(name);

  useEffect(() => {
    if (textareaRef.current) {
      registerField({
        name: fieldName,
        ref: textareaRef,
        getValue: (ref) => ref.current.value,
        setValue: (ref, value) => {
          ref.current.value = value;
        },
      });
    }
  }, [fieldName, registerField]);

  const handleOnFocus = () => clearError();

  const handleCount = useCallback(
    (event) => {
      if (countRef.current) {
        const { value } = event.target;

        countRef.current.innerText = `${value.length}/${maxLength}`;
      }
    },
    [maxLength]
  );

  useEffect(() => {
    if (countRef.current && textareaRef.current)
      countRef.current.innerText = `${textareaRef.current.value.length}/${maxLength}`;
  }, [maxLength]);

  return (
    <Container className={`root-textarea-container ${error}`}>
      <Head>
        <Label htmlFor={id}>{label}</Label>
        {minLength !== undefined && (
          <CountContainer>
            MÍN. {minLength} / MÁX. {maxLength} CARACTERES -{' '}
            <Count ref={countRef} />
          </CountContainer>
        )}
      </Head>
      <FieldContainer>
        <Field
          ref={textareaRef}
          className={`root-textarea-field ${error && 'error'}`}
          id={id}
          {...props}
          onFocus={handleOnFocus}
          onKeyUp={handleCount}
        />
      </FieldContainer>
      <InputErrorMessage
        fieldLabel={label}
        fieldName={fieldName}
        message={error}
      />
    </Container>
  );
};

export default Textarea;
