import styled from 'styled-components';

const LINE_HEIGHT = 8;
const NUMBER_HEIGHT = 12;

export const Container = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

interface IContentProps {
  steps: number;
}

export const Content = styled.div<IContentProps>`
  display: grid;
  grid-template-columns: ${({ steps }) => `repeat(${steps}, 1fr)`};
`;

interface ILineProps {
  steps: number;
  currentStep: number;
}

export const Line = styled.div<ILineProps>`
  height: ${LINE_HEIGHT}px;
  width: 100%;
  background-color: #eeeeee;

  position: absolute;
  bottom: 0;

  border-radius: ${LINE_HEIGHT}px;

  &:before {
    content: '';
    position: absolute;
    width: ${({ steps }) => 100 / steps}%;
    height: 100%;
    left: ${({ currentStep, steps }) => currentStep * (100 / steps)}%;

    transition: left 200ms ease-in-out;

    background-color: ${({ theme }) => theme.primary};
    border-radius: ${LINE_HEIGHT}px;
  }
`;

export const Step = styled.div`
  text-align: center;
`;

export const StepLabel = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
`;

interface IStepNumberProps {
  complete?: boolean;
}

export const StepNumber = styled.div<IStepNumberProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;
  border-radius: 50%;
  width: ${NUMBER_HEIGHT * 2}px;
  height: ${NUMBER_HEIGHT * 2}px;

  color: ${({ complete }) => (complete ? '#ffffff' : '#333333')};
  background-color: ${({ complete, theme }) =>
    complete ? theme.primary : '#eeeeee'};
  font-size: 0.75rem;

  z-index: 1;
`;

export const StepNumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${LINE_HEIGHT}px;
  width: 100%;

  margin-top: 8px;
  border-radius: ${LINE_HEIGHT}px;
`;
