/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import format from 'date-fns/format';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';
import { string } from 'yup/lib/locale';

interface ILicenseProps {
  formRef?: any;
}

interface IFormData {
  id?: string;
  license_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const LegalRequirements = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [residueOptionsABNT, setResidueOptionsABNT] = useState([] as any);
  const [residueOptionsIbamaCodeList, setResidueOptionsIbamaCodeList] =
    useState([] as any);

  const [residueOptionsConamaCodeList, setResidueOptionsConamaCodeList] =
    useState([] as any);

  const [
    residueOptionsIbamaCodeListSubsidiary,
    setResidueOptionsIbamaCodeListSubsidiary,
  ] = useState([] as any);

  const [residueOptionsNbr_147253, setResidueOptionsNbr_147253] = useState(
    [] as any
  );

  const [
    residueOptionsNbr_147253Subsidiary,
    setResidueOptionsNbr_147253Subsidiary,
  ] = useState([] as any);

  const [residueOptionsRiskSubsidiary, setResidueOptionsRiskSubsidiary] =
    useState([] as any);

  const [residueOptionsRisk, setResidueOptionsRisk] = useState([] as any);

  const [residueOptionsOnu, setResidueOptionsOnu] = useState([] as any);
  const [residueOptionsRiskNumber, setResidueOptionsRiskNumber] = useState(
    [] as any
  );

  const [residueOptionsPackingGroups, setResidueOptionsPackingGroups] =
    useState([] as any);

  useEffect(() => {
    const getABNT = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/ibama/table');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsABNT(options);
      } catch (error) {}
    };

    const getIbamaCodeList = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/ibama/code/lists'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsIbamaCodeList(options);
      } catch (error) {}
    };

    const getConamaCodeList = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/conama/code/lists'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsConamaCodeList(options);
      } catch (error) {}
    };

    const getNbr_147253 = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/nbr_147253/lists'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsNbr_147253(options);
      } catch (error) {}
    };

    const getOnu = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/onu/code/lists'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsOnu(options);
      } catch (error) {}
    };

    const getRisk = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/main/risk/classes'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsRisk(options);
      } catch (error) {}
    };

    const getRiskSubsidiaryNumber = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/risk/numbers'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsRiskNumber(options);
      } catch (error) {}
    };

    const getPackingGroups = async () => {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/packing/groups'
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsPackingGroups(options);
      } catch (error) {}
    };

    getPackingGroups();
    getRiskSubsidiaryNumber();
    getRisk();
    getOnu();
    getNbr_147253();
    getConamaCodeList();
    getABNT();
    getIbamaCodeList();
  }, []);

  const getNbr_147253Subsidiary = async (code) => {
    if (code.value !== '') {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/nbr_147253/sub/lists/' + code.value + ''
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsNbr_147253Subsidiary(options);
      } catch (error) {}
    }
  };

  const getRiskSubsidiary = async (code) => {
    if (code.value !== '') {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/subsidiary/risk/classes/' + code.value + ''
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsRiskSubsidiary(options);
      } catch (error) {}
    }
  };

  const getIbamaCodeListSubsidiary = async (code) => {
    if (code.value !== '') {
      try {
        const { data } = await api.residues.get(
          '/api/v1/residues/ibama/code/sub/lists/' + code.value + ''
        );

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsIbamaCodeListSubsidiary(options);
      } catch (error) {}
    }
  };

  const resetForm = () => {
    const { licenses } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = [
    {
      key: 'uuid_license_type',
      label: 'CATEGORIA',
      format: (typeId) => {
        const license = residueOptionsABNT?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'TECNOLOGIA DE TRATAMENTO' },
    { key: 'code', label: 'CLASSE' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  const formTableColumnsStorage = [
    {
      key: 'uuid_license_type',
      label: 'TIPO DE ARMAZENAGEM',
      format: (typeId) => {
        const license = residueOptionsABNT?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'ESTADO FÍSICO' },
    { key: 'code', label: 'ACONDICIONAMENTO' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">DEFINIÇÕES LEGAIS</Title>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr">
            <Select
              label="Lista do Código ABNT NBR 10004 (opcional)"
              name="uuid_license_type"
              options={residueOptionsABNT}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código IBAMA (opcional)"
              name="uuid_license_type"
              options={residueOptionsIbamaCodeList}
              onChange={getIbamaCodeListSubsidiary}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código IBAMA - Subdescrição (opcional)"
              name="uuid_license_type"
              options={residueOptionsIbamaCodeListSubsidiary}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código CONAMA (opcional)"
              name="uuid_license_type"
              options={residueOptionsConamaCodeList}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista de Produtos Químicos Perigosos da NBR 14725-3"
              name="uuid_license_type"
              options={residueOptionsNbr_147253}
              onChange={getNbr_147253Subsidiary}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Categoria de Produtos Químicos Perigosos da NBR 14725-3"
              name="uuid_license_type"
              options={residueOptionsNbr_147253Subsidiary}
            />
          </FormGroup>

          <Title variant="subtitle">CLASSES DE RISCO CÓDIGO ONU</Title>
          <FormGroup columns="1fr">
            <Select
              label="Lista do Código ONU (opcional)"
              name="uuid_license_type"
              options={residueOptionsOnu}
            />
          </FormGroup>
          <FormGroup columns="2fr 2fr">
            <Select
              label="Classe/Subclasse Risco Principal"
              name="uuid_license_type"
              options={residueOptionsRisk}
              onChange={getRiskSubsidiary}
            />
            <Select
              label="Classe/Subclasse Risco Subsidiário"
              name="uuid_license_type"
              options={residueOptionsRiskSubsidiary}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Select
              label="Número de Risco"
              name="uuid_license_type"
              options={residueOptionsRiskNumber}
            />
            <Select
              label="Grupo de Embalagem"
              name="uuid_license_type"
              options={residueOptionsPackingGroups}
            />
          </FormGroup>
        </FormGroup>
      </Form>
    </FormGroup>
  );
};

export default LegalRequirements;
