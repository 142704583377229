import styled from 'styled-components';
interface IContainerProps {
  error: boolean;
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  padding-top: 8px;
  padding-bottom: 23px;
  border: ${(props) => {
    return props.error ? '1px solid #EF1414' : '1px solid #cccccc';
  }};
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
`;
