import React, { useState } from 'react'; // react plugin used to create charts

import { useHistory } from 'react-router-dom';

import alterar_header from 'src/assets/img/alterar_header.svg';
import alterar_header_green from 'src/assets/img/alterar_header_green.svg';

import Dropdown from './Dropdown';

import {
  Button,
  Container,
  DropContainer,
  DropContent,
  DropLinkContainer,
  DropLink,
  DropTitle,
} from './styles';

const ChangeCompany = (): JSX.Element => {
  const history = useHistory();
  const [mouseouverchangecompany, setMouseOuverChangeCompany] =
    useState<boolean>(false);
  const [mouseouverchangecompanyclick, setMouseOuverChangeCompanyClick] =
    useState<boolean>(false);
  return (
    <Container
      onClick={() => {
        setMouseOuverChangeCompanyClick(!mouseouverchangecompanyclick);
        setMouseOuverChangeCompany(false);
      }}
      onMouseOver={() => {
        setMouseOuverChangeCompany(true);
      }}
      onMouseLeave={() => {
        setMouseOuverChangeCompany(false);
      }}
      changecompany={mouseouverchangecompany}
      changecompanyclick={mouseouverchangecompanyclick}
    >
      {!mouseouverchangecompanyclick && (
        <img src={alterar_header} style={{ width: 25, height: 22 }} />
      )}

      {mouseouverchangecompanyclick && (
        <img src={alterar_header_green} style={{ width: 25, height: 22 }} />
      )}

      <div
        style={{
          position: 'absolute',
          height: '20px',
          top: 70,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 3px 18px #0000003B',
          borderRadius: 4,
          visibility:
            mouseouverchangecompany && !mouseouverchangecompanyclick
              ? 'visible'
              : 'hidden',
        }}
      >
        <div
          onMouseLeave={() => {
            setMouseOuverChangeCompany(false);
          }}
          style={{
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            width: 60,
            height: 20,
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: '#247C49',
              fontWeight: 'bold',
              fontSize: 10,
            }}
          >
            ALTERAR
          </span>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          top: 70,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 3px 18px #0000003B',
          borderRadius: 8,
          visibility: mouseouverchangecompanyclick ? 'visible' : 'hidden',
        }}
      >
        <Dropdown visible={mouseouverchangecompanyclick} />
      </div>
    </Container>
  );
};

export default ChangeCompany;
