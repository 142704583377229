import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const Content = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(224 224 224) 0px 4px 10px 4px;
  padding: 24px;
  margin-top: 16px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const Head = styled.div``;

export const HeadContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;
`;
