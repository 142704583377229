export default {
  normal: {
    foreground: '#1c1c1e',
    border: '#d0d0d0',
    background: '#ffffff',
    focus: {
      border: '#e0e0e0',
    },
  },
  error: {
    foreground: '#1c1c1e',
    border: '#ff554c',
    background: '#fafafa',
    focus: {
      border: '#e0e0e0',
    },
  },
};
