import { FC, useRef } from 'react';

import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Button, { IButtonHandlers } from 'src/components/Button';
import Input from 'src/components/Input';
import Title from 'src/components/Title';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import recoverPasswordSchema from 'src/validators/recoverPassword.schema';

import {
  BackToLoginContainer,
  BackToLoginLink,
  Container,
  Content,
} from './styles';

interface FormData {
  password: string;
}

const RecoverPassword: FC = () => {
  const buttonRef = useRef<IButtonHandlers>(null);
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const handleOnSubmit: SubmitHandler<FormData> = async (formData) => {
    buttonRef.current?.startLoad();
    formRef.current?.setErrors({});

    try {
      const data: FormData = await recoverPasswordSchema.validate(
        formData,
        defaultOptions
      );

      history.push('/recuperar-senha');
    } catch (errors) {
      if (errors?.inner) {
        formRef.current?.setErrors(formatErrors(errors.inner));
      }

      buttonRef.current?.finishLoad();
    }
  };

  return (
    <Container>
      <Content>
        <Title variant="title">Recuperar senha</Title>
        <Form ref={formRef} onSubmit={handleOnSubmit}>
          <Input id="password" label="Senha" name="password" type="password" />
          <Button ref={buttonRef} label="Enviar" type="submit" />
        </Form>
        <BackToLoginContainer>
          <BackToLoginLink to="/">Voltar para o login</BackToLoginLink>
        </BackToLoginContainer>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
