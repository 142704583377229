import React, { useState } from 'react';

import { IcoClip, IcoDelete } from '../Icons';
import { Container } from './styles';

interface FileInputProps {
  label: string;
  accept: string;
  name: string;
}

interface Files {
  name: string;
  path: string;
}

const FileInput = ({ label, accept, name }: FileInputProps): JSX.Element => {
  const [files, setFiles] = useState<Files[]>([]);

  function handleInputChange(e) {
    const newFile = {
      name: e.target.value.split('\\').at(-1),
      path: e.target.value,
    };
    setFiles([...files, newFile]);
  }

  function handleDelete(fileName) {
    const newFiles = files.filter((file) => file.name != fileName);
    setFiles(newFiles);
  }

  return (
    <Container>
      <div className="top-wrapper">
        <label htmlFor="">{label}</label>
        <input
          type="file"
          accept={accept}
          id={`inputFile${name}`}
          onChange={handleInputChange}
        />
        <div className="btn-wrapper">
          <input
            type="button"
            value="Anexar"
            onClick={() => document.getElementById(`inputFile${name}`)?.click()}
          />
          <IcoClip />
        </div>
      </div>
      <div className="bottom-wrapper">
        <table>
          <tbody>
            {files.map((file, i) => {
              return (
                <tr key={i}>
                  <td className="selectedFileName">{file.name}</td>
                  <td className="selectedFilePath">{file.path}</td>
                  <td className="excludeBtn">
                    <IcoDelete onClick={() => handleDelete(file.name)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default FileInput;
