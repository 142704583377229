import { colors } from './_defaultTheme';

export default {
  normal: {
    foreground: '#1c1c1e',
    border: '#bdbdbd',
    background: '#ffffff',
    icon: 'transparent',
    focus: {
      border: '#e0e0e0',
    },
  },
  checked: {
    foreground: '#1c1c1e',
    border: colors.primary,
    background: colors.primary,
    icon: '#ffffff',
    focus: {
      border: colors.primary,
      background: colors.primary,
    },
  },
};
