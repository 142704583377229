import { Farol } from 'src/components/Icons';
import Title from 'src/components/Title';

import Privates from './Private';
import Public from './Public';
import { Container } from './styles';

const Lighthouse = (): JSX.Element => {
  return (
    <Container>
      <Title icon={Farol} variant="header">
        Farol de Integrações
      </Title>
      <Public />
      <Privates />
    </Container>
  );
};

export default Lighthouse;
