import React, { useState, useEffect } from 'react';

import getApiErrors from 'src/utils/getApiErrors';
import { ToastContainer, toast } from 'react-toastify';
import logo from 'src/assets/img/logo-pv.png';
import arte from 'src/assets/img/arte-login.png';
import sso from 'src/assets/img/logo-sso.png';
import api from 'src/services/api';
import lock from 'src/assets/img/ico-lock.png';
import user from 'src/assets/img/ico-user.png';
import InputLogin from 'src/components/InputLogin';
import BockInput from 'src/components/BockInput';
import ButtonLogin from 'src/components/ButtonLogin';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import keys from 'src/services/keys';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
  Container,
  Logo,
  Form,
  LeftBox,
  RightBox,
  LogoRight,
  TextEntre,
  TextFooter,
  ImageSSO,
  ImageLock,
  ImageUser,
  TextORSSO,
  HRORSSOs,
  MenuItemLink,
  TextTitle,
  Title,
  ReCaptcha,
} from './styles';

const LoginSSO: React.FC = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loading = false;
    dispatch(AuthActions.authLoading(loading));
  }, []);

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [showRecaptcha] = useState<boolean>(
    keys.REACT_APP_RECAPTCHA_ENABLED === 'true'
  );
  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    '5948086e-e295-43a2-8b3d-189b7723db99'
  );

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
  };

  const backLogin = () => {
    history.push('/');
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  const IsEmail = (email: string) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const forgotRequest = async () => {
    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (!IsEmail(email)) {
        toast.error('E-mail digitado não é valido!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorEmail(true);
      } else {
        if (showRecaptcha && recaptchavalue == '') {
          toast.error('Validação do hCaptcha é obrigatória!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const loading = true;
          dispatch(AuthActions.authLoading(loading));
          const data = {
            login_sso_email_input: email,
            login_sso_servico_input: 'azure',
          };
          try {
            const response = await api.user.post('/api/v1/sso/login', data);

            window.location.href = response.data.redirectUrl;
            // history.push('/esqueci-minha-senha/info', data);
          } catch (error) {
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
            if (
              error.response.data.error == 'Email does not exist.' ||
              error.response.data.error == 'Invalid Credentials.' ||
              error.response.data.error.substr(0, 18) == 'SSO para o Serviço'
            ) {
              funcErrorEmail(true);
              toast.error(
                ' Acesso SSO não configurado. Entre em contato com nossa equipe de suporte ao cliente. ',
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        }
      }
    }
  };

  return (
    <Container>
      <Form>
        <LeftBox>
          <TextEntre>Bem-vindo à Revolução</TextEntre>

          <Logo>
            <img src={logo} alt="Plataforma Verde" />
          </Logo>

          <HRORSSOs>
            <TextORSSO>ACESSE UTILIZANDO</TextORSSO>
          </HRORSSOs>

          <MenuItemLink href="/login-sso">
            <ImageSSO>
              <img src={sso} alt="Plataforma Verde" />
            </ImageSSO>
          </MenuItemLink>

          <BockInput error={errorEmail}>
            <ImageLock>
              <img src={user} alt="Plataforma Verde" />
            </ImageLock>
            <InputLogin
              type="email"
              placeholder="Nome de usuário ou e-mail"
              onChange={(e) => changeEmail(e.target.value)}
            />
          </BockInput>
          {showRecaptcha && (
            <ReCaptcha>
              <HCaptcha
                sitekey={recaptcha_privateke}
                onVerify={(token) => changeRecaptha(token)}
              />
            </ReCaptcha>
          )}

          <ButtonLogin onClick={() => forgotRequest()} type="button">
            ENTRAR
          </ButtonLogin>

          <HRORSSOs>
            <TextORSSO>OU</TextORSSO>
          </HRORSSOs>

          <MenuItemLink onClick={() => backLogin()}>
            <ButtonBackLogin type="button">
              acessar usando login / senha
            </ButtonBackLogin>
          </MenuItemLink>

          <TextFooter>problemas de acesso? entre em nosso chat</TextFooter>
        </LeftBox>

        <RightBox>
          <LogoRight>
            <img src={arte} alt="Plataforma Verde" />
          </LogoRight>
        </RightBox>
      </Form>
    </Container>
  );
};

export default LoginSSO;
