import { ImgHTMLAttributes, useState } from 'react';

import { Container, Img, Loader } from './styles';

interface IAvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number;
  variant?: 'light' | 'dark';
}

const Avatar = ({ size, src, variant }: IAvatarProps): JSX.Element => {
  const [loading, setLoading] = useState(true);

  const handleOnLoadImage = () => setLoading(false);

  return (
    <Container className="root-avatar" size={size || 32}>
      {loading && <Loader size={size || 32} variant={variant || 'dark'} />}
      <Img src={src} onLoad={handleOnLoadImage} loaded={!loading} />
    </Container>
  );
};

export default Avatar;
