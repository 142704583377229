import { useLocation } from 'react-router-dom';
import {
  Dashboard,
  Documents,
  ElectronicMarket,
  Monitoring,
  Moving,
  Registers,
  Reports,
} from 'src/components/Icons';

import Dropdown from './Dropdown';
import { Container } from './styles';

const DEFAULT_MENUS = [
  {
    path: '/dashboard',
    icon: <Dashboard />,
  },
  {
    icon: <Registers />,
    submenus: {
      label: 'Cadastros',
      paths: [
        {
          label: 'Contratantes',
          path: '/contratantes',
        },
        {
          label: 'Negociações',
          path: '/negociacoes',
        },
      ],
    },
  },
];

const Sidebar = (): JSX.Element => {
  const location = useLocation();

  const isActive = (menu) => {
    if (!menu?.path && !menu.submenus) {
      return undefined;
    }

    if (menu?.path === location.pathname) {
      return true;
    }

    return menu?.submenus?.paths
      .map(({ path }) => path)
      .includes(location.pathname);
  };

  return (
    <Container>
      {DEFAULT_MENUS.map((menu, index) => (
        <Dropdown
          key={index.toString()}
          active={isActive(menu) && 1}
          icon={menu.icon}
          submenus={menu.submenus}
          path={menu.path}
        />
      ))}
    </Container>
  );
};

export default Sidebar;
