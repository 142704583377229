import { useEffect, useState } from 'react';

import SwitchForm from 'src/components/SwitchForm';
import api from 'src/services/api';

import { Container } from './styles';
const Services = (): JSX.Element => {
  const [switchOptions, setSwitchOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.providers.get('/api/v1/providers/types');

        const options = response.data.map(({ code, name }) => ({
          label: name,
          value: code,
        }));

        setSwitchOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  return (
    <Container>
      <SwitchForm
        label="Serviços prestados"
        options={switchOptions}
        variant="small"
        name="types"
        required
      />
    </Container>
  );
};

export default Services;
