import { HtmlHTMLAttributes, useRef, useEffect, useState } from 'react';

import format from 'date-fns/format';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { IModalContentProps } from 'src/components/ModalWrapper';
import FormTableInsert from 'src/components/FormTableInsert';
import { IFormTableHandlers } from 'src/components/FormTable';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import { licensesSchema } from 'src/validators/providers/store/index';
import { v4 as uuidv4 } from 'uuid';

import Button, { IButtonHandlers } from '../Button';
import Textarea from '../Textarea';
import { Body, Container, Footer, Head, Instruction } from './styles';

interface ILicenseProps {
  formRef?: any;
  closeButton?: any;
  storeLicenses?: any;
}
const MIN_JUSTIFICATION_LENGTH = 30;
const MAX_JUSTIFICATION_LENGTH = 500;

const LicenseModal = ({
  closeButton,
  formRef,
  storeLicenses,
}: ILicenseProps): JSX.Element => {
  const buttonRef = useRef<IButtonHandlers>(null);
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [licenceOptions, setLicenceOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.providers.get('/api/v1/licenses/types');

        const options = response.data
          .map(({ uuid_license_type, name }) => ({
            label: name,
            value: uuid_license_type,
          }))
          .sort((a, b) => (a.label > b.label ? 0 : -1));

        setLicenceOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  const resetForm = () => {
    const { licenses } = formRef.current.getData();

    formRef.current.setData({});
    formRef.current.setErrors({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      const data = await licensesSchema.validate(formData, defaultOptions);
      formTableRef.current?.add({ id: uuidv4(), ...data });

      setTimeout(() => resetForm(), 200);
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const formTableColumns = {
    size: '3fr 2fr 1fr 1fr 1fr 1fr 1fr',
    fields: [
      {
        key: 'uuid_license_type',
        label: 'Tipo de licença',
        format: (typeId) => {
          const license = licenceOptions?.find(
            (license: SelectOptionsInterface) => license.value === typeId
          ) || {
            label: '',
          };

          return license.label;
        },
      },
      { key: 'name', label: 'Nome do documento' },
      { key: 'code', label: 'Número do documento' },
      {
        key: 'emission',
        label: 'Data de emissão',
        format: (emission) =>
          emission ? format(emission, 'dd/MM/yyyy') : 'Não informado',
      },
      {
        key: 'validity_date',
        label: 'Data de validade',
        format: (validityDate) => format(validityDate, 'dd/MM/yyyy'),
      },
      {
        key: 'alert_date',
        label: 'Data de alerta',
        format: (alertDate) =>
          alertDate ? format(alertDate, 'dd/MM/yyyy') : 'Não informado',
      },
      {
        key: 'file',
        label: 'Documento',
        format: (document) => document?.name || 'Documento',
      },
    ],
  };

  return (
    <Container>
      <Head>{closeButton}</Head>
      <Body>
        <FormTableInsert
          formRef={formRef}
          formTableRef={formTableRef}
          formTableColumns={formTableColumns}
          handleOnSubmit={handleOnSubmit}
          name="licenses"
          title="Adicionar licenças"
        >
          <FormGroup columns="1fr 1fr 1fr">
            <Select
              label="Tipo de licença"
              name="uuid_license_type"
              options={licenceOptions}
            />
            <Input label="Nome do documento" name="name" />
            <Input label="Número do documento" required name="code" />
          </FormGroup>
          <FormGroup columns="1fr 1fr 1fr 2fr 1fr">
            <DatePicker label="Data de emissão" name="emission" />
            <DatePicker label="Data de validade" name="validity_date" />
            <DatePicker label="Data de alerta" name="alert_date" />
            <InputFile label="Anexar documento" name="file" accept="*" />
            <Button ref={addButtonRef} type="submit" label="Incluir" />
          </FormGroup>
        </FormTableInsert>
      </Body>
      <Footer>
        <Button
          type="button"
          ref={buttonRef}
          label="Enviar"
          onClick={storeLicenses}
        />
      </Footer>
    </Container>
  );
};

export default LicenseModal;
