import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { useField } from '@unform/core';
import masks, { IMagicMasksNames } from 'magic-masks';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';

import ControlSecurityEntry from './ControlSecurityEntry';
import { Container, Field, FieldContainer } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  mask?: IMagicMasksNames | string;
  returnUnmasked?: boolean;
  hidden?: boolean;
  required?: boolean | undefined;
  disabled?: boolean;
  defaultValue?: string;
}

export interface InputHandlers {
  setValue(value: string): void;
  field: HTMLInputElement | null;
}

const Input: ForwardRefRenderFunction<InputHandlers, InputProps> = (
  {
    id,
    hidden = false,
    label,
    name,
    mask,
    returnUnmasked = false,
    disabled = false,
    required,
    type,
    onChange,
    ...props
  },
  ref
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { clearError, fieldName, registerField, error } = useField(name);

  useImperativeHandle(
    ref,
    () => ({
      setValue: (value) => {
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      },
      field: inputRef.current,
    }),
    [inputRef]
  );

  useEffect(() => {
    if (inputRef.current) {
      registerField({
        name: fieldName,
        ref: inputRef,
        getValue: (fieldRef) => {
          const { value } = fieldRef.current;

          if (returnUnmasked) {
            return masks.clear(value);
          }

          return value;
        },
        setValue: (fieldRef, value = '') => {
          if (mask) {
            fieldRef.current.value = masks[mask](String(value));
            fieldRef.current.defaultValue = masks[mask](String(value));
          } else {
            fieldRef.current.value = value;
          }
        },
      });
    }
  }, [fieldName, registerField, mask, returnUnmasked]);

  const handleOnFocus = () => clearError();

  const handleOnChangeControlSecurityEntry = (showPassword) => {
    if (inputRef.current) {
      if (inputRef.current.type != '') {
        inputRef.current.type = showPassword ? 'text' : 'password';
      }
    }
  };

  const handleOnChange = useCallback(
    (event) => {
      if (mask && inputRef.current) {
        const { value } = event.target;

        const updatedValue = masks[mask](value);
        inputRef.current.value = updatedValue;
      }

      if (onChange) {
        onChange(event);
      }
    },
    [mask, onChange]
  );

  return (
    <Container className={`root-input-container ${error}`} hidden={hidden}>
      <Label htmlFor={id}>
        {label}
        {required === true ? '*' : ''}
      </Label>
      <FieldContainer>
        <Field
          ref={inputRef}
          className={`root-input-field ${error && 'error'}`}
          id={id}
          name={fieldName}
          {...props}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          disabled={disabled}
          type={type}
        />
        {type === 'password' && (
          <ControlSecurityEntry onChange={handleOnChangeControlSecurityEntry} />
        )}
      </FieldContainer>
      <InputErrorMessage
        fieldLabel={label}
        fieldName={fieldName}
        message={error}
      />
    </Container>
  );
};

Input.displayName = 'input';

export default forwardRef(Input);
