/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import format from 'date-fns/format';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormTable, { IFormTableHandlers } from 'src/components/FormTable';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface ILicenseProps {
  formRef?: any;
}

interface IFormData {
  id?: string;
  license_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const LegalRequirements = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [residueOptions, setResidueOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.residues.get('/api/v1/residues/types');

        const options = response.data.map(({ uuid_license_type, name }) => ({
          label: name,
          value: uuid_license_type,
        }));

        setResidueOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  const resetForm = () => {
    const { licenses } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = [
    {
      key: 'uuid_license_type',
      label: 'CATEGORIA',
      format: (typeId) => {
        const license = residueOptions?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'TECNOLOGIA DE TRATAMENTO' },
    { key: 'code', label: 'CLASSE' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  const formTableColumnsStorage = [
    {
      key: 'uuid_license_type',
      label: 'TIPO DE ARMAZENAGEM',
      format: (typeId) => {
        const license = residueOptions?.find(
          (license: SelectOptionsInterface) => license.value === typeId
        ) || {
          label: '',
        };

        return license.label;
      },
    },
    { key: 'name', label: 'ESTADO FÍSICO' },
    { key: 'code', label: 'ACONDICIONAMENTO' },
    {
      key: 'id',
      label: '',
      format: (id) => (
        <button type="button" value={id} onClick={handleOnClickRemove}>
          remove
        </button>
      ),
    },
  ];

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">DEFINIÇÕES LEGAIS</Title>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr">
            <Select
              label="Lista do Código ABNT NBR 10004 (opcional)"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código IBAMA (opcional)"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código IBAMA - Subdescrição (opcional)"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista do Código CONAMA (opcional)"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Lista de Produtos Químicos Perigosos da NBR 14725-3"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="1fr">
            <Select
              label="Categoria de Produtos Químicos Perigosos da NBR 14725-3"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <Title variant="subtitle">CLASSES DE RISCO CÓDIGO ONU</Title>
          <FormGroup columns="1fr">
            <Select
              label="Lista do Código ONU (opcional)"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>
          <FormGroup columns="2fr 2fr">
            <Select
              label="Classe/Subclasse Risco Principal"
              name="uuid_license_type"
              options={residueOptions}
            />
            <Select
              label="Classe/Subclasse Risco Subsidiário"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>

          <FormGroup columns="2fr 2fr">
            <Select
              label="Número de Risco"
              name="uuid_license_type"
              options={residueOptions}
            />
            <Select
              label="Grupo de Embalagem"
              name="uuid_license_type"
              options={residueOptions}
            />
          </FormGroup>
        </FormGroup>
      </Form>
    </FormGroup>
  );
};

export default LegalRequirements;
