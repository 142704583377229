import styled from 'styled-components';

export const Container = styled.button`
  background: #ffb50c;
  width: 100%;
  height: 41px;
  color: #dd1021 !important;
  line-height: 20px;
  font-weight: bold;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;
  font: normal normal bold 16px/24px Roboto;
  box-shadow: none;
  &:hover {
    opacity: 0.9;
  }
`;
