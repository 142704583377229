import { useCallback, useState } from 'react';

import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';
import Title from 'src/components/Title';

import { Container, Content, Controls } from './styles';

const List = (): JSX.Element => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    data: [],
    total: 0,
  });

  return (
    <Container>
      <Title variant="header">Legislação</Title>
      <Content>
        <Iframe
          url="https://app.powerbi.com/view?r=eyJrIjoiZGEyMzBkMWYtNzNiMS00ZmIyLTg5YzgtZDk5ZWE5ODU4ZDg2IiwidCI6IjJiMjY2ZmE5LTNmOTMtNGJiMS05ODMwLTYzNDY3NTJmMDNlNCIsImMiOjF9"
          width="100%"
          height="1000px"
          id="myId"
          className="myClassname"
          display="inline"
          position="relative"
        />
      </Content>
    </Container>
  );
};

export default List;
