import { FC } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import routes, { RouteInterface } from './';
import AuthRoute from './Auth';
import PrivateRoute from './Private';
import PublicRoute from './Public';

const Routes: FC = () => {
  const makeTitle = (title?: string, label?: string): string => {
    const pageTitleSuffix = title || label || '';
    const pageTitle = `Plataforma Verde - ${pageTitleSuffix}`;

    return pageTitle;
  };

  const makeRoute = (route) => {
    // const key = route.type + route.path;
    const pageTitle = makeTitle(route.title, route.label);

    switch (true) {
      case route.type === 'private':
        return (
          <PrivateRoute
            title={pageTitle}
            breadcrumbs={route.breadcrumbs}
            {...route}
          />
        );
      case route.type === 'public':
        return <PublicRoute title={pageTitle} {...route} />;

      default:
        return <AuthRoute title={pageTitle} {...route} />;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ sub_routes, ...route }) => [
          makeRoute(route),
          sub_routes?.map((sub_route) => makeRoute(sub_route)),
        ])}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
