import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  padding: 32px;

  .root-title.header {
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid #cccccc;
  }
`;

export const Content = styled.div`
  padding: 16px 10px 20px 10px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const Controls = styled.div`
  padding: 16px 0;
`;
