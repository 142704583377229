import * as CompanyProfile from './CompanyProfile';
import Configs from './Configs';
import * as Contractor from './Contractor';
import Dashboard from './Dashboard';
import * as Emissions from './Emissions';
import * as Legislation from './Legislation';
import Lighthouse from './Lighthouse';
import * as Logout from './Logout';
import * as Providers from './Providers';
import * as Residues from './Residues';

export default {
  CompanyProfile,
  Configs,
  Dashboard,
  Emissions,
  Legislation,
  Logout,
  Providers,
  Residues,
  Contractor,
  Lighthouse,
};
