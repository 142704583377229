import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  .root-label {
    margin-bottom: 2px;
  }
`;

export const DisplayField = styled.input`
  width: 100%;

  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  height: 30px;
  padding: 0 12px;

  ${({ theme }) => css`
    color: ${theme.input.normal.foreground};
    background-color: ${theme.input.normal.background};
    border: 1px solid ${theme.input.normal.border};

    transition: 200ms linear border-color;

    &.error {
      border-color: ${theme.input.error.border};
    }

    :read-only {
      border-color: ${theme.input.normal.border};
      padding-right: 0;
    }

    :not(:read-only):hover,
    :not(:read-only):focus {
      border-color: ${theme.input.normal.focus.border};
    }
  `}

  &:read-only {
    cursor: pointer;
  }
`;

export const FileField = styled.input`
  display: none;
`;

export const FieldContainer = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    background-color: #ffffff;
    position: absolute;
    width: 24px;
    height: 16px;
    right: 1px;
    padding-right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;
