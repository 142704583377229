import styled from 'styled-components';

export const Container = styled.div`
  & label {
    font-family: 'Roboto Condensed' sans-serif;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0px;
    color: #202020;
    margin-bottom: 0;
  }

  & input[type='file'] {
    display: none;
  }

  & input[type='button'] {
    width: 60px;
    height: 18px;
    background: #0e843f 0% 0% no-repeat padding-box;
    border-radius: 2px;
    text-align: left;
    font-size: 13px;
    font-weight: medium;
    font-family: 'Roboto Condensed' sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    text-indent: 15px;
  }

  & .top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .bottom-wrapper {
    margin-top: 3px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    width: inherit;
    height: 80px;
    overflow-x: auto;
    padding: 5px;
  }

  & .selectedFileName {
    text-align: left;
    font: normal normal normal 13px/21px Roboto;
    letter-spacing: 0px;
    color: #808080;
    /* width: 90%; */
  }
  & .selectedFilePath {
    display: none;
  }
  & .excludeBtn {
    /* width: 10%; */
    text-align: right;
    padding-right: 5px;
    cursor: pointer;
  }

  & .btn-wrapper {
    position: relative;

    & svg {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
    }
  }

  & table {
    width: 100%;
  }
`;
