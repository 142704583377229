import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 170px;
  zindex: 1;
  background: transparent
    radial-gradient(closest-side at 50% 0%, #3a811d 0%, #3a811d 100%) 0% 0%
    no-repeat padding-box;
`;

export const FooterBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 90%;
  margin-left: 5%;
  padding-right: 30px;
  padding-left: 30px;
`;
