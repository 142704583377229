import { useLocation } from 'react-router-dom';
import { Config } from 'src/components/Icons';
import logo from 'src/assets/img/logo_green.png';
import Navigation from './Navigation';
import NavigationAdm from './NavigationAdm';
import { Container, LogoBox, Menu } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/ducks/index';

const Sidebar = (): JSX.Element => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <Container>
      <LogoBox>
        <img src={logo} alt="Logo" />
      </LogoBox>
      {user.plans != 'ADM' && <Navigation />}
      {user.plans == 'ADM' && <NavigationAdm />}
      <Menu
        active={
          location.pathname.indexOf('/configuracoes') > -1 ? 1 : undefined
        }
        to="/configuracoes"
      >
        <Config />
      </Menu>
    </Container>
  );
};

export default Sidebar;
