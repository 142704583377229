import { FC } from 'react';

import marcaCarrefour from 'src/assets/img/carrefour_logo-negative.png';
import marcaPV from 'src/assets/img/logo-rodape-NL.png';

import {
  Container,
  FooterCarrefourMenu,
  FooterTel,
  FooterBrandCarrefour,
  FooterBrandPlatform,
  FooterCarrefourLink,
} from './styles';

const FooterLogin: FC = ({ children }) => {
  return (
    <Container>
      <>
        <section>
          <FooterBrandCarrefour>
            <>
              <img src={marcaCarrefour} alt="Carrefour" />
            </>
          </FooterBrandCarrefour>
          <FooterBrandPlatform>
            <>
              <img src={marcaPV} alt="Plataforma Verde" />
            </>
          </FooterBrandPlatform>

          <FooterCarrefourMenu>
            <>
              <h3>PLATAFORMA VERDE</h3>
              <ul>
                <li>
                  <a href="https://plataformaverde.com.br/conhecanos">
                    Conheça
                  </a>
                </li>
                <li>
                  <a href="https://plataformaverde.com.br/funcionalidades">
                    Funcionalidades
                  </a>
                </li>
                <li>
                  <a href="https://plataformaverde.com.br/blockchainpv">
                    Block Chain
                  </a>
                </li>
                <li>
                  <a href="https://plataformaverde.com.br/suporte">Suporte</a>
                </li>
              </ul>

              <FooterTel>
                <p>Telefone: (11) 2337-4049</p>
              </FooterTel>
              <FooterCarrefourLink>
                <p>
                  <a href="https://greenplat.com/">
                    www.plataformaverde.com.br
                  </a>
                </p>
              </FooterCarrefourLink>
            </>
          </FooterCarrefourMenu>
        </section>
      </>
    </Container>
  );
};

export default FooterLogin;
