import { primary, secondary } from './_defaultTheme';

export default {
  disabled: {
    background: '#eeeeee',
  },
  primary: {
    normal: {
      borderColor: '#989898',
      background: '#ffffff',
      toggle: {
        background: '#989898',
      },
    },
    active: {
      borderColor: primary,
      background: primary,
      toggle: {
        background: '#ffffff',
      },
    },
  },
  secondary: {
    normal: {
      borderColor: '#989898',
      background: '#ffffff',
      toggle: {
        background: '#989898',
      },
    },
    active: {
      borderColor: secondary,
      background: secondary,
      toggle: {
        background: '#ffffff',
      },
    },
  },
};
