import { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';

interface ISteps {
  content: ReactElement;
  data?: { [key: string]: any };
  label: string;
  key: string;
}

interface IUseStepFormProps {
  initialData?: { [key: string]: any };
  steps: ISteps[];
  onChange?: (param: 'next' | 'previous' | null) => void;
  onSubmit?: (param: any) => void;
}

interface IUseStepForm {
  form: ReactElement;
  hasNextStep: boolean;
  hasPreviousStep: boolean;
  handleOnChangeStep(event: any): void;
  nextStep(currentStep?: number): number;
  previousStep(currentStep?: number): number;
}

const useStepForm = ({
  onChange,
  onSubmit,
  steps,
}: IUseStepFormProps): IUseStepForm => {
  const formRef = useRef<FormHandles>(null);

  const [formControl, setFormControl] = useState({
    currentStep: 0,
    lastAction: null,
    steps: steps.map((step) => ({
      ...step,
      data: {},
    })),
  });

  useEffect(() => {
    const makeScroll = () => {
      if (formControl.lastAction) {
        const container = document.getElementById('root_content');

        if (container?.scrollTop && container.scrollTop > 0) {
          container?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    };

    makeScroll();
  }, [formControl.lastAction]);

  useEffect(() => {
    if (onChange && formControl) {
      onChange(formControl.lastAction);

      formRef.current?.setData(
        formControl.steps[formControl.currentStep]?.data || {}
      );
    }
  }, [formControl, onChange]);

  const hasNextStep = (currentStep?: number) => {
    return (currentStep || formControl.currentStep) + 1 < steps.length;
  };

  const hasPreviousStep = (currentStep?: number) => {
    return (currentStep || formControl.currentStep) - 1 >= 0;
  };

  // Pass to next step if possible
  const nextStep = (currentStep) => {
    const target = currentStep || formControl.currentStep;

    if (hasNextStep(target)) {
      return target + 1;
    }

    if (onSubmit) {
      const formData = formControl.steps.reduce((acc, { data, key }) => {
        acc[key] = data;
        return acc;
      }, {});

      onSubmit(formData);
    }

    return target;
  };

  // Returns to previous step if possible
  const previousStep = (currentStep) => {
    const target = currentStep || formControl.currentStep;

    return hasPreviousStep(target) ? target - 1 : target;
  };

  const getCurrentStepForm = (): ReactElement => {
    const { content } = steps[formControl.currentStep];

    return cloneElement(content, { formRef });
  };

  const handleOnChangeStep = (event) => {
    const value = event.target.value;

    setFormControl(({ currentStep, steps }) => {
      const formData = formRef.current?.getData() || {};
      steps[currentStep].data = formData;

      const updatedStep =
        value === 'next' ? nextStep(currentStep) : previousStep(currentStep);

      return { currentStep: updatedStep, lastAction: value, steps };
    });
  };

  return {
    form: getCurrentStepForm(),
    hasNextStep: hasNextStep(),
    hasPreviousStep: hasPreviousStep(),
    nextStep,
    previousStep,
    handleOnChangeStep,
  };
};

export default useStepForm;
