const mediaQuery = (width: number, type = 'min'): string =>
  `@media (${type}-width: ${width}px)`;

const sizes = {
  desktopLarge: 1920,
  desktop: 1024,
  tablet: 768,
  mobile: 520,
};

const media = {
  custom: mediaQuery,
  desktopLarge: mediaQuery(sizes.desktopLarge),
  desktop: mediaQuery(sizes.desktop),
  tablet: mediaQuery(sizes.tablet),
  mobile: mediaQuery(sizes.mobile),
  max: {
    desktopLarge: mediaQuery(sizes.desktopLarge, 'max'),
    desktop: mediaQuery(sizes.desktop, 'max'),
    tablet: mediaQuery(sizes.tablet, 'max'),
    mobile: mediaQuery(sizes.mobile, 'max'),
  },
};

export default media;
