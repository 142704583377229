import yup from 'src/libs/yup';

export default yup.object().shape({
  uuid_certificate: yup.string(),
  name: yup.string().required(),
  number: yup
    .number()
    .typeError('Número do documento deve ser um número')
    .min(0, 'Maior que 0')
    .required(),
  emission: yup
    .date()
    .required()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  attachment: yup.mixed().required(),
});
