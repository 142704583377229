import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 5px;

  border-radius: 0 0 5px 5px !important;

  font-weight: bold;
  color: #ffffff;
  background-color: #0060a8;
  border-radius: 6px;
  transition: opacity 0.3s;
  font: normal normal bold 14px/24px Roboto;
  box-shadow: none;
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;
