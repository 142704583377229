import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  z-index: 10;

  svg {
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;
