import { Container, Dot, IContainerProps } from './styles';

type TSPulseLoaderProps = IContainerProps;

const PulseLoader = ({ color, size }: TSPulseLoaderProps): JSX.Element => {
  return (
    <Container color={color} size={size} className="root-pulse-loader">
      <Dot className="bounce1" />
      <Dot className="bounce2" />
      <Dot className="bounce3" />
    </Container>
  );
};

export default PulseLoader;
