import {
  ComponentType,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';

import {
  Body,
  Row,
  Cell,
  Container,
  Content,
  Head,
  Label,
  Total,
  Table,
  Separator,
} from './styles';

interface IFormTableColumns {
  key: string;
  label: string;
  format?: (param: any) => string | ComponentType | JSX.Element;
}

interface IFormTableProps {
  columns: IFormTableColumns[];
  name: string;
  columnsSize?: string;
  showTotal?: boolean;
}

interface IFormData {
  [key: string]: any;
}

export interface IFormTableHandlers {
  add(newData: IFormData): void;
  remove(id: string): void;
  setData(data: IFormData[]): void;
}

const FormTable: ForwardRefRenderFunction<
  IFormTableHandlers,
  IFormTableProps
> = ({ columns, columnsSize, name, showTotal }, ref) => {
  const formTableRef = useRef<{ value: IFormData[] }>({ value: [] });

  const [data, setData] = useState<IFormData[]>([]);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    if (formTableRef.current) {
      registerField({
        name: fieldName,
        ref: formTableRef.current,
        getValue: (ref) => {
          return ref.value;
        },
        setValue: (ref, value: IFormData[]) => {
          setData(value || []);
        },
      });
    }
  }, [fieldName, formTableRef, registerField]);

  useEffect(() => {
    if (formTableRef?.current) {
      formTableRef.current.value = data;
    }
  }, [data]);

  useImperativeHandle(
    ref,
    () => ({
      add: (newData) => setData((currentData) => [newData, ...currentData]),
      remove: (id) =>
        setData((currentData) => currentData.filter((item) => item.id !== id)),
      setData,
    }),
    []
  );

  const getRowContent = (columns, item) => {
    return columns.map(({ key, format }, index) => {
      const value = key === '*' ? item : item[key];

      return (
        <Cell key={index.toString()} title={format ? format(value) : value}>
          {format ? format(value) : value}
        </Cell>
      );
    });
  };

  return (
    <Container>
      <Content className="table">
        <Table>
          <Head>
            <Row columnsSize={columnsSize} numberColumns={columns.length}>
              {columns.map(({ label }, index) => (
                <Label key={index.toString()}>{label}</Label>
              ))}
            </Row>
          </Head>
          <Separator />
          <Body
            columnsSize={data.length ? columnsSize : '1fr'}
            numberColumns={data.length ? columns.length : 1}
          >
            {data.length ? (
              data.map((item, index) => {
                return (
                  <Row
                    key={index.toString()}
                    columnsSize={data.length ? columnsSize : '1fr'}
                    numberColumns={data.length ? columns.length : 1}
                  >
                    {getRowContent(columns, item)}
                  </Row>
                );
              })
            ) : (
              <Row columnsSize={'1fr'} numberColumns={1}>
                <Cell key="0" className="empty-table">
                  Não há itens cadastrados. Selecione os itens nos campos acima
                  e clique no botão incluir para cadastrar.
                </Cell>
              </Row>
            )}
          </Body>
        </Table>
      </Content>
      {showTotal ? (
        <Total>
          Total de itens: <strong>{data.length}</strong>
        </Total>
      ) : null}
    </Container>
  );
};

FormTable.displayName = 'FormTable';

export default forwardRef(FormTable);
