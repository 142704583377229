import styled, { css } from 'styled-components';

export const Container = styled.div`
  .root-label {
    margin-bottom: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const FieldLabel = styled.label`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.checkbox.normal.foreground};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Field = styled.input`
  display: none;

  &:checked + span {
    border-color: ${({ theme }) => theme.checkbox.checked.border};
    background-color: ${({ theme }) => theme.checkbox.checked.background};

    svg {
      color: ${({ theme }) => theme.checkbox.checked.icon};
    }

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.checkbox.checked.focus.border};
      background-color: ${({ theme }) =>
        theme.checkbox.checked.focus.background};
    }
  }
`;

export const CheckMark = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;

  margin-right: 4px;

  border-radius: 50%;

  transition: background-color 200ms linear, border-color 200ms linear;

  border: 1px solid ${({ theme }) => theme.checkbox.normal.border};
  background-color: ${({ theme }) => theme.checkbox.normal.background};

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.checkbox.normal.focus.border};
  }

  svg {
    pointer-events: none;
    transition: color 200ms linear;

    color: ${({ theme }) => theme.checkbox.normal.icon};
  }
`;
