import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  .root-label {
    margin-bottom: 2px;
  }

  display: ${({ hidden }) => (!hidden ? 'block' : 'none')};
`;

export const FieldContainer = styled.div`
  position: relative;
`;

export const Field = styled.input`
  width: 100%;

  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  height: 30px;
  padding: 0 10px;

  ${({ theme }) => css`
    color: ${theme.input.normal.foreground};
    background-color: ${theme.input.normal.background};
    border: 1px solid ${theme.input.normal.border};

    transition: 200ms linear border-color;

    &.error {
      border-color: ${theme.input.error.border};
    }

    :read-only {
      border-color: ${theme.input.normal.border};
    }

    :not(:read-only):hover,
    :not(:read-only):focus {
      border-color: ${theme.input.normal.focus.border};
    }
  `}

  &:read-only {
    cursor: not-allowed;
  }
`;
