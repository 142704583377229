import { useCallback, useEffect, useState } from 'react';
import { RefObject } from 'react';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import { clear } from 'magic-masks';
import FadeIn from 'src/components/FadeIn';
import FormGroup from 'src/components/FormGroup';
import { Modules, Functionalities } from 'src/components/Icons';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import Title from 'src/components/Title';
import Radio from 'src/components/Radio';
import api from 'src/services/api';

import Register from './Register';
import { Label } from 'recharts';

import { Card, CheckBoxBody } from '../styles';
interface IBasicDataProps {
  formRef: RefObject<FormHandles>;
}

const BasicData = ({ formRef }: IBasicDataProps): JSX.Element => {
  const [selected_1, setSelected_1] = useState('');
  const [selected_2, setSelected_2] = useState('');
  const [acordoChecked, setAcordoChecked] = useState<boolean>(false);
  const [data, setData] = useState({});
  const radioHandler1 = (e) => {
    setSelected_1(e.target.value);
    setSelected_2('');
  };
  const radioHandler2 = (e) => {
    setSelected_2(e.target.value);
  };

  const handleOnChangeUserType = () =>
    setData((currentData) => ({
      ...currentData,
      vehicle_type: true,
    }));

  const renderList = () => {
    return (
      <>
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Usuários Primários</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Usuários Secundários</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Pedido de Coleta e Módulo Terceiro</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Monitoramento</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Logística Reversa</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Gestão de Documentos</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Gerador de PGRS</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
        <hr />
        <FormGroup columns="3fr 1fr 1fr 1fr">
          <CheckBoxBody style={{ textAlign: 'start' }}>
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={acordoChecked}
                onChange={() => setAcordoChecked(!acordoChecked)}
              />
              <span>Homologação</span>
            </label>
          </CheckBoxBody>
          <Select
            label="Qtde de Usuários"
            required
            name="qts_users"
            options={[
              { label: '10 Usuários (padrão)', value: '10' },
              { label: '20 Usuários', value: '20' },
              { label: '30 Usuários', value: '30' },
              { label: '40 Usuários', value: '40' },
              { label: '50 Usuários', value: '50' },
              { label: '60 Usuários', value: '60' },
              { label: '70 Usuários', value: '70' },
              { label: '80 Usuários', value: '80' },
              { label: '90 Usuários', value: '90' },
              { label: '100 Usuários', value: '100' },
            ]}
            onChange={handleOnChangeUserType}
          />
          <Input
            label="Valor por Usuários"
            name="qts_in_users"
            type="number"
            value="0,00"
          />
          <Input label="Valor Total" name="total" type="text" value="0,00" />
        </FormGroup>
      </>
    );
  };

  const renderRadioMMF = () => {
    return (
      <>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={15}>
            <label>
              <b>
                2. INFORME A QUANTIDADE DE GERADORES CLASSIFICADOS PELOS TIPOS
                ABAIXO
              </b>
            </label>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr 1fr 1fr">
              <Input
                label="Qtde. de Módulos Pequeno Gerador"
                name="email"
                type="number"
                value="0"
              />
              <Input
                label="Qtde. de Módulos Grande Gerador"
                name="email"
                type="number"
                value="0"
              />
              <Input
                label="Qtde. de Módulos Gerador com Gestão Interna"
                name="email"
                type="number"
                value="0"
              />
            </FormGroup>
          </Scope>
        </FormGroup>
        <br />
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={15}>
            <Title icon={Functionalities} variant="subtitle">
              FUNCIONALIDADES EXTRAS (MATRIZ-FILIAL)
            </Title>
          </FormGroup>
          <Scope path="company">{renderList()}</Scope>
        </FormGroup>
      </>
    );
  };

  const renderRadioMG = () => {
    return (
      <>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={15}>
            <label>
              <b>2. ESCOLHA O TIPO DE GERADOR</b>
            </label>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr">
              <Radio
                label="Tipo de Módulo Gerador"
                name="tipo_gerador"
                onClick={(e) => radioHandler2(e)}
                options={[
                  { label: 'Pequeno Gerador', value: 'pg' },
                  { label: 'Grande Gerador', value: 'gg' },
                  { label: 'Gerador com Gestão Interna', value: 'ggi' },
                ]}
              />
              {selected_2 === 'pg' && (
                <Card>
                  <h3 style={{ marginBottom: '1%' }}>Sobre o módulo</h3>
                  <p style={{ marginBottom: '1%' }}>
                    No módulo Pequeno Gerador o usuário tem acesso as
                    funcionalidades básicas para gerenciar as saídas dos seus
                    resíduos.
                  </p>
                  <p>
                    <b>Nesse módulo temos: </b>
                    Inclusão de licenças, inclusão de certificado de destinação
                    final, emissão de MTR online integrada com os sistemas
                    públicos, geração de relatórios e gestão de usuários que tem
                    acesso ao sistema e dashboard básico com indicadores das
                    resultantes.
                  </p>
                </Card>
              )}
              {selected_2 === 'gg' && (
                <Card>
                  <h3 style={{ marginBottom: '1%' }}>Sobre o módulo</h3>
                  <p style={{ marginBottom: '1%' }}>
                    No módulo Grande Gerador o usuário tem acesso as
                    funcionalidades básicas e cadastros operacionais para
                    gerenciar as saídas dos seus resíduos.
                  </p>
                  <p>
                    <b>Nesse módulo temos: </b>
                    Cadastros de equipamentos, acondicionamento, veículos,
                    Inclusão de licenças, inclusão de certificado de destinação
                    final, emissão de MTR online integrada com os sistemas
                    públicos, geração de relatórios e gestão de usuários que tem
                    acesso ao sistema e dashboard com indicadores das
                    resultantes.
                  </p>
                </Card>
              )}
              {selected_2 === 'ggi' && (
                <Card>
                  <h3 style={{ marginBottom: '1%' }}>Sobre o módulo</h3>
                  <p style={{ marginBottom: '1%' }}>
                    O módulo Gestão Interna oferece aos usuários um maior
                    controle na gestão interna dos seus resíduos, além de gerir
                    as suas saídas externas.
                  </p>
                  <p>
                    <b>Nesse módulo temos: </b>
                    Nesse módulo temos: Emissão de MI, rótulo de resíduos com QR
                    Code, cadastros de áreas, cadastro de estoque, cadastros de
                    equipamentos, acondicionamento, veículos, inclusão de
                    licenças, inclusão de certificado de destinação final,
                    emissão de MTR online integrada com os sistemas públicos,
                    geração de relatórios e gestão de usuários que tem acesso ao
                    sistema e dashboard básico com indicadores das resultantes.
                  </p>
                </Card>
              )}
            </FormGroup>
          </Scope>
        </FormGroup>
        <br />
        {['pg', 'gg', 'ggi'].includes(selected_2) && (
          <FormGroup columns="1fr" gap={24}>
            <FormGroup columns="1fr" gap={15}>
              <Title icon={Functionalities} variant="subtitle">
                FUNCIONALIDADES EXTRAS (GERADORES)
              </Title>
            </FormGroup>
            <Scope path="company">{renderList()}</Scope>
          </FormGroup>
        )}
      </>
    );
  };

  return (
    <FadeIn>
      <Form ref={formRef} onSubmit={console.log}>
        <FormGroup columns="1fr" gap={24}>
          <FormGroup columns="1fr" gap={15}>
            <Title icon={Modules} variant="subtitle">
              MÓDULOS
            </Title>
            <label>
              <b>
                1. ESCOLHA O TIPO DE MÓDULO PRINCIPAL PARA O PLANO DE CONTRATO
              </b>
            </label>
          </FormGroup>
          <Scope path="company">
            <FormGroup columns="1fr">
              <Radio
                label="Módulo Principal"
                name="modulo"
                onClick={(e) => radioHandler1(e)}
                options={[
                  { label: 'Módulo Matriz-Filial', value: 'mmf' },
                  { label: 'Módulo Gerador', value: 'mg' },
                ]}
              />
            </FormGroup>
          </Scope>
        </FormGroup>
        <br />
        {selected_1 == 'mmf' && renderRadioMMF()}
        {selected_1 == 'mg' && renderRadioMG()}
      </Form>
    </FadeIn>
  );
};

export default BasicData;
