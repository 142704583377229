import { useRef } from 'react';

import format from 'date-fns/format';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import Stepper, { IStepperHandlers } from 'src/components/Stepper';
import Text from 'src/components/Text';
import Title from 'src/components/Title';
import useStepForm from 'src/hooks/useStepForm';
import api from 'src/services/api';

import BasicData from './BasicData';
import IntegratedSystems from './IntegratedSystems';
import LegalRequirements from './LegalRequirements';
import Negotiation from './Negotiation';
import { Container, Content, Controls, Head, HeadContent } from './styles';

const Store = (): JSX.Element => {
  const submitButtonRef = useRef<IButtonHandlers>(null);
  const stepperRef = useRef<IStepperHandlers>(null);

  const storeLicenses = async (providerId, licenses) => {
    try {
      const responses = licenses.map(async (license) => {
        const formData = new FormData();

        formData.append('file', license.file);
        formData.append('type', 'operacao');
        formData.append('uuid_license_type', license.uuid_license_type);
        formData.append('name', license.name);
        formData.append('code', license.code);

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/licenses`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (error) {
      return error;
    }
  };

  const storeCertificates = async (providerId, certificates) => {
    try {
      const responses = certificates.map(async (certificate) => {
        const formData = new FormData();

        formData.append('attachment', certificate.attachment);
        formData.append('number', certificate.number);
        formData.append('emission', format(certificate.emission, 'yyyy-MM-dd'));

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/certificates`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (error) {
      return error;
    }
  };

  const onChangeStep = (typeAction) => {
    if (typeAction) stepperRef?.current?.[typeAction]();
  };

  const onSubmit = async (formData) => {
    try {
      submitButtonRef.current?.startLoad();

      const { basic_data, employee, licenses_and_certificates } = formData;
      const { company, responsible } = basic_data;

      const response = await api.providers.post('/api/v1/providers', {
        company,
        responsible,
        employee: employee.employees,
        provider: {
          alias: company.alias,
          activity: company.segment,
          types: [
            {
              code: 'recipient',
            },
            {
              code: 'carrier',
            },
          ],
        },
      });

      const { uuid_provider } = response.data.provider;

      // Store licenses and certificates
      const storeLicensesResponse = await storeLicenses(
        uuid_provider,
        licenses_and_certificates.licenses
      );

      const storeCertificatesResponse = await storeCertificates(
        uuid_provider,
        licenses_and_certificates.certificates
      );

      // Check if has errors in store responses
      const hasErrorInStoreLicenses = storeLicensesResponse.find(
        ({ status }) => status === 'rejected'
      );

      const hasErrorInStoreCertificates = storeCertificatesResponse.find(
        ({ status }) => status === 'rejected'
      );

      // If has errors change message
      if (hasErrorInStoreLicenses || hasErrorInStoreCertificates) {
        const storeErrors: string[] = [];

        if (hasErrorInStoreLicenses) {
          storeErrors.push('licenças');
        }

        if (hasErrorInStoreCertificates) {
          storeErrors.push('certificados');
        }

        toast.warning(
          `Sucesso ao cadastrar o resíduo! Com erros no cadastro de: ${storeErrors.join(
            ' e '
          )} `
        );
      } else {
        toast.success('Sucesso ao cadastrar o resíduo!');
      }
    } catch (error) {
      toast.error('Erro ao cadastrar o resíduo');
    } finally {
      submitButtonRef.current?.finishLoad();
    }
  };

  const steps = [
    { key: 'basic_data', content: <BasicData />, label: 'DADOS BÁSICOS' },
    {
      key: 'licenses_and_certificates',
      content: <LegalRequirements />,
      label: 'REQUISITOS LEGAIS',
    },
    {
      key: 'employee',
      content: <Negotiation />,
      label: 'NEGOCIAÇÕES',
    },
    {
      key: 'system',
      content: <IntegratedSystems />,
      label: 'SISTEMAS INTEGRADOS',
    },
  ];

  const { form, handleOnChangeStep, hasPreviousStep } = useStepForm({
    steps: steps,
    onChange: onChangeStep,
    onSubmit,
  });

  return (
    <Container>
      <Head>
        <HeadContent>
          <Title variant="header">Cadastrar Novo Resíduo</Title>
          <Text>(*) campos obrigatórios</Text>
        </HeadContent>
      </Head>
      <Content>
        <Stepper ref={stepperRef} steps={steps.map(({ label }) => label)} />
        <FormGroup columns="1fr" gap={24}>
          {form}
          <Controls>
            <Button
              label="Voltar"
              variant="outline"
              value="previous"
              onClick={handleOnChangeStep}
              disabled={!hasPreviousStep}
            />
            <Button
              ref={submitButtonRef}
              label="Próximo"
              value="next"
              onClick={handleOnChangeStep}
            />
          </Controls>
        </FormGroup>
      </Content>
    </Container>
  );
};

export default Store;
