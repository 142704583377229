import React from 'react';

import { Container } from './styles';

interface BockInputSelectCarrefourProps {
  error: boolean;
}

const BockInputSelectCarrefour: React.FC<BockInputSelectCarrefourProps> = ({
  children,
  error,
}) => <Container error={error}>{children}</Container>;

export default BockInputSelectCarrefour;
