import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  padding: 32px;

  .root-title.header {
    padding-bottom: 8px;
    margin-bottom: 32px;
    border-bottom: 1px solid #cccccc;
  }

  .root-edit-menu-controls .root-button {
    width: 100%;
    margin-top: 16px;
  }

  .root-card {
    padding: 16px;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.2fr;
  grid-gap: 32px;
`;

export const Content = styled.div``;
