import styled from 'styled-components';

export interface IContainerProps {
  display: string;
}

export const Container = styled.div<IContainerProps>`
  display: ${({ display }) => display};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 9999;
  transform: scale(1);
  opacity: 1;
`;

export const PvLoading = styled.div`
  width: 100px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.4));
`;
