import styled from 'styled-components';

export const Container = styled.div``;

export const Card = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f5f5;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

interface ILabelProps {
  highlight?: boolean;
}

export const Label = styled.div<ILabelProps>`
  color: ${({ highlight, theme }) => (highlight ? theme.primary : '#505050')};
  font-size: 0.875rem;
  font-weight: 700;

  margin-bottom: 16px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
`;
