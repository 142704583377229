import { FC } from 'react';

import { IContainerProps, Container } from './styles';

type IGridProps = IContainerProps;

const Grid: FC<IGridProps> = ({
  children,
  columns,
  gap = 16,
  rows = '1fr',
}) => {
  return (
    <Container columns={columns} rows={rows} gap={gap} className="root-grid">
      {children}
    </Container>
  );
};

export default Grid;
