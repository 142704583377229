import Card from 'src/components/Card';
import { IMenu } from 'src/hooks/useEditForm';

import Button from './Button';
import { Container, Controls } from './styles';

interface IEditMenuProps {
  menus: IMenu[];
}

const EditMenu = ({ menus }: IEditMenuProps): JSX.Element => {
  return (
    <Container>
      <Card>
        {menus.map(({ controls, ...menu }, index) => (
          <>
            <Button key={index.toString()} {...menu} />
          </>
        ))}
        <Controls className="root-edit-menu-controls">
          {menus.find(({ active }) => active)?.controls}
        </Controls>
      </Card>
    </Container>
  );
};

export default EditMenu;
