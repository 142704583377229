import { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import Button from 'src/components/Button';
import Table from 'src/components/Table';
import Title from 'src/components/Title';
import api from 'src/services/api';

import { Container, Content, Controls } from './styles';

const List = (): JSX.Element => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    data: [],
    total: 0,
  });

  const getResidues = useCallback(async ({ pageIndex = 0 }) => {
    try {
      const response = await api.residues.get(
        `/api/v1/residues/?page=${pageIndex + 1}`
      );

      setData(response.data);
    } catch (error) {}
  }, []);

  const handleOnClickNew = () => {
    history.push('/residuos/cadastro');
  };

  const columns: Column[] = [
    {
      Header: 'Nome comum do resíduo',
      accessor: 'trading_name',
    },
    {
      Header: 'Tipo de resíduo',
      accessor: 'company_name',
    },
    {
      Header: 'Qdt. de destinos',
      accessor: 'segment',
    },
    {
      Header: 'Qdt. de transportadores',
      accessor: 'city',
    },
    {
      Header: 'Status',
      accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
    },
  ];

  return (
    <Container>
      <Title variant="header">Resíduos</Title>
      <Content>
        <Title variant="title">Resíduos</Title>
        <Controls>
          <Button label="Novo resíduo" onClick={handleOnClickNew} />
        </Controls>
        <Table
          columns={columns}
          data={data.data}
          loading={false}
          totalData={data.total}
          onChangePage={getResidues}
        />
      </Content>
    </Container>
  );
};

export default List;
