import { FC } from 'react';

import { Container } from './styles';

interface IHoverProps {
  value?: any;
  onClick?: (value: any) => void;
  onDoubleClick?: (value: any) => void;
}

const Hover: FC<IHoverProps> = ({
  children,
  onClick,
  onDoubleClick,
  value,
}) => {
  const handleOnClick = (event) => {
    if (event.target.id === 'root_hover' && onClick) {
      onClick(value || null);
    }
  };

  const handleOnDoubleClick = () => {
    if (onDoubleClick) onDoubleClick(value || null);
  };

  return (
    <Container
      id="root_hover"
      className="root-hover-container"
      onClick={handleOnClick}
      onDoubleClick={handleOnDoubleClick}
    >
      {children}
    </Container>
  );
};

export default Hover;
