/* eslint-disable react/display-name */
import { useRef } from 'react';

import format from 'date-fns/format';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import IconButton from 'src/components/IconButton';
import { Employee, RemoveCircle } from 'src/components/Icons';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import { employeesSchema } from 'src/validators/providers/store/index';
import { v4 as uuidv4 } from 'uuid';

interface IEmployeesProps {
  formRef?: any;
}

const Employees = ({ formRef }: IEmployeesProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const employeesOptionTypes = [
    {
      label: 'Administrativo',
      value: 'adm',
    },
    {
      label: 'Ajudante',
      value: 'assistant',
    },
    {
      label: 'Motorista',
      value: 'driver',
    },
    {
      label: 'Operação',
      value: 'operation',
    },
  ];

  const resetForm = () => {
    const { employees } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('employees', employees);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      const data = await employeesSchema.validate(formData, defaultOptions);

      const hasItems = formRef.current
        ?.getFieldValue('employees')
        .find((item) => item.name === data.name && item.email === data.email);

      if (hasItems) {
        throw new Error('Colaborador já adicionado');
      }

      formTableRef.current?.add({ id: uuidv4(), ...data });

      setTimeout(() => resetForm(), 200);
    } catch (error: any) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = {
    // size: '1fr 1fr 0.8fr 1fr 0.5fr',
    size: '180px 150px 130px 130px 110px 120px 100px 100px 100px 80px 100px 20px',
    fields: [
      {
        key: 'name',
        label: 'Nome',
      },
      { key: 'email', label: 'Email' },
      {
        key: 'function',
        label: 'Cargo',
        format: (value) => value || 'Não informado',
      },
      {
        key: 'type',
        label: 'Tipo Colab.',
        format: (functionTypeId) => {
          const functionType = employeesOptionTypes?.find(
            (functionType: SelectOptionsInterface) =>
              functionType.value === functionTypeId
          ) || {
            label: '',
          };

          return functionType.label;
        },
      },
      { key: 'cpf', label: 'CPF', format: (value) => value || 'Não informado' },
      {
        key: 'moop_expiration',
        label: 'Validade MOPP',
        format: (value) =>
          value ? format(value, 'dd/MM/yyyy') : 'Não informado',
      },
      {
        key: 'moop_file',
        label: 'Doc. MOPP',
        format: (value) => value?.name || 'Não informado',
      },
      { key: 'cnh', label: 'CNH', format: (value) => value || 'Não informado' },
      {
        key: 'license_expiration',
        label: 'Validade CNH',
        format: (value) =>
          value ? format(value, 'dd/MM/yyyy') : 'Não informado',
      },
      { key: 'license_type', label: 'Categ. CNH' },
      {
        key: 'license_file',
        label: 'Doc. CNH',
        format: (value) => value?.name || 'Documento',
      },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton
            className="table-button-remove"
            value={id}
            onClick={handleOnClickRemove}
          >
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="employees"
      title="Adicionar Colaborador"
      icon={Employee}
      optional
    >
      <FormGroup columns="2fr 1fr 1fr">
        <Input label="Nome Completo" name="name" maxLength={100} required />
        <Input label="Cargo" name="function" maxLength={40} />
        <Select
          label="Tipo de Colaborador"
          name="type"
          options={employeesOptionTypes}
        />
      </FormGroup>
      <FormGroup columns="0.94fr 0.94fr 2fr">
        <Input label="CPF" name="cpf" mask="cpf" placeholder="000.000.000-00" />
        <DatePicker
          label="Validade MOPP"
          name="moop_expiration"
          placeholder="dd/mm/aaaa"
        />
        <InputFile
          label="Imagem documento MOPP"
          name="mopp_document_image"
          placeholder="Clique para anexar"
          accept="*"
        />
      </FormGroup>
      <FormGroup columns="0.94fr 0.94fr 2fr">
        <Input
          label="CNH"
          name="license_number"
          placeholder="00000000000"
          maxLength={11}
        />
        <DatePicker
          label="Validade CNH"
          name="license_expiration"
          placeholder="dd/mm/aaaa"
        />
        <InputFile
          label="Imagem documento CNH"
          name="license_file"
          placeholder="Clique para anexar"
          accept="*"
        />
      </FormGroup>
      <FormGroup columns="1.09fr 3fr 0.4fr">
        <Input
          label="Categoria CNH"
          name="license_type"
          placeholder="Ex: B, D"
          maxLength={20}
        />
        <Input
          label="E-mail"
          name="email"
          type="email"
          maxLength={255}
          required
        />
        <Button
          ref={addButtonRef}
          variant="table-inclusion"
          type="submit"
          label="Incluir"
        />
      </FormGroup>
    </FormTableInsert>
  );
};

export default Employees;
