import React from 'react';

import { Container } from './styles';

interface BockCarrefourInputProps {
  error: boolean;
}

const BockCarrefourInput: React.FC<BockCarrefourInputProps> = ({
  children,
  error,
}) => <Container error={error}>{children}</Container>;

export default BockCarrefourInput;
