import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/img/bg-carrefour.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  > form {
    height: 100vh;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const ImageLock = styled.div`
  display: flex;
  background-color: #0060a8;
  border-radius: 6px 0 0 6px;
  margin-right: 10px;

  > img {
    width: 60%;
    height: auto;
    padding-top: 12px !important;
    padding-left: 15px !important;
    padding-bottom: 12px !important;
    border-radius: 5px 0 0 5px;
    margin-right: 10px !important;
    border-radius: 6px 0 0 6px !important;
  }
`;

export const ImageUser = styled.div`
  display: flex;
  background-color: #0060a8;
  border-radius: 6px 0 0 6px;
  margin-right: 10px;

  > img {
    width: 60%;
    height: auto;
    padding-top: 12px !important;
    padding-left: 15px !important;
    padding-bottom: 12px !important;
    border-radius: 5px 0 0 5px;
    margin-right: 10px !important;
    border-radius: 6px 0 0 6px !important;
  }
`;

export const LeftBox = styled.div`
  width: 60%;
  justify-content: center;
  align-items: center;
  padding-right: 150px !important;
`;

export const RightBox = styled.div`
  width: 40%;
  padding-right: 0px !important;
  justify-content: center;
  align-items: center;
  padding-right: 50px !important;
`;

export const FormLogin = styled.form`
  width: 75%;
  display: flex;
  padding: 30px;
  padding-right: 10px;
  padding-bottom: 0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

export const TextEntre = styled.p`
  width: 100%;
  float: left;
  margin-bottom: 10px;
  padding-left: 75px;
  margin-top: 0;
  line-height: 35px;
  font-size: 22px;
  color: #ffffff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 100;
  background-image: url('/assets/img/ico-pv-negative.png');
  background-repeat: no-repeat;
  background-position: 0px center;
`;

export const MenuItemLink = styled.p`
  color: #0060a8 !important;
  text-shadow: #cccccc 1px 1px 1px;
  text-decoration: none;
  justify-content: right;
  margin: 0px 0;
  display: flex;
  align-items: center;

  transition: opacity 0.3s;

  &:hover {
    text-decoration: underline;
  }

  > svg {
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const TextFooter = styled.p`
  text-align: right;
  font: normal normal normal 10px/24px Roboto;
  color: '#0060a8';
  line-height: 30px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 5%;
  justify-content: center;
  cursor: pointer;
`;

export const LogoRight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 105px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 40px;

  color: '#FFFFFF';

  &:after {
    content: '';
    display: block;
    width: 55px;
    border-bottom: 10px solid #e44c4e;
  }
`;

export const InputCarrefour = styled.h1`
  font-size: 16px !important;
`;

export const InfoSelectCompany = styled.p`
  font-size: 24px;
  text-align: center;
  color: #0060a8;
  font-weight: bold;
  width: 100%;
  text-shadow: #cccccc 1px 1px 1px;
`;
