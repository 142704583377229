import {
  ComponentType,
  ForwardRefRenderFunction,
  forwardRef,
  ButtonHTMLAttributes,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';

import { ThemeContext } from 'styled-components';

import BeatLoader from 'react-spinners/BeatLoader';

import { ArrowDown } from '../Icons';
import { Container, Span } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | 'default'
    | 'error'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  icon?: ComponentType;
  iconPosition?: 'before' | 'after';
  label: string;
  divider?: boolean;
  variant?: 'line' | 'normal' | 'outline' | 'table-inclusion';
  hide?: boolean;
}

export interface IButtonHandlers {
  finishLoad(): void;
  startLoad(): void;
}

const Button: ForwardRefRenderFunction<IButtonHandlers, IButtonProps> = (
  {
    color = 'primary',
    icon: Icon,
    iconPosition,
    divider,
    label,
    onClick,
    type = 'button',
    variant = 'normal',
    hide,
    ...props
  },
  ref
) => {
  const [loading, setLoading] = useState(false);
  const themeContext = useContext(ThemeContext);

  useImperativeHandle(ref, () => ({
    finishLoad: () => setLoading(false),
    startLoad: () => setLoading(true),
  }));

  return (
    <Container
      className={`root-button ${loading && 'loading'} ${
        Icon && 'button-icon'
      } ${variant} icon-${iconPosition} ${hide && 'hide'}`}
      onClick={onClick}
      type={type}
      color={color}
      variant={variant}
      disabled={loading}
      {...props}
    >
      {(iconPosition === 'before' || !iconPosition) &&
        (variant === 'table-inclusion' && Icon === undefined ? (
          <>
            <ArrowDown />
            {divider && <span className="button-divider" />}
          </>
        ) : (
          <>
            {Icon && <Icon />}
            {divider && <span className="button-divider" />}
          </>
        ))}
      {!loading ? (
        <Span>{label}</Span>
      ) : (
        <BeatLoader size={8} color="#ffffff" />
      )}
      {iconPosition === 'after' &&
        (variant === 'table-inclusion' && Icon === undefined ? (
          <>
            {divider && <span className="button-divider" />}
            <ArrowDown />
          </>
        ) : (
          <>
            {divider && <span className="button-divider" />}
            {Icon && <Icon />}
          </>
        ))}
    </Container>
  );
};

Button.displayName = 'Button';

export default forwardRef(Button);
