import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import arte from 'src/assets/img/arte-login.png';
import company from 'src/assets/img/ico-building.png';
import logoSSMA from 'src/assets/img/logo-arcos-ssma.png';
import logo from 'src/assets/img/logo-arcos.png';
import BockInput from 'src/components/BockInput';
import BockInputArcos from 'src/components/BockInputArcos';
import ButtonArcosContinuar from 'src/components/ButtonArcosContinuar';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import ButtonLogin from 'src/components/ButtonLogin';
import ContactGreenPlateArcos from 'src/components/ContactGreenPlateArcos';
import ContentTextPage from 'src/components/ContentTextPage';
import InputArcosLogin from 'src/components/InputArcosLogin';
import SelectLoginArcos from 'src/components/SelectLoginArcos';
import api from 'src/services/api';
import terceiro from 'src/services/terceiro';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { RootState } from 'src/store/ducks/index';
import getApiErrors from 'src/utils/getApiErrors';

import {
  Container,
  Logo,
  FormLogin,
  LeftBox,
  InputArcos,
  RightBox,
  TextEntre,
  TextFooter,
  MenuItemLink,
  ReCaptcha,
  FooterArcos,
  SSMA,
} from './styles';

const ChooseCompany: React.FC = (props) => {
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();

  const [companiesOptions, setCompaniesOptions] = useState([] as any);
  const [companiesSelected, setCompanies] = useState([] as any);
  const { modules, token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const getLogin = async () => {
    const loading = false;
    dispatch(AuthActions.authLoading(loading));
    localStorage.setItem('erro', '2');
    history.push('/');
  };

  useEffect(() => {
    const getCompanie = async () => {
      const loading = true;
      dispatch(AuthActions.authLoading(loading));
      if (
        modules?.modules.length == 1 &&
        modules?.modules[0]?.user_profile == null
      ) {
        const data = {
          uuid: modules?.modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                if (
                  companies[0]?.plans == 'CANCELADO' ||
                  companies[0]?.plan == 'CANCELADO'
                ) {
                  const loading = false;
                  dispatch(AuthActions.authLoading(loading));
                  toast.error(
                    'Seu contrato de uso com a GreenPlat está vencido. Caso queira renovar acesse um dos nossos canais de atendimento.',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  const response = await api.user.post(
                    '/api/v1/companies/current',
                    data,
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenNew,
                      },
                    }
                  );

                  const dataNew = {
                    token: response.data.token,
                  };

                  window.location.href =
                    'api/v1/logar_by_micro_service/' + response.data.token;
                }
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  getLogin();
                }
              }
            } else {
              if (modules?.modules) {
                const data = modules.modules;
                const options = Object.keys(data)
                  .map((key) => ({
                    label: `${data[key].nome_comercial}`,
                    value: data[key],
                  }))
                  .filter((option) => option.value !== '');
                setCompaniesOptions(options);
              }
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
            }
          }

          // dispatch(AuthActions.authSelectCompanie(token, { companies }));
        } catch (error: any) {
          const loading = false;
          dispatch(AuthActions.authLoading(loading));
          if (error.response.data.error == 'Provided token is expired.') {
            getLoginExpired();
          }

          if (error.response.data.error == 'No companies bounded to user') {
            toast.error(
              ' Usuário inativo no sistema. Por favor entre em contato com o responsável para reativar seu acesso ao sistema! ',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
          }
        }
      } else {
        if (
          modules?.modules[0]?.user_profile?.usuario_id != null &&
          modules.length == 1
        ) {
          window.location.href =
            terceiro.REACT_APP_TERCEIRO +
            '/logar_by_micro_service/' +
            modules?.modules[0]?.user_profile?.usuario_id +
            '/' +
            token;
        }
      }
      if (modules?.modules) {
        const data = modules.modules;
        const options = Object.keys(data)
          .map((key) => ({
            label: `${data[key].nome_comercial}`,
            value: data[key],
          }))
          .filter((option) => option.value !== '');
        setCompaniesOptions(options);
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
      }
    };

    getCompanie();
  }, []);

  const getLoginExpired = async () => {
    localStorage.setItem('erro', '4');
    history.push('/');
  };

  const getCompany = async (code) => {
    if (code.value !== '') {
      try {
        setCompanies(code.value);
        funcErrorCompanie(false);
      } catch (error) {}
    }
  };

  const funcErrorCompanie = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    if (companiesSelected == '') {
      toast.error('Escolha uma unidade!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorCompanie(true);
    } else {
      if (companiesSelected.user_profile?.usuario_id != null) {
        const loading = true;
        dispatch(AuthActions.authLoading(loading));
        window.location.href =
          terceiro.REACT_APP_TERCEIRO +
          '/logar_by_micro_service/' +
          companiesSelected.user_profile?.usuario_id +
          '/' +
          token;
      } else {
        const companySelected = companiesSelected.id;
        dispatch(AuthActions.authCompany(token, companySelected, modules));
        history.push('/escolher-modulo-arcosdourados');
      }
    }
  };

  return (
    <Container>
      <FormLogin>
        <LeftBox>
          <section>
            <Logo>
              <img src={logo} alt="Arcos Dourados" />
            </Logo>
            <InputArcos>
              <TextEntre>Selecione para continuar</TextEntre>
              <BockInputArcos error={errorEmail}>
                <SelectLoginArcos
                  options={companiesOptions}
                  onChange={getCompany}
                  required
                />
              </BockInputArcos>

              <ButtonArcosContinuar
                onClick={() => forgotRequest()}
                type="button"
              >
                CONTINUAR
              </ButtonArcosContinuar>
            </InputArcos>
            <ContactGreenPlateArcos />
            <SSMA>
              <img src={logoSSMA} alt="Arcos Dourados" />
            </SSMA>
          </section>
        </LeftBox>

        {/* Same as */}
        <RightBox>
          <ContentTextPage />
        </RightBox>
      </FormLogin>
      <FooterArcos>® 2022 GreenPlat™ Todos os direitos reservados</FooterArcos>
    </Container>
  );
};

export default ChooseCompany;
