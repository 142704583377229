import { useRef } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import Can from 'src/components/Can';
import FormGroup from 'src/components/FormGroup';
import Grid from 'src/components/Grid';
import Stepper, { IStepperHandlers } from 'src/components/Stepper';
import Text from 'src/components/Text';
import Title from 'src/components/Title';
import useStepFormControl from 'src/hooks/useStepFormControl';
import api from 'src/services/api';
import { basicDataSchema } from 'src/validators/residues/store';

import BasicData from './BasicData';
import Storages from './BasicData/Storages';
import Treatments from './BasicData/Treatments';
import IntegratedSystems from './IntegratedSystems';
import LegalRequirements from './LegalRequirements';
import Negotiation from './Negotiation';
import { Container, Content, Controls, Head, HeadContent } from './styles';

const Store = (): JSX.Element => {
  const nextButtonRef = useRef<IButtonHandlers>(null);

  // Refs of forms
  const formRefBasicData = useRef<FormHandles>(null);
  const formRefTreatments = useRef<FormHandles>(null);
  const formRefStorages = useRef<FormHandles>(null);
  const formRefLegalRequirements = useRef<FormHandles>(null);
  const formRefNegotiation = useRef<FormHandles>(null);

  const steps = [
    {
      label: 'Dados básicos',
      content: (
        <Grid columns="1fr" gap={24}>
          <BasicData formRef={formRefBasicData} />
          <Treatments formRef={formRefTreatments} />
          <Storages formRef={formRefStorages} />
        </Grid>
      ),
      validation: basicDataSchema,
      ref: formRefBasicData,
    },
    {
      key: 'legal_requirements',
      content: <LegalRequirements />,
      label: 'REQUISITOS LEGAIS',
    },
    {
      key: 'negotiations',
      content: <Negotiation formRef={formRefLegalRequirements} />,
      label: 'NEGOCIAÇÕES',
    },
    // {
    //   key: 'system',
    //   content: <IntegratedSystems />,
    //   label: 'SISTEMAS INTEGRADOS',
    // },
  ];

  const storeLicenses = async (providerId, licenses) => {
    try {
      const responses = licenses.map(async (license) => {
        const formData = new FormData();

        formData.append('file', license.file);
        formData.append('type', 'operacao');
        formData.append('uuid_license_type', license.uuid_license_type);
        formData.append('name', license.name);
        formData.append('code', license.code);

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/licenses`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (error) {
      return error;
    }
  };

  const storeCertificates = async (providerId, certificates) => {
    try {
      const responses = certificates.map(async (certificate) => {
        const formData = new FormData();

        formData.append('attachment', certificate.attachment);
        formData.append('number', certificate.number);
        formData.append('emission', format(certificate.emission, 'yyyy-MM-dd'));

        const response = await api.providers.post(
          `/api/v1/providers/${providerId}/certificates`,
          formData
        );

        return response;
      });

      return Promise.allSettled(responses);
    } catch (error) {
      return error;
    }
  };

  const onFinish = async () => {
    try {
      nextButtonRef.current?.startLoad();

      // const storeProviderResponse = await storeProvider();

      // await storeLicenses(storeProviderResponse);
      // await storeCertificates(storeProviderResponse);

      toast.success('Cadastro efetuado com sucesso!');
    } catch (exception) {
    } finally {
      nextButtonRef.current?.finishLoad();
    }
  };

  // Functions and variables to control the form's state
  const { currentStep, isFirstStep, isLastStep, nextStep, previousStep } =
    useStepFormControl({
      steps,
      onFinish,
    });

  return (
    <Container>
      <Head>
        <HeadContent>
          <Title variant="header">Cadastrar Novo Resíduo</Title>
          <Text>(*) campos obrigatórios</Text>
        </HeadContent>
      </Head>
      <Content>
        <Stepper
          current={currentStep}
          steps={steps.map(({ label }) => label)}
        />
        <FormGroup columns="1fr" gap={24}>
          {steps.map(({ content }, index) => (
            <Can key={index.toString()} see={currentStep === index}>
              {content}
            </Can>
          ))}
          <Controls>
            <Button
              label="Voltar"
              variant="outline"
              value="previous"
              onClick={previousStep}
              disabled={isFirstStep}
            />
            <Button
              ref={nextButtonRef}
              label={isLastStep ? 'Salvar' : 'Próximo'}
              value="next"
              onClick={nextStep}
            />
          </Controls>
        </FormGroup>
      </Content>
    </Container>
  );
};

export default Store;
