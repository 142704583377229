import styled from 'styled-components';

export const Container = styled.div`
  .root-title {
    margin-bottom: 24px;
  }
`;

export const Justification = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  gap: 32px;

  font-size: 0.875rem;
  color: #000;
`;

export const JustificationLabel = styled.div`
  font-weight: 600;
`;

export const JustificationValue = styled.div``;
