import { FC } from 'react';

import { IContainerProps, Container } from './styles';

interface ICanProps extends IContainerProps {
  id?: string;
}

const Can: FC<ICanProps> = ({ children, id, see = true }) => {
  return (
    <Container id={id} see={see}>
      {children}
    </Container>
  );
};

export default Can;
