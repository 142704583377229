/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';
import { IButtonHandlers } from 'src/components/Button';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import { Board } from 'src/components/Icons';
import Input from 'src/components/Input';
import Select, { SelectOptionsInterface } from 'src/components/Select';
import Title from 'src/components/Title';
import api from 'src/services/api';
import { v4 as uuidv4 } from 'uuid';

interface ILicenseProps {
  formRef?: any;
}

interface IFormData {
  id?: string;
  license_type: string;
  document_name: string;
  document_number: string;
  emission_date: string;
  validity_date: string;
  alert_date: string;
  document: string;
}

const BasicData = ({ formRef }: ILicenseProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const [residueOptionsType, setResidueOptionsType] = useState([] as any);

  useEffect(() => {
    const getTypes = async () => {
      try {
        const { data } = await api.residues.get('/api/v1/residues/types');

        const options = Object.keys(data)
          .map((key) => ({
            label: data[key],
            value: key,
          }))
          .filter((option) => option.value !== '');

        setResidueOptionsType(options);
      } catch (error) {}
    };

    getTypes();
  }, []);

  const resetForm = () => {
    const { licenses } = formRef.current?.getData();

    formRef.current.setData({});
    formRef.current.setFieldValue('licenses', licenses);
  };

  const handleOnSubmit = async (formData: IFormData) => {
    addButtonRef.current?.startLoad();
    formTableRef.current?.add({ id: uuidv4(), ...formData });

    setTimeout(() => {
      resetForm();

      setTimeout(() => {
        addButtonRef.current?.finishLoad();
      }, 200);
    }, 200);
  };
  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  return (
    <FormGroup columns="1fr" gap={10}>
      <Title variant="subtitle" icon={Board}>
        DADOS DO RESÍDUO
      </Title>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Scope path="residue">
          <FormGroup columns="1fr" gap={24}>
            <FormGroup columns="1fr 1fr 1fr ">
              <Select
                label="Tipo de Resíduo"
                name="type"
                options={residueOptionsType}
                required
              />
              <Input label="Nome Comum do Resíduo" name="name" required />
              <Input label="Código Interno do Resíduo" name="internal_code" />
            </FormGroup>
          </FormGroup>
        </Scope>
      </Form>
    </FormGroup>
  );
};

export default BasicData;
