import { colors, primary } from './_defaultTheme';

export default {
  background: '#ffffff',
  shadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px',
  link: {
    normal: { foreground: colors.gray3, background: 'transparent' },
    hover: { foreground: primary, background: 'transparent' },
    active: { foreground: primary, background: primary },
  },
  user: {
    icon: {
      foreground: colors.gray1,
      background: '#f5f5f5',
    },
  },
};
