import DateRangePickerContainer from 'src/components/DateRangePicker';
import Grid from 'src/components/Grid';
import {
  ClienteAtivos,
  ClienteInativos,
  EditProviders,
  Indicadores,
} from 'src/components/Icons';
import IndicatorCard from 'src/components/IndicatorCard';
import Title from 'src/components/Title';

import { Container } from './styles';
const Indicators = (): JSX.Element => {
  return (
    <Container>
      <div className="header_wrapper">
        <Title variant="subtitle" icon={Indicadores}>
          Indicadores
        </Title>
        <DateRangePickerContainer />
      </div>
      <Grid columns="1fr 1fr 1fr 1fr">
        <IndicatorCard icon={ClienteAtivos} value={70} text="Clientes Ativos" />
        <IndicatorCard
          icon={ClienteInativos}
          value={12}
          text="Clientes Inativos"
        />
      </Grid>
    </Container>
  );
};

export default Indicators;
