import { useCallback, useEffect, useRef, useState, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import axios from 'axios';
import { clear } from 'magic-masks';
import Input from 'src/components/Input';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Select from 'src/components/Select';
import api from 'src/services/api';

import AlertModal from './AlertModal';

interface IRegister {
  fieldType: 'cpf' | 'cnpj' | 'international_registry';
  formRef: RefObject<FormHandles>;
  onChange: ({ value }) => void;
  cities: any;
}

const Register = ({
  fieldType,
  formRef,
  onChange,
  cities,
}: IRegister): JSX.Element => {
  const modalRef = useRef<IModalHandlers>(null);
  const [provider, setProvider] = useState();

  useEffect(() => {
    if (provider) {
      formRef?.current?.setFieldValue(`company.${fieldType}`, '');
      modalRef.current?.open();
    }
  }, [modalRef, provider, formRef, fieldType]);

  const handleOnBlur = useCallback(
    async (event) => {
      const value = clear(event.target.value);

      if (
        (fieldType === 'cnpj' && value.length === 14) ||
        (fieldType === 'cpf' && value.length === 11) ||
        fieldType === 'international_registry'
      ) {
        try {
          const response = await api.providers.get(
            `/api/v1/providers?${fieldType}=${value}`
          );

          setProvider(response.data?.data?.[0]);
        } catch (error) {
          setProvider(undefined);

          try {
            const response = await api.providers.get(
              `/api/v1/companies?${fieldType}=${value}`
            );

            const company = response.data[0];

            Object.keys(company).map((key) => {
              formRef?.current?.setFieldValue(`company.${key}`, company[key]);
            });

            await cities({ value: company.address_state });

            formRef?.current?.setFieldValue(
              'company.address_city',
              company.address_city
            );
          } catch (error) {
            if (fieldType === 'cnpj') {
              try {
                const response = await api.providers.get(
                  `/api/v1/companies?source=receita&${fieldType}=${value}`
                );

                const company = response.data[0];

                Object.keys(company).map((key) => {
                  formRef?.current?.setFieldValue(
                    `company.${key}`,
                    company[key]
                  );
                });

                await cities({ value: company.address_state });

                formRef?.current?.setFieldValue(
                  'company.address_city',
                  company.address_city.toUpperCase()
                );
              } catch (error) {}
            }
          }
        }
      }
    },
    [fieldType, formRef, cities]
  );

  // const setCompanyData = (company) => {
  //   Object.keys(company).map((key) => {
  //     formRef.current?.setFieldValue(`company.${key}`, company[key]);
  //   });
  // };

  const DEFAULT_REGISTER_FIELDS = {
    cpf: {
      label: 'CPF',
      name: 'cpf',
      component: (
        <Input
          label="CPF"
          name="cpf"
          mask="cpf"
          placeholder="000.000.000-00"
          onBlur={handleOnBlur}
          required
          returnUnmasked
        />
      ),
    },
    cnpj: {
      label: 'CNPJ',
      name: 'cnpj',
      component: (
        <Input
          label="CNPJ"
          name="cnpj"
          mask="cnpj"
          placeholder="00.000.000/0000-00"
          onBlur={handleOnBlur}
          required
          returnUnmasked
        />
      ),
    },
    international_registry: {
      label: 'Registro Internacional',
      name: 'international_registry',
      component: (
        <Input
          label="Nr. Registro Internacional"
          name="international_registry"
          onBlur={handleOnBlur}
          maxLength={14}
          required
        />
      ),
    },
  };

  const getOptions = () => {
    const keys = Object.keys(DEFAULT_REGISTER_FIELDS);

    const options = keys.map((key) => {
      const { label, name } = DEFAULT_REGISTER_FIELDS[key];

      return {
        label,
        value: name,
      };
    });

    return options;
  };

  const handleModalClose = () => {
    setProvider(undefined);
  };

  return (
    <>
      <ModalWrapper ref={modalRef} onClose={() => handleModalClose()}>
        <AlertModal
          fieldName={DEFAULT_REGISTER_FIELDS[fieldType].label}
          provider={provider}
        />
      </ModalWrapper>
      <Select
        label="Tipo de Registro"
        name="registry_type"
        options={getOptions()}
        onChange={onChange}
        defaultValue={{
          label: 'CNPJ',
          value: 'cnpj',
        }}
        isSearchable={false}
        required
      />
      {DEFAULT_REGISTER_FIELDS[fieldType].component}
    </>
  );
};

export default Register;
