import { Ref } from 'react';
import { InputHTMLAttributes } from 'react';

import { Box, Container, Input, Text, Toggle } from './styles';
export interface ISwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
  inputRef?: Ref<HTMLInputElement>;
  color?: 'primary' | 'secondary';
  variant?: 'tinny' | 'small' | 'medium' | 'large';
}

const switchSizes = {
  tinny: {
    box: {
      width: 22,
      height: 16,
    },
    toggle: {
      width: 12,
      height: 12,
    },
  },
  small: {
    box: {
      width: 34,
      height: 18,
    },
    toggle: {
      width: 15,
      height: 15,
    },
  },
  medium: {
    box: {
      width: 38,
      height: 20,
    },
    toggle: {
      width: 17,
      height: 17,
    },
  },
  large: {
    box: {
      width: 44,
      height: 24,
    },
    toggle: {
      width: 20,
      height: 20,
    },
  },
};

const Switch = ({
  color = 'primary',
  inputRef,
  name,
  variant = 'medium',
  text,
  ...rest
}: ISwitchProps): JSX.Element => {
  return (
    <Container>
      <Input
        ref={inputRef}
        type="checkbox"
        name={name}
        color={color}
        {...rest}
      />
      <Box style={switchSizes[variant].box} color={color}>
        <Toggle style={switchSizes[variant].toggle} color={color} />
      </Box>
      <Text>{text}</Text>
    </Container>
  );
};

export default Switch;
