import { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import Can from 'src/components/Can';
import Grid from 'src/components/Grid';
import {
  Cancel,
  IcoNovoContratante,
  Next,
  Prev,
  Save,
} from 'src/components/Icons';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Stepper from 'src/components/Stepper';
import Text from 'src/components/Text';
import Title from 'src/components/Title';
import useStepFormControl from 'src/hooks/useStepFormControl';
import api from 'src/services/api';
import { basicDataSchema } from 'src/validators/providers/store/index';

import AlertModal from './AlertModal';
import BasicData from './BasicData';
import Contracts from './Contracts';
import Functionalities from './Functionalities';
import { Container, Content, Controls, Head, HeadContent } from './styles';
import Users from './Users';

const Store = (): JSX.Element => {
  const history = useHistory();

  const nextButtonRef = useRef<IButtonHandlers>(null);

  // Refs of forms
  const formRefBasicData = useRef<FormHandles>(null);
  const formRefContract = useRef<FormHandles>(null);
  const formRefFunctionalities = useRef<FormHandles>(null);
  const formRefUsers = useRef<FormHandles>(null);
  const modalRef = useRef<IModalHandlers>(null);

  // Define the steps of register
  const steps = [
    {
      label: 'Dados do Contratante',
      content: <BasicData formRef={formRefBasicData} />,
      // validation: basicDataSchema,
      // ref: formRefBasicData,
    },
    {
      label: 'Dados do Contrato',
      content: <Contracts formRef={formRefContract} />,
    },
    {
      label: 'Funcionalidades',
      content: <Functionalities formRef={formRefFunctionalities} />,
    },
    {
      label: 'Usuários Master',
      content: <Users formRef={formRefUsers} />,
    },
  ];

  // When finish the steps
  const onFinish = useCallback(async () => {
    try {
      nextButtonRef.current?.startLoad();
      toast.success('Cadastro efetuado com sucesso!');
      history.push('/contratantes');
    } catch (exception: any) {
      toast.error(exception?.message || 'Erro ao cadastrar o contratante.');
    } finally {
      nextButtonRef.current?.finishLoad();
    }
  }, [history]);

  // Functions and variables to control the form's state
  const { currentStep, isFirstStep, isLastStep, nextStep, previousStep } =
    useStepFormControl({
      steps,
      onFinish,
    });

  return (
    <>
      <ModalWrapper ref={modalRef}>
        <AlertModal />
      </ModalWrapper>
      <Container>
        <Head>
          <HeadContent>
            <Title icon={IcoNovoContratante} variant="header">
              Cadastrar Novo Contratante
            </Title>
            <Text className="helper">(*) campos obrigatórios</Text>
          </HeadContent>
        </Head>
        <Content>
          <Stepper
            current={currentStep}
            steps={steps.map(({ label }) => label)}
          />
          <Grid columns="1fr" gap={24}>
            {steps.map(({ content }, index) => (
              <Can key={index.toString()} see={currentStep === index}>
                {content}
              </Can>
            ))}
            <Controls>
              <Button
                label="Cancelar"
                variant="outline"
                value="cancel"
                onClick={() => modalRef.current?.open()}
                icon={Cancel}
                iconPosition="after"
                divider
              />
              <Button
                label="Anterior"
                variant="outline"
                value="previous"
                disabled={isFirstStep}
                hide={isFirstStep}
                onClick={previousStep}
                icon={Prev}
                iconPosition="after"
                divider
              />
              <Button
                ref={nextButtonRef}
                label={isLastStep ? 'Salvar' : 'Próximo'}
                value="next"
                onClick={nextStep}
                icon={isLastStep ? Save : Next}
                iconPosition="after"
                divider
              />
            </Controls>
          </Grid>
        </Content>
      </Container>
    </>
  );
};

export default Store;
