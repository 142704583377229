/* eslint-disable react/display-name */
import { RefObject, useCallback, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormModal from 'src/components/FormModal';
import IconButton from 'src/components/IconButton';
import { Inactive, Justification } from 'src/components/Icons';
import InactiveModal from 'src/components/InactiveModal';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import JustificationModal from 'src/components/JustificationModal';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Select from 'src/components/Select';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Actions from 'src/components/Table/Hover/Actions';
import Title from 'src/components/Title';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import { employeesSchema } from 'src/validators/providers/store/index';
interface IEmployeesProps {
  formRef: RefObject<FormHandles>;
}

interface IDataState {
  data: { [key: string]: any }[];
  total: number;
}

const Employees = ({ formRef }: IEmployeesProps): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const buttonRef = useRef<IButtonHandlers>(null);
  const modalFormRef = useRef<IModalHandlers>(null);
  const justificationsModalRef = useRef<IModalHandlers>(null);
  const inactiveModalRef = useRef<IModalHandlers>(null);

  const [apiURL, setApiURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDataState>({
    data: [],
    total: 0,
  });

  const getEmployees = useCallback(
    async ({ pageIndex = 0, pageSize = 10, showInactive = false }) => {
      try {
        setLoading(true);
        const response = await api.providers.get(
          `/api/v1/providers/${id}/employees?page=${
            pageIndex + 1
          }&per_page=${pageSize}&active=${!showInactive}`
        );

        setData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  const handleOnClickNew = () => {
    modalFormRef.current?.open();
  };

  const storeEmployee = async (employee, update) => {
    try {
      const formData = new FormData();

      formData.append('name', employee.name);
      formData.append('function', employee.function);
      formData.append('collaborator_type', employee.collaborator_type);
      formData.append('cpf', employee.cpf);
      formData.append(
        'mopp_validity',
        format(employee.mopp_validity, 'yyyy-MM-dd')
      );
      formData.append('mopp_document_image', employee.mopp_document_image);
      formData.append('cnh', employee.cnh);
      formData.append(
        'cnh_validity',
        format(employee.cnh_validity, 'yyyy-MM-dd')
      );
      formData.append('cnh_document_image', employee.cnh_document_image);
      formData.append('cnh_categoty', employee.cnh_categoty);
      formData.append('email', employee.email);
      formData.append('number', employee.number);

      const method = update ? 'put' : 'post';
      const response = await api.providers[method](
        `/api/v1/providers/${id}/employees/${employee.uuid_employee || ''}`,
        formData
      );

      return response;
    } catch (exception) {
      throw new Error(
        `Erro ao ${update ? 'editar' : 'cadastrar'} o colaborador!`
      );
    }
  };

  const handleOnSubmit = async (formData) => {
    try {
      buttonRef.current?.startLoad();

      const data = await employeesSchema.validate(formData, defaultOptions);

      storeEmployee(data, data.uuid_employee !== '');
      setTimeout(() => getEmployees({}), 200);
      modalFormRef.current?.close();
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      } else {
        toast.error(error.message);
      }
    } finally {
      buttonRef.current?.finishLoad();
    }
  };

  const columns: Column = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Nome',
      accessor: ({ name, last_name }) => name + last_name,
    },
    { Header: 'Cargo', accessor: 'office' },
    { Header: 'E-mail', accessor: 'email' },
    {
      Header: 'Status',
      accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
    },
  ];

  const handleClickOpenModal = (event) => {
    const { value } = event.target;

    if (event.target.id === 'justification') {
      setApiURL(`/api/v1/providers/${value}/employees/${value}/changes`);
      justificationsModalRef.current?.open();
    } else {
      setApiURL(`/api/v1/providers/${id}/employees/${value}`);
      inactiveModalRef.current?.open();
    }
  };

  const getHoverComponent = ({ deleted_at, uuid_employee }) => {
    return (
      <Hover value={uuid_employee}>
        <Actions>
          <IconButton
            id={deleted_at ? 'justification' : 'inactive'}
            value={uuid_employee}
            onClick={handleClickOpenModal}
          >
            {deleted_at ? <Justification /> : <Inactive />}
          </IconButton>
        </Actions>
      </Hover>
    );
  };

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">Colaboradores cadastrados</Title>
      <ModalWrapper ref={justificationsModalRef}>
        <JustificationModal text="Colaborador" getURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={inactiveModalRef}>
        <InactiveModal text="Colaborador" postURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={modalFormRef}>
        <FormModal
          title="Cadastrar colaborador"
          formRef={formRef}
          buttonRef={buttonRef}
          onSubmit={handleOnSubmit}
        >
          <Input label="" name="uuid_employee" hidden />
          <FormGroup columns="1fr" gap={24}>
            <FormGroup columns="2fr 1fr 1fr">
              <Input label="Nome" name="name" />
              <Input label="Cargo" name="function" />
              <Select
                label="Tipo de colaborador"
                name="collaborator_type"
                options={[
                  {
                    label: 'Ajudante',
                    value: 'assistant',
                  },
                  {
                    label: 'Motorista',
                    value: 'driver',
                  },
                ]}
              />
            </FormGroup>
            <FormGroup columns="1fr 1fr 2fr">
              <Input label="CPF" name="cpf" mask="cpf" />
              <DatePicker label="Validade MOPP" name="mopp_validity" />
              <InputFile
                label="Imagem documento MOPP"
                name="mopp_document_image"
                accept=".jpg,.jpeg,.png"
              />
            </FormGroup>
            <FormGroup columns="1fr 1fr 2fr">
              <Input label="CNH" name="cnh" />
              <DatePicker label="Validade CNH" name="cnh_validity" />
              <InputFile
                label="Imagem documento CNH"
                name="cnh_document_image"
                accept=".jpg,.jpeg,.png"
              />
            </FormGroup>
            <FormGroup columns="1fr 3fr">
              <Input label="Categoria CNH" name="cnh_categoty" />
              <Input label="E-mail" name="email" type="email" />
            </FormGroup>
          </FormGroup>
        </FormModal>
      </ModalWrapper>
      <Table
        allowControls
        columns={columns}
        data={data.data}
        loading={loading}
        totalData={data.total}
        newButtonLabel="Novo colaborador"
        onChangePage={getEmployees}
        onClickNew={handleOnClickNew}
        hoverComponent={getHoverComponent}
      />
    </FormGroup>
  );
};

export default Employees;
