import { IMenu } from 'src/hooks/useEditForm';

import { Container, IconBox, Label } from './styles';

export interface IButtonProps extends IMenu {
  active?: boolean;
}

const Button = ({
  active,
  icon: Icon,
  label,
  ...rest
}: IButtonProps): JSX.Element => {
  return (
    <Container type="button" className={active ? 'active' : ''} {...rest}>
      <IconBox>
        <Icon />
      </IconBox>
      <Label>{label}</Label>
    </Container>
  );
};

export default Button;
