import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 600px;
`;

export const Content = styled.div``;

export const Head = styled.div`
  padding: 16px;
  border-radius: 8px 8px 0 0;

  color: #d92727;
  font-size: 1.25rem;
  background-color: #efefef;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  padding: 16px;
  background-color: #ffffff;

  .root-label {
    font-weight: 600;
  }
`;

export const Footer = styled.div`
  padding: 16px;
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: center;
  gap: 16px;

  background-color: #efefef;
`;

export const Instruction = styled.div`
  font-size: 0.875rem;
  font-weight: 400;

  margin-bottom: 16px;
`;

export const Info = styled.div``;

export const InfoRow = styled.div`
  font-size: 0.875rem;
`;

export const InfoLabel = styled.span`
  font-weight: 600;
  margin-right: 4px;
`;

export const InfoValue = styled.span``;
