import Breadcrumbs from 'src/components/Breadcrumbs';

import Central from './Central';
import Company from './Company';
import Profile from './Profile';
import {
  Container,
  Content,
  LeftContent,
  RightContainer,
  RightContent,
} from './styles';

interface IHeaderProps {
  breadcrumbs?: string[];
}

const Header = ({ breadcrumbs }: IHeaderProps): JSX.Element => {
  return (
    <Container>
      <Content>
        <LeftContent>
          <Company />
          {breadcrumbs && <Breadcrumbs levels={breadcrumbs} />}
        </LeftContent>
        <RightContainer>
          <RightContent>
            <Central />
            <Profile />
          </RightContent>
        </RightContainer>
      </Content>
    </Container>
  );
};

export default Header;
