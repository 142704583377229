import Title from 'src/components/Title';

import { Container } from './styles';

const Configs = (): JSX.Element => {
  return (
    <Container>
      <Title variant="header">Configurações</Title>
    </Container>
  );
};

export default Configs;
