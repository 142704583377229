import { useCallback, useRef, useState, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import { IButtonHandlers } from 'src/components/Button';
import Can from 'src/components/Can';
import FormGroup from 'src/components/FormGroup';
import Grid from 'src/components/Grid';
import IconButton from 'src/components/IconButton';
import { Board } from 'src/components/Icons';
import { Cancel, Next, Prev } from 'src/components/Icons';
import { Inactive, Justification } from 'src/components/Icons';
import LicenseModal from 'src/components/LicenseModal';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Title from 'src/components/Title';
import useStepFormControl from 'src/hooks/useStepFormControl';
import api from 'src/services/api';
import { RootState } from 'src/store/ducks/index';
import { basicDataProfile } from 'src/validators/company/store/index';

import BasicData from '../Store/BasicData';
import {
  Container,
  Content,
  Actions,
  Space,
  ContentList,
  Controls,
} from './styles';

interface IProvider {
  alias?: string;
  type?: string;
  types?: { [key: string]: any }[];
  company?: {
    type: string;
  };
}

const List = (): JSX.Element => {
  const nextButtonRef = useRef<IButtonHandlers>(null);
  const history = useHistory();
  const [apiURL, setApiURL] = useState('');
  const LicenseModalRef = useRef<IModalHandlers>(null);
  const justificationsModalRef = useRef<IModalHandlers>(null);
  const formRefLicenses = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    data: [] as any,
    total: 0,
  });

  const [responsibleFirst, setCompanyresponsibleFirst] = useState([] as any);
  const [responsibleSecond, setCompanyresponsibleSecond] = useState([] as any);

  const [company, setCompany] = useState([] as any);

  const { companySelected } = useSelector((state: RootState) => state.auth);

  // Refs of forms
  const formRefBasicData = useRef<FormHandles>(null);

  useEffect(() => {
    const companyCnpj = companySelected[0]?.cnpj;
    const getCompanyresponsible = async () => {
      try {
        const response = await api.providers.get(
          `/api/v1/companies/${companySelected[0]?.uuid}/responsible`
        );
        setCompanyresponsibleFirst(response.data[0]);
        setCompanyresponsibleSecond(response.data[1]);
      } catch (error) {}
    };

    const getCompany = async () => {
      const response = await api.providers.get(
        `/api/v1/companies/?cnpj=${companyCnpj}`
      );
      setCompany(response.data[0]);
    };

    getCompanyresponsible();
    getCompany();
  }, [companySelected]);

  useEffect(() => {
    formRefBasicData.current?.setData({
      company: {
        ...company,
        registry_type: company?.type || 'cnpj',
        responsible_first: responsibleFirst,
        responsible_second: responsibleSecond,
        city: company?.address_city,
      },
    });
  }, [company]);

  const steps = [
    {
      label: 'Dados básicos',
      content: (
        <Grid columns="1fr" gap={24}>
          <BasicData formRef={formRefBasicData} />
        </Grid>
      ),
      validation: basicDataProfile,
      ref: formRefBasicData,
    },
  ];

  const onFinish = async () => {
    try {
      nextButtonRef.current?.startLoad();

      const dataCompany = formRefBasicData.current?.getData();
      const company = dataCompany?.company;
      const responsible_first = dataCompany?.company.responsible_first;
      const responsible_second = dataCompany?.company.responsible_first;

      delete company.responsible_first;
      delete company.responsible_second;
      delete company.city;

      const response = await api.providers.put(
        `/api/v1/companies/${companySelected[0]?.uuid}`,
        company
      );

      const responsible = [responsible_first, responsible_second];

      const responses = responsible.map(async (responsible) => {
        if (responsible?.uuid_company_responsible == '') {
          delete responsible.uuid_company_responsible;
          await api.providers.post(
            `/api/v1/companies/${companySelected[0]?.uuid}/responsible`,
            responsible
          );
          toast.info('Responsável criado com sucesso!');
        } else {
          await api.providers.put(
            `/api/v1/companies/${companySelected[0]?.uuid}/responsible/${responsible.uuid_company_responsible}`,
            responsible
          );
          // toast.info('Responsável editado com sucesso!');
        }
      });

      toast.success('Edição efetuada com sucesso!');
    } catch (exception) {
    } finally {
      nextButtonRef.current?.finishLoad();
    }
  };

  // Functions and variables to control the form's state
  const { currentStep, isFirstStep, isLastStep, nextStep, previousStep } =
    useStepFormControl({
      steps,
      onFinish,
    });

  const getLicenses = useCallback(
    async ({ pageIndex = 0, pageSize = 10, showInactive = false }) => {
      try {
        setLoading(true);
        const response = await api.providers.get(
          `/api/v1/licenses/?page=${
            pageIndex + 1
          }&per_page=${pageSize}&active=${!showInactive}`
        );

        setData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const storeLicenses = async () => {
    try {
      const data = formRefLicenses.current?.getData();

      const responses = data?.licenses.map(async (license) => {
        const formData = new FormData();
        formData.append('file', license.file);
        formData.append('type', 'operacao');
        formData.append('emission', format(license.emission, 'yyyy-MM-dd'));
        formData.append(
          'expiration',
          format(license.validity_date, 'yyyy-MM-dd')
        );
        formData.append('alert_date', format(license.alert_date, 'yyyy-MM-dd'));

        formData.append('uuid_license_type', license.uuid_license_type);
        formData.append('name', license.name);
        formData.append('code', license.code);

        const response = await api.providers.post(`/api/v1/licenses`, formData);
        LicenseModalRef.current?.close();
        formRefLicenses.current?.setData({});
        getLicenses(0);
        toast.info('Licença cadastrada com sucesso!');
        return response;
      });

      return Promise.allSettled(responses);
    } catch (exception) {
      throw new Error('Erro ao cadastrar as licenças!');
    }
  };

  const handleOnDoubleClickHover = (providerId) => {
    history.push(``);
  };

  const handleClickOpenModal = (event) => {
    const { id, value } = event.target;

    if (id === 'justification') {
      setApiURL(`/api/v1/licenses/${value}/changes`);
      justificationsModalRef.current?.open();
    } else {
      setApiURL(`/api/v1/licenses/${value}`);
      LicenseModalRef.current?.open();
    }
  };

  const getHoverComponent = ({ deleted_at, uuid_provider }) => {
    return (
      <Hover value={uuid_provider} onDoubleClick={handleOnDoubleClickHover}>
        <Actions>
          <IconButton
            id={deleted_at ? 'justification' : 'inactive'}
            value={uuid_provider}
            onClick={handleClickOpenModal}
          >
            {deleted_at ? <Justification /> : <Inactive />}
          </IconButton>
        </Actions>
      </Hover>
    );
  };

  const handleOnClickNew = () => {
    LicenseModalRef.current?.open();
  };

  const columns: Column[] = [
    {
      Header: 'Nome Do Documento',
      accessor: ({ name }) => name,
    },
    {
      Header: 'Emissão',
      accessor: ({ emission }) =>
        emission ? format(new Date(emission), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Validade',
      accessor: ({ expiration }) =>
        expiration ? format(new Date(expiration), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Tipo de Documento',
      accessor: ({ license_type }) =>
        license_type.name ? license_type.name : '',
    },
    {
      Header: 'Data de Alerta',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Status',
      hide: true,
      accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
    },
  ];

  return (
    <Container>
      <Title variant="header">Editar Perfil da Empresa</Title>
      <Content>
        <FormGroup columns="1fr" gap={24}>
          {steps.map(({ content }, index) => (
            <Can key={index.toString()} see={currentStep === index}>
              {content}
            </Can>
          ))}
          <Controls>
            {/* <Button
              ref={nextButtonRef}
              label={'Salvar'}
              value="next"
              onClick={onFinish}
            /> */}
            <Button
              ref={nextButtonRef}
              label={isLastStep ? 'Salvar' : 'Próximo'}
              value="next"
              onClick={nextStep}
              icon={Next}
              iconPosition="after"
              divider
            />
          </Controls>
        </FormGroup>
      </Content>

      <ContentList>
        <FormGroup columns="0.1fr 15fr" gap={24}>
          <Board />
          <Title variant="subtitle">LISTA DE LICENÇAS DA EMPRESA</Title>
        </FormGroup>
        <Space />
        <ModalWrapper ref={LicenseModalRef}>
          <LicenseModal
            formRef={formRefLicenses}
            storeLicenses={storeLicenses}
          />
        </ModalWrapper>
        <Table
          allowControls
          columns={columns}
          data={data.data}
          loading={loading}
          totalData={data.total}
          newButtonLabel="Nova Licença"
          onChangePage={getLicenses}
          onClickNew={handleOnClickNew}
          hoverComponent={getHoverComponent}
        />
      </ContentList>
    </Container>
  );
};

export default List;
