/* eslint-disable react/display-name */
import { useRef, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import Button, { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import { IFormTableHandlers } from 'src/components/FormTable';
import FormTableInsert from 'src/components/FormTableInsert';
import IconButton from 'src/components/IconButton';
import { Certificate, RemoveCircle } from 'src/components/Icons';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import { certificatesSchema } from 'src/validators/providers/store/index';
import { v4 as uuidv4 } from 'uuid';
interface ICertificatesProps {
  formRef: RefObject<FormHandles>;
}

const Certificates = ({ formRef }: ICertificatesProps): JSX.Element => {
  const formTableRef = useRef<IFormTableHandlers>(null);
  const addButtonRef = useRef<IButtonHandlers>(null);

  const resetForm = () => {
    const formData = formRef.current?.getData();

    formRef.current?.setData({});
    formRef.current?.setErrors({});
    formRef.current?.setFieldValue('certificates', formData?.certificates);
  };

  const handleOnSubmit = async (formData) => {
    try {
      addButtonRef.current?.startLoad();

      const data = await certificatesSchema.validate(formData, defaultOptions);

      const hasItems = formRef.current
        ?.getFieldValue('certificates')
        .find(
          (item) =>
            item.name === data.name &&
            item.number === data.number &&
            format(item.emission, 'yyyy-MM-dd') ===
              format(data.emission, 'yyyy-MM-dd')
        );

      if (hasItems) {
        throw new Error('Cerificado já adicionado');
      }

      formTableRef.current?.add({ id: uuidv4(), ...data });

      setTimeout(() => resetForm(), 200);
    } catch (error: any) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      }

      if (error.message) {
        toast.error(error.message);
      }
    } finally {
      addButtonRef.current?.finishLoad();
    }
  };

  const handleOnClickRemove = (event) => {
    const id = event.target.value;

    formTableRef.current?.remove(id);
  };

  const formTableColumns = {
    size: '0.8fr 0.8fr 0.8fr 1.53fr 0.07fr',
    fields: [
      { key: 'name', label: 'Nome do documento' },
      { key: 'number', label: 'Número do documento' },
      {
        key: 'emission',
        label: 'Data de emissão',
        format: (emission) => format(emission, 'dd/MM/yyyy'),
      },
      {
        key: 'attachment',
        label: 'Documento',
        format: (document) => document?.name || 'Documento',
      },
      {
        key: 'id',
        label: '',
        format: (id) => (
          <IconButton
            className="table-button-remove"
            value={id}
            onClick={handleOnClickRemove}
          >
            <RemoveCircle />
          </IconButton>
        ),
      },
    ],
  };

  return (
    <FormTableInsert
      formRef={formRef}
      formTableRef={formTableRef}
      formTableColumns={formTableColumns}
      handleOnSubmit={handleOnSubmit}
      name="certificates"
      title="Adicionar certificados"
      icon={Certificate}
      optional
    >
      <FormGroup columns="1fr 1fr 1fr 1fr 0.4fr">
        <Input label="Nome do documento" name="name" maxLength={100} required />
        <Input
          label="Número do documento"
          name="number"
          maxLength={100}
          required
        />
        <DatePicker
          label="Data de emissão"
          name="emission"
          placeholder="dd/mm/aaaa"
          filterDate={(date) => {
            return date < new Date();
          }}
          required
        />
        <InputFile
          label="Anexar documento"
          name="attachment"
          accept="*"
          required
        />
        <Button
          variant="table-inclusion"
          type="submit"
          label="Incluir"
          className="button-remove"
        />
      </FormGroup>
    </FormTableInsert>
  );
};

export default Certificates;
