import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  padding: 3px;

  border-radius: 5px;

  font-weight: bold;
  color: #ffffff;
  background-color: #b0b0b0;
  border-radius: 6px;
  transition: opacity 0.3s;
  font: normal normal bold 12px/24px Roboto;
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;
