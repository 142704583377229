import { FC } from 'react';

import { IContainerProps, Container } from './styles';

type IFormGroupProps = IContainerProps;

const FormGroup: FC<IFormGroupProps> = ({
  children,
  columns,
  className,
  gap = 16,
  rows = '1fr',
}) => {
  return (
    <Container columns={columns} rows={rows} gap={gap} className={className}>
      {children}
    </Container>
  );
};

export default FormGroup;
