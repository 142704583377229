import styled from 'styled-components';

export const Container = styled.span`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 16px;

  height: 100%;
  width: 24px;

  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.gray1};
    font-size: 1.5rem;

    transition: color 200ms linear;

    &:hover {
      color: ${({ theme }) => theme.gray2};
    }
  }
`;

export const Content = styled.span`
  border: none;
  background: none;
`;
