import { useCallback, useEffect, useState } from 'react';

import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import Table from 'src/components/Table';
import Title from 'src/components/Title';
import api from 'src/services/api';
import tableToggle from 'src/utils/tableToggle';

import {
  Container,
  Justification,
  JustificationLabel,
  JustificationValue,
} from './styles';

const History = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ [key: string]: any }[]>([]);

  const getHistory = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.providers.get(
        `/api/v1/providers/${id}/changes`
      );

      if (response.data.length !== 0) {
        setData([...response.data?.updates, ...response.data?.deletions]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  const columns: Column = [
    ...tableToggle,
    {
      Header: 'Data de modificação',
      hide: true,
      accessor: ({ when }) =>
        when ? format(new Date(when), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Usuário',
      accessor: () => 'Usuário test',
    },
    {
      Header: 'Onde ocorreu',
      accessor: ({ changes }) =>
        changes
          ? changes.map((change, index) => (
              <div key={index.toString()}>{change.field}</div>
            ))
          : '',
    },
    {
      Header: 'O que mudou',
      accessor: ({ changes }) =>
        changes
          ? changes.map((change, index) => (
              <div key={index.toString()}>{change.to}</div>
            ))
          : '',
    },
  ];

  const renderRowSubComponent = useCallback(({ row }) => {
    const { justification } = row.original;

    return (
      <Justification>
        <JustificationLabel>Justificativa</JustificationLabel>
        <JustificationValue>{justification}</JustificationValue>
      </Justification>
    );
  }, []);

  return (
    <Container>
      <Title variant="subtitle">Histórico de alterações</Title>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        totalData={data.length}
        renderRowSubComponent={renderRowSubComponent}
        hidePagination
      />
    </Container>
  );
};

export default History;
