import { colors } from './_defaultTheme';

export default {
  disabled: {
    foreground: '#ffffff',
    border: '#8E8E93',
    background: '#8E8E93',
  },
  line: {
    default: {
      foreground: colors.gray6,
      hover: {
        foreground: colors.gray2,
      },
    },
    error: {
      foreground: colors.error,
      hover: {
        foreground: colors.error,
      },
    },
    info: {
      foreground: colors.info,
      hover: {
        foreground: colors.info,
      },
    },
    primary: {
      foreground: colors.primary,
      hover: {
        foreground: colors.primary,
      },
    },
    secondary: {
      foreground: colors.secondary,
      hover: {
        foreground: colors.secondary,
      },
    },
    success: {
      foreground: colors.success,
      hover: {
        foreground: colors.success,
      },
    },
    warning: {
      foreground: colors.warning,
      hover: {
        foreground: colors.warning,
      },
    },
  },
  normal: {
    default: {
      foreground: colors.gray6,
      border: 'transparent',
      background: 'transparent',
      hover: {
        foreground: colors.gray3,
        border: 'transparent',
        background: 'transparent',
      },
    },
    error: {
      foreground: '#ffffff',
      border: colors.error,
      background: colors.error,
      hover: {
        foreground: '#ffffff',
        border: colors.error,
        background: colors.error,
      },
    },
    info: {
      foreground: '#ffffff',
      border: colors.info,
      background: colors.info,
      hover: {
        foreground: '#ffffff',
        border: colors.info,
        background: colors.info,
      },
    },
    primary: {
      foreground: '#ffffff',
      border: colors.primary,
      background: colors.primary,
      hover: {
        foreground: '#ffffff',
        border: colors.primary,
        background: '#65bf24',
      },
    },
    secondary: {
      foreground: '#ffffff',
      border: colors.secondary,
      background: colors.secondary,
      hover: {
        foreground: '#ffffff',
        border: colors.secondary,
        background: colors.secondary,
      },
    },
    success: {
      foreground: '#ffffff',
      border: colors.success,
      background: colors.success,
      hover: {
        foreground: '#ffffff',
        border: colors.success,
        background: colors.success,
      },
    },
    warning: {
      foreground: '#ffffff',
      border: colors.warning,
      background: colors.warning,
      hover: {
        foreground: '#ffffff',
        border: colors.warning,
        background: colors.warning,
      },
    },
  },
  outline: {
    error: {
      foreground: colors.error,
      border: colors.error,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.error,
        background: colors.error,
      },
    },
    info: {
      foreground: colors.info,
      border: colors.info,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.info,
        background: colors.info,
      },
    },
    primary: {
      foreground: colors.primary,
      border: colors.primary,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.primary,
        background: '#65bf24',
      },
    },
    secondary: {
      foreground: colors.secondary,
      border: colors.secondary,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.secondary,
        background: colors.secondary,
      },
    },
    success: {
      foreground: colors.success,
      border: colors.success,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.success,
        background: colors.success,
      },
    },
    warning: {
      foreground: colors.warning,
      border: colors.warning,
      background: 'transparent',
      hover: {
        foreground: '#ffffff',
        border: colors.warning,
        background: colors.warning,
      },
    },
  },
};
