import { FC, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { OptionTypeBase } from 'react-select';
import InputErrorMessage from 'src/components/InputErrorMessage';
import Label from 'src/components/Label';

import { Container, Field } from './styles';
export interface SelectOptionsInterface {
  value: any;
  label: string;
}

export interface SelectOptionsGroupedInterface {
  label: string;
  options: Array<{ label: string; value: any }>;
}

interface SelectPropsInterface {
  label?: string;
  name?: string;
  required?: boolean | undefined;
  defaultValue?: SelectOptionsInterface;
  isMulti?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  isSearchable?: boolean;
  options: SelectOptionsInterface[];
  onChange?: any;
  placeholder?: string;
  noOptionsMessage?: string;
  isDisabled?: boolean;
}

const SelectLoginArcos: FC<SelectPropsInterface> = ({
  isMulti = false,
  isSearchable = true,
  label,
  loading = false,
  readOnly,
  name = '',
  required,
  onChange,
  options,
  defaultValue,
  placeholder,
  noOptionsMessage,
  isDisabled = false,
}) => {
  const selectRef = useRef(null);

  // const { clearError, fieldName, registerField, error } = useField(name);

  // const handleOnFocus = () => clearError();

  // useEffect(() => {
  //   if (selectRef.current)
  //     registerField({
  //       name: fieldName,
  //       ref: selectRef.current,
  //       getValue: (ref: any) => {
  //         if (isMulti) {
  //           if (!ref.state.value) {
  //             return [];
  //           }
  //           return ref.state.value.map(
  //             (option: OptionTypeBase) => option.value
  //           );
  //         }

  //         if (!ref.state.value) {
  //           return '';
  //         }

  //         return ref.state.value.value;
  //       },
  //       setValue: (ref: any, value: string) => {
  //         let option = '';
  //         if (isMulti) {
  //           option = ref.select.props.options.filter((opt) =>
  //             value?.includes(opt.value)
  //           );
  //         } else {
  //           option = ref.select.props.options.find(
  //             (opt) => opt.value === value
  //           );
  //         }

  //         ref.select.setValue(option);
  //       },
  //     });
  // }, [selectRef, isMulti]);

  return (
    <Container className="root-select-container">
      {/* <Label>
        {label}
        {required === true ? '*' : ''}
      </Label> */}
      <Field
        ref={selectRef}
        classNamePrefix="react-select"
        // error={error}
        isSearchable={isSearchable}
        loading={loading}
        isMulti={isMulti}
        readOnly={readOnly}
        onChange={onChange}
        // onFocus={handleOnFocus}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder || 'Selecione uma unidade'}
        isDisabled={isDisabled}
        noOptionsMessage={() => noOptionsMessage || 'Sem dados para carregar'}
      />
      {/* <InputErrorMessage
        fieldLabel={label}
        // fieldName={fieldName}
        // message={error}
      /> */}
    </Container>
  );
};

export default SelectLoginArcos;
