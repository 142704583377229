import yup from 'src/libs/yup';

export default yup.object().shape({
  residue: yup.object().shape({
    type: yup.string().required(),
    name: yup.string().required(),
    internal_code: yup.string(),
  }),
  // company: yup.object().shape({
  //   registry_type: yup.string().required(),
  //   cpf: yup.string().when('registry_type', {
  //     is: 'cpf',
  //     then: yup.string().required(),
  //   }),
  //   cnpj: yup.string().when('registry_type', {
  //     is: 'cnpj',
  //     then: yup.string().required(),
  //   }),
  //   international_register: yup.string().when('registry_type', {
  //     is: 'international_register',
  //     then: yup.string().required(),
  //   }),
  //   alias: yup.string(),
  //   segment: yup.string(),
  //   trading_name: yup.string().required(),
  //   company_name: yup.string().required(),
  //   address_zip_code: yup.string().required(),
  //   address_name: yup.string().required(),
  //   address_number: yup.string().required(),
  //   address_complement: yup.string().required(),
  //   region: yup.string().required(),
  //   address_state: yup.string().required(),
  //   address_city: yup.string().required(),
  //   address_country: yup.string(),
  //   phone: yup.string().required(),
  //   ie: yup.string(),
  //   im: yup.string(),
  //   types: yup.array().of(yup.string()),
  // }),
  // responsible: yup.array().of(
  //   yup.object().shape({
  //     name: yup.string(),
  //     role: yup.string(),
  //     phone: yup.string(),
  //     telephone_extension: yup.string(),
  //     email: yup.string().email(),
  //     cellphone: yup.string(),
  //   })
  // ),
});
