import styled from 'styled-components';

export const Container = styled.div`
  background-color: #024e9f;
  clear: both;
  margin: 0;
  width: 100%;
  padding: 20px 80px;
  height: 220px;
  section {
    display: inline-flex;
  }
`;

export const FooterCarrefourMenu = styled.div`
  margin-left: 30px;
  h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 15px;
  }
  ul {
    list-style: none !important;
  }
  ul li {
    list-style: none !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  ul li a {
    color: #ffffff !important;
    text-decoration: none;
    line-height: 24px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterTel = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  color: #ffffff;
`;

export const FooterBrandCarrefour = styled.div`
  margin: 8px 450px 0 60px;
`;

export const FooterBrandPlatform = styled.div`
  margin-top: 50px;
`;

export const FooterCarrefourLink = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
