import styled from 'styled-components';

export const Container = styled.td`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 9;

  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(245, 245, 245, 0.4);

  &:hover {
    opacity: 1;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
`;
