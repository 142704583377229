import { HtmlHTMLAttributes, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { IModalContentProps } from 'src/components/ModalWrapper';
import api from 'src/services/api';

import Button, { IButtonHandlers } from '../Button';
import Textarea from '../Textarea';
import { Body, Container, Footer, Head, Instruction } from './styles';

interface IInactiveModalModal
  extends IModalContentProps,
    HtmlHTMLAttributes<HTMLDivElement> {
  postURL: string;
  text: string;
}

interface IFormData {
  justification: string;
}

const MIN_JUSTIFICATION_LENGTH = 30;
const MAX_JUSTIFICATION_LENGTH = 500;

const InactiveModal = ({
  closeButton,
  closeModal,
  postURL,
  text,
}: IInactiveModalModal): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const buttonRef = useRef<IButtonHandlers>(null);

  const validation = (fieldValue) => {
    if (
      fieldValue.length < MIN_JUSTIFICATION_LENGTH ||
      fieldValue.length > MAX_JUSTIFICATION_LENGTH
    ) {
      formRef.current?.setFieldError(
        'justification',
        `A justificação é obrigatória e deve conter entre ${MIN_JUSTIFICATION_LENGTH} e ${MAX_JUSTIFICATION_LENGTH} caracteres.`
      );
      throw new Error('Erro de validação');
    }
  };

  const handleOnClickSend = () => formRef.current?.submitForm();

  const handleOnSubmit = ({ justification }: IFormData) => {
    try {
      buttonRef.current?.startLoad();

      validation(justification);

      api.providers.delete(postURL, {
        data: {
          justification,
        },
      });

      toast.success('Dado inativado com sucesso!');
      if (closeModal) closeModal(true);
    } catch (exception) {
      toast.error('Erro ao inserir a justificativa!');
    } finally {
      buttonRef.current?.finishLoad();
    }
  };

  return (
    <Container>
      <Head>
        INATIVAR - {text} {closeButton}
      </Head>
      <Body>
        <Instruction>
          Com essa ação você estará inativando esse {text} dentro do sistema.
          Isso significa que ele não aparecerá mais em listagens e não poderá
          ser mais selecionado para alguma outra ação. Caso queira reutilizá-lo,
          você poderá reativá-lo posteriormente. Preencha no campo abaixo a
          justificativa para essa ação.
        </Instruction>
        <Form ref={formRef} onSubmit={handleOnSubmit}>
          <Textarea
            label="Justificativa (obrigatório)"
            name="justification"
            minLength={MIN_JUSTIFICATION_LENGTH}
            maxLength={MAX_JUSTIFICATION_LENGTH}
          />
        </Form>
      </Body>
      <Footer>
        <Button ref={buttonRef} label="Enviar" onClick={handleOnClickSend} />
      </Footer>
    </Container>
  );
};

export default InactiveModal;
