import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from 'src/components/Avatar';
import { RootState } from 'src/store/ducks/index';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { useDispatch } from 'react-redux';

import { Container, Control, Name } from './styles';

const Profile = (): JSX.Element => {
  const { companySelected } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const handleClick = () => {
    history.push('/perfil-empresa');
  };
  const dispatch = useDispatch();
  const RedirectLogin = () => {
    history.push('/');
  };
  const [company, setCompany] = useState('');

  // useEffect(() => {
  //   const companyName = async () => {
  //     try {
  //       if (
  //         companySelected[0]?.company_name !== '' &&
  //         companySelected[0]?.company_name !== undefined
  //       ) {
  //         setCompany(companySelected[0]?.company_name);
  //       } else {
  //         dispatch(AuthActions.authLogout());
  //         //RedirectLogin();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       dispatch(AuthActions.authLogout());
  //       //RedirectLogin();
  //     }
  //   };
  //   companyName();
  // }, [companySelected]);

  return (
    <Container onClick={() => handleClick()}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '100px',
          width: '105px',
          height: '105px',
          top: 10,
          backgroundColor: 'rgb(255, 255, 255)',
          boxShadow: 'rgb(111 111 111) 0px 4px 18px -7px',
        }}
      >
        {/* <img src={logo_placeholder} style={{}} /> */}
        <Avatar
          size={100}
          src="https://random.imagecdn.app/100/100"
          variant="light"
        />
      </div>

      {/* <Name>{company}</Name> */}
      {/* <Control /> */}
    </Container>
  );
};

export default Profile;
