/* eslint-disable react/display-name */
import { useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { IoIosApps } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import EditMenu from 'src/components/EditMenu';
import Grid from 'src/components/Grid';
import Title from 'src/components/Title';
import useEditForm from 'src/hooks/useEditForm';
import api from 'src/services/api';

import BasicData from './BasicData';
import BasicDataIndicators from './BasicData/Indicators';
import Certificates from './Certificates';
import Employees from './Employees';
import EmployeesIndicators from './Employees/Indicators';
import History from './History';
import HistoryIndicators from './History/Indicators';
import Licenses from './Licenses';
import LicensesIndicators from './Licenses/Indicators';
import { Body, Container, Content } from './styles';
interface IProvider {
  alias?: string;
  types?: { [key: string]: any }[];
  company?: {
    type: string;
  };
}

const Edit = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const formRefBasicData = useRef<FormHandles>(null);
  const formRefCertificates = useRef<FormHandles>(null);
  const formRefEmployees = useRef<FormHandles>(null);
  const formRefLicenses = useRef<FormHandles>(null);

  const [provider, setProvider] = useState<IProvider>({});

  const handleOnClickSaveBasicData = async () => {
    const data = formRefBasicData.current?.getData();
  };

  const { forms, menus } = useEditForm({
    config: [
      {
        menu: {
          label: 'Dados básicos',
          icon: IoIosApps,
        },
        form: (
          <>
            <BasicDataIndicators />
            <Card>
              <BasicData formRef={formRefBasicData} />
            </Card>
          </>
        ),
        controls: (
          <Button label="Salvar" onClick={handleOnClickSaveBasicData} />
        ),
      },
      {
        menu: {
          label: 'Licenças e Certificados',
          icon: IoIosApps,
        },
        form: (
          <>
            <LicensesIndicators />
            <Card>
              <Grid columns="1fr" gap={24}>
                <Licenses formRef={formRefLicenses} />
                <Certificates formRef={formRefCertificates} />
              </Grid>
            </Card>
          </>
        ),
      },
      {
        menu: {
          label: 'Colaboradores',
          icon: IoIosApps,
        },
        form: (
          <>
            <EmployeesIndicators />
            <Card>
              <Employees formRef={formRefEmployees} />{' '}
            </Card>
          </>
        ),
      },
      {
        menu: {
          label: 'Histórico de alterações',
          icon: IoIosApps,
        },
        form: (
          <>
            <HistoryIndicators />
            <Card>
              <History />
            </Card>
          </>
        ),
      },
    ],
  });

  useEffect(() => {
    const getProvider = async () => {
      const response = await api.providers.get(`/api/v1/providers/${id}`);
      setProvider(response.data);
    };

    getProvider();
  }, [id]);

  useEffect(() => {
    const types = provider?.types?.map(({ code }) => code);

    formRefBasicData.current?.setData({
      ...provider,
      company: {
        ...provider.company,
        registry_type: provider?.company?.type || 'cnpj',
        types,
      },
    });
  }, [provider]);

  return (
    <Container>
      <Title variant="header">
        Editar Provedor {provider?.alias && `- ${provider?.alias}`}
      </Title>
      <Body>
        <EditMenu menus={menus} />
        <Content>{forms}</Content>
      </Body>
    </Container>
  );
};

export default Edit;
