import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import arte from 'src/assets/img/arte-login.png';
import company from 'src/assets/img/ico-building.png';
import logo from 'src/assets/img/logo-pv.png';
import BockInput from 'src/components/BockInput';
import ButtonBackLogin from 'src/components/ButtonBackLogin';
import ButtonLogin from 'src/components/ButtonLogin';
import SelectLogin from 'src/components/SelectLogin';
import api from 'src/services/api';
import terceiro from 'src/services/terceiro';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import { RootState } from 'src/store/ducks/index';
import getApiErrors from 'src/utils/getApiErrors';

import {
  Container,
  Logo,
  Form,
  LeftBox,
  RightBox,
  LogoRight,
  TextEntre,
  TextFooter,
  ImageSSO,
  ImageLock,
  ImageUser,
  TextORSSO,
  HRORSSOs,
  MenuItemLink,
  TextTitle,
  Title,
} from './styles';

const ChooseCompany: React.FC = (props) => {
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const history = useHistory();

  const [companiesOptions, setCompaniesOptions] = useState([] as any);
  const [companiesSelected, setCompanies] = useState([] as any);
  const { modules, token } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const getLogin = async () => {
    const loading = false;
    dispatch(AuthActions.authLoading(loading));
    localStorage.setItem('erro', '2');
    history.push('/');
  };

  useEffect(() => {
    const getCompanie = async () => {
      const loading = true;
      dispatch(AuthActions.authLoading(loading));
      if (
        modules?.modules.length == 1 &&
        modules?.modules[0]?.user_profile == null
      ) {
        const data = {
          uuid: modules?.modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                if (
                  companies[0]?.plans == 'CANCELADO' ||
                  companies[0]?.plan == 'CANCELADO'
                ) {
                  const loading = false;
                  dispatch(AuthActions.authLoading(loading));
                  toast.error(
                    'Seu contrato de uso com a GreenPlat está vencido. Caso queira renovar acesse um dos nossos canais de atendimento.',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                } else {
                  const response = await api.user.post(
                    '/api/v1/companies/current',
                    data,
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenNew,
                      },
                    }
                  );

                  const dataNew = {
                    token: response.data.token,
                  };

                  window.location.href =
                    'api/v1/logar_by_micro_service/' + response.data.token;
                }
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  getLogin();
                }
              }
            } else {
              if (modules?.modules) {
                const data = modules.modules;
                const options = Object.keys(data)
                  .map((key) => ({
                    label: `${data[key].nome_comercial}`,
                    value: data[key],
                  }))
                  .filter((option) => option.value !== '');
                setCompaniesOptions(options);
              }
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
            }
          }

          // dispatch(AuthActions.authSelectCompanie(token, { companies }));
        } catch (error: any) {
          const loading = false;
          dispatch(AuthActions.authLoading(loading));
          if (error.response.data.error == 'Provided token is expired.') {
            getLoginExpired();
          }

          if (error.response.data.error == 'No companies bounded to user') {
            toast.error(
              ' Usuário inativo no sistema. Por favor entre em contato com o responsável para reativar seu acesso ao sistema! ',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            const loading = false;
            dispatch(AuthActions.authLoading(loading));
          }
        }
      } else {
        if (
          modules?.modules[0]?.user_profile?.usuario_id != null &&
          modules.length == 1
        ) {
          window.location.href =
            terceiro.REACT_APP_TERCEIRO +
            '/logar_by_micro_service/' +
            modules?.modules[0]?.user_profile?.usuario_id +
            '/' +
            token;
        }
      }
      if (modules?.modules) {
        const data = modules.modules;
        const options = Object.keys(data)
          .map((key) => ({
            label: `${data[key].nome_comercial}`,
            value: data[key],
          }))
          .filter((option) => option.value !== '');
        setCompaniesOptions(options);
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
      }
    };

    getCompanie();
  }, []);

  const getLoginExpired = async () => {
    localStorage.setItem('erro', '4');
    history.push('/');
  };

  const getCompany = async (code) => {
    if (code.value !== '') {
      try {
        setCompanies(code.value);
        funcErrorCompanie(false);
      } catch (error) {}
    }
  };

  const funcErrorCompanie = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    if (companiesSelected == '') {
      toast.error('Escolha uma unidade!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorCompanie(true);
    } else {
      if (companiesSelected.user_profile?.usuario_id != null) {
        const loading = true;
        dispatch(AuthActions.authLoading(loading));
        window.location.href =
          terceiro.REACT_APP_TERCEIRO +
          '/logar_by_micro_service/' +
          companiesSelected.user_profile?.usuario_id +
          '/' +
          token;
      } else {
        const companySelected = companiesSelected.id;
        dispatch(AuthActions.authCompany(token, companySelected, modules));
        history.push('/escolher-modulo');
      }
    }
  };

  return (
    <Container>
      <Form>
        <LeftBox>
          <TextEntre>Bem-vindo à Revolução</TextEntre>

          <Logo>
            <img src={logo} alt="Plataforma Verde" />
          </Logo>

          <HRORSSOs>
            <TextORSSO>SELECIONE PARA CONTINUAR</TextORSSO>
          </HRORSSOs>

          <BockInput error={errorEmail}>
            <ImageLock>
              <img src={company} alt="Plataforma Verde" />
            </ImageLock>
            <SelectLogin
              options={companiesOptions}
              onChange={getCompany}
              required
            />
          </BockInput>

          <ButtonLogin onClick={() => forgotRequest()} type="button">
            CONTINUAR
          </ButtonLogin>

          <HRORSSOs>
            <TextORSSO>OU</TextORSSO>
          </HRORSSOs>

          <MenuItemLink onClick={() => backLogin()}>
            <ButtonBackLogin type="button">
              VOLTAR PARA LOGIN/SENHA
            </ButtonBackLogin>
          </MenuItemLink>

          <TextFooter>problemas de acesso? entre em nosso chat</TextFooter>
        </LeftBox>

        <RightBox>
          <LogoRight>
            <img src={arte} alt="Plataforma Verde" />
          </LogoRight>
        </RightBox>
      </Form>
    </Container>
  );
};

export default ChooseCompany;
