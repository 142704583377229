import yup from 'src/libs/yup';

export default yup.object().shape({
  uuid_license: yup.string(),
  uuid_license_type: yup.string().required().nullable(),
  name: yup.string().required(),
  code: yup
    .number()
    .typeError('Número do documento deve ser um número')
    .min(0, 'Maior que 0')
    .required(),
  emission: yup
    .date()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(),
  expiration: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  alert_date: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? undefined : curr)),
  file: yup.mixed().required(),
});
