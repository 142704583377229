import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

interface IContentProps {
  visible?: boolean;
}

export const Content = styled.div<IContentProps>`
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};

  width: 100vw;
  max-width: 170px;
  padding: 8px 8px 14px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 18px #0000003b;

  position: absolute;
  left: 36px;
  z-index: 99;
`;

export const Controls = styled.div`
  display: grid;
  grid-gap: 8px;

  & span {
    font-family: 'Roboto Condensed';
    font-size: 16px;
    font-weight: 300;
    color: #a0a0a0;
  }
`;

export const Title = styled.div`
  font-family: 'Roboto Condensed';
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #247c49;

  margin-bottom: 16px;
`;

export const ToggleButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  background-color: transparent;

  svg {
    width: 16px;
    height: 16px;
    color: #909090;
  }
`;
