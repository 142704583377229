import styled from 'styled-components';

export const Container = styled.div`
  .root-label {
    margin-bottom: 8px;
  }
`;

export const Content = styled.div``;

export const Item = styled.div``;
