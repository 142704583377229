import styled from 'styled-components';

import media from 'src/styles/configs/devices';

export const Container = styled.div`
  margin-bottom: 32px;
  padding: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.card.background};
  box-shadow: ${({ theme }) => theme.boxShadow};

  .root-title {
    margin-bottom: 16px;
  }

  .header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${media.max.tablet} {
    .root-grid {
      grid-template-columns: 1fr;
    }
    .header_wrapper {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
`;
