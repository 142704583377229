/* eslint-disable react/display-name */
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { IButtonHandlers } from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import FormGroup from 'src/components/FormGroup';
import FormModal from 'src/components/FormModal';
import IconButton from 'src/components/IconButton';
import { Inactive, Justification } from 'src/components/Icons';
import InactiveModal from 'src/components/InactiveModal';
import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import JustificationModal from 'src/components/JustificationModal';
import ModalWrapper, { IModalHandlers } from 'src/components/ModalWrapper';
import Select from 'src/components/Select';
import Table from 'src/components/Table';
import Hover from 'src/components/Table/Hover';
import Actions from 'src/components/Table/Hover/Actions';
import Title from 'src/components/Title';
import { defaultOptions, formatErrors } from 'src/libs/yup';
import api from 'src/services/api';
import { licensesSchema } from 'src/validators/providers/store/index';

interface ILicenseProps {
  formRef: RefObject<FormHandles>;
}

interface IDataState {
  data: { [key: string]: any }[];
  total: number;
}

const Licenses = ({ formRef }: ILicenseProps): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const buttonRef = useRef<IButtonHandlers>(null);
  const modalFormRef = useRef<IModalHandlers>(null);
  const justificationsModalRef = useRef<IModalHandlers>(null);
  const inactiveModalRef = useRef<IModalHandlers>(null);

  const [apiURL, setApiURL] = useState('');
  const [licenceOptions, setLicenceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDataState>({
    data: [],
    total: 0,
  });

  const getLicenses = useCallback(
    async ({ pageIndex = 0, pageSize = 10, showInactive = false }) => {
      try {
        setLoading(true);
        const response = await api.providers.get(
          `/api/v1/providers/${id}/licenses?page=${
            pageIndex + 1
          }&per_page=${pageSize}&active=${!showInactive}`
        );

        setData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await api.providers.get('/api/v1/licenses/types');

        const options = response.data
          .map(({ uuid_license_type, name }) => ({
            label: name,
            value: uuid_license_type,
          }))
          .sort((a, b) => (a.label > b.label ? 0 : -1));

        setLicenceOptions(options);
      } catch (error) {}
    };

    getOptions();
  }, []);

  const handleOnClickNew = () => {
    modalFormRef.current?.open();
  };

  const storeLicense = async (license, update) => {
    try {
      const formData = new FormData();

      formData.append('file', license.file);
      formData.append('type', 'operacao');
      formData.append('uuid_license_type', license.uuid_license_type);
      formData.append('name', license.name);
      formData.append('code', license.code);

      const method = update ? 'put' : 'post';
      const response = await api.providers[method](
        `/api/v1/providers/${id}/licenses/${license.uuid_license || ''}`,
        formData
      );

      return response;
    } catch (exception) {
      throw new Error(`Erro ao ${update ? 'editar' : 'cadastrar'} a licença!`);
    }
  };

  const handleOnSubmit = async (formData) => {
    try {
      buttonRef.current?.startLoad();

      const data = await licensesSchema.validate(formData, defaultOptions);

      storeLicense(data, data.uuid_license !== '');

      setTimeout(() => getLicenses({}), 200);
      modalFormRef.current?.close();
    } catch (error) {
      if (error?.inner) {
        formRef.current?.setErrors(formatErrors(error.inner));
      } else {
        toast.error(error.message);
      }
    } finally {
      buttonRef.current?.finishLoad();
    }
  };

  const columns: Column = [
    {
      Header: 'Data',
      accessor: ({ created_at }) =>
        created_at ? format(new Date(created_at), 'dd/MM/yyyy') : '',
    },
    {
      Header: 'Tipo de licença',
      accessor: ({ license_type }) => license_type.name,
    },
    { Header: 'Nome do documento', accessor: 'name' },
    { Header: 'Número do documento', accessor: 'code' },
    {
      Header: 'Data de emissão',
      accessor: ({ emission }) => format(new Date(emission), 'dd/MM/yyyy'),
    },
    {
      Header: 'Data de validade',
      accessor: ({ expiration }) =>
        expiration
          ? format(new Date(expiration), 'dd/MM/yyyy')
          : 'Não informado',
    },
    {
      Header: 'Data de alerta',
      accessor: ({ alert_date }) =>
        alert_date
          ? format(new Date(alert_date), 'dd/MM/yyyy')
          : 'Não informado',
    },
    {
      Header: 'Documento',
      accessor: ({ file_display_name }) => file_display_name || 'Documento',
    },
    {
      Header: 'Status',
      accessor: ({ deleted_at }) => (deleted_at ? 'Inativo' : 'Ativo'),
    },
  ];

  const handleClickOpenModal = (event) => {
    const { value } = event.target;

    if (event.target.id === 'justification') {
      setApiURL(`/api/v1/providers/${value}/licenses/${value}/changes`);
      justificationsModalRef.current?.open();
    } else {
      setApiURL(`/api/v1/providers/${id}/licenses/${value}`);
      inactiveModalRef.current?.open();
    }
  };

  const handleOnClickHover = useCallback(
    (value) => {
      const itemToEdit = data.data.find((item) => item.uuid_license === value);

      if (itemToEdit) {
        modalFormRef.current?.open();
        formRef.current?.setData(itemToEdit);
      }
    },
    [formRef, data.data]
  );

  const getHoverComponent = ({ deleted_at, uuid_license }) => {
    return (
      <Hover value={uuid_license} onClick={handleOnClickHover}>
        <Actions>
          <IconButton
            id={deleted_at ? 'justification' : 'inactive'}
            value={uuid_license}
            onClick={handleClickOpenModal}
          >
            {deleted_at ? <Justification /> : <Inactive />}
          </IconButton>
        </Actions>
      </Hover>
    );
  };

  return (
    <FormGroup columns="1fr" gap={24}>
      <Title variant="subtitle">Licenças cadastradas</Title>
      <ModalWrapper ref={justificationsModalRef}>
        <JustificationModal text="Licença" getURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={inactiveModalRef}>
        <InactiveModal text="Licença" postURL={apiURL} />
      </ModalWrapper>
      <ModalWrapper ref={modalFormRef}>
        <FormModal
          title="Licença"
          formRef={formRef}
          buttonRef={buttonRef}
          onSubmit={handleOnSubmit}
        >
          <Input label="" name="uuid_license" hidden />
          <FormGroup columns="1fr" gap={24}>
            <FormGroup columns="1fr 1fr 1fr ">
              <Select
                label="Tipo de licença"
                name="uuid_license_type"
                options={licenceOptions}
              />
              <Input label="Nome do documento" name="name" />
              <Input label="Número do documento" required name="code" />
            </FormGroup>
            <FormGroup columns="1fr 1fr 1fr 2fr">
              <DatePicker label="Data de emissão" name="emission" />
              <DatePicker label="Data de validade" name="validity_date" />
              <DatePicker label="Data de alerta" name="alert_date" />
              <InputFile label="Anexar documento" name="file" accept=".pdf" />
            </FormGroup>
          </FormGroup>
        </FormModal>
      </ModalWrapper>
      <Table
        allowControls
        columns={columns}
        data={data.data}
        loading={loading}
        totalData={data.total}
        newButtonLabel="Nova licença"
        onChangePage={getLicenses}
        onClickNew={handleOnClickNew}
        hoverComponent={getHoverComponent}
      />
    </FormGroup>
  );
};

export default Licenses;
