import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
  padding: 16px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow};

  .root-title {
    margin-bottom: 16px;
  }

  .card-text-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .nome-sistema {
    font-size: 13px;
  }

  .sigla-sistema {
    font-size: 15px;
  }

  .logo-sistema {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }

  & .messageLastUpdate {
    margin-bottom: 0;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    color: #202020;
    text-transform: uppercase;
  }

  & .labelLastUpdate {
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 13px;
    letter-spacing: 0px;
    color: #b0b0b0;
    display: inline;
  }

  & .dateLastUpdate {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    letter-spacing: 0px;
    color: #808080;
    display: inline;
  }

  .tile-link {
    text-align: left;
    font-size: 13px;
    font-family: 'Roboto Condensed' sans-serif;
    letter-spacing: 0px;
  }

  .titulo-sistema-container {
    text-align: left;
    font-weight: normal;
    font-size: 22px;
    font-family: 'Roboto Condensed' sans-serif;
    letter-spacing: 0px;
    color: #505050;
    text-transform: uppercase;
  }
`;
