import styled, { css } from 'styled-components';

export const Button = styled.button<{ tooltip: string }>`
  border: none;
  background-color: transparent;

  width: 18px;
  height: 22px;

  svg {
    pointer-events: none;

    background-color: #fbfbfb;
  }

  ${({ tooltip }) => {
    if (tooltip) {
      return css`
        display: flex;
        align-content: end;
        /* align-items: end; */

        ::before {
          background-color: #fbfbfb;
          border-radius: 2px;
          color: #5fbf3f;
          content: '${tooltip}';
          display: none;
          font-family: sans-serif;
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          text-align: right;
          align-self: flex-end;
          padding: 8px 5px;
          position: absolute;
          margin-right: 30px;
          /* left: 84vw; */
          /* left: -7vw; */
          right: 0;
          z-index: 1;
        }

        :hover::before {
          display: block;
        }
      `;
    }

    return css``;
  }};
`;
