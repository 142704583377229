import { Container, LastLevel } from './styles';

interface IBreadcrumbsProps {
  levels: string[];
}

const Breadcrumbs = ({ levels }: IBreadcrumbsProps): JSX.Element => {
  const [...arrLevels] = levels;
  const lastLevel = arrLevels.pop();

  return (
    <Container className="root-breadcrumbs">
      {arrLevels.join(' / ')} {levels.length > 1 && '/'}{' '}
      <LastLevel>{lastLevel}</LastLevel>
    </Container>
  );
};

export default Breadcrumbs;
