import styled from 'styled-components';

export interface IContainerProps {
  size: number;
}

export const Container = styled.div<IContainerProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  img {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;

    border-radius: 50%;
  }
`;

interface IImgProps {
  loaded?: boolean;
}

export const Img = styled.img<IImgProps>`
  display: ${({ loaded }) => (loaded ? 'block' : 'none')};
`;

interface ILoaderProps {
  size: number;
  variant?: 'light' | 'dark';
}

export const Loader = styled.div<ILoaderProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  background-color: ${({ variant }) =>
    variant === 'dark' ? '#333333' : '#ffffff'};

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
`;
